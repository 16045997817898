import React from 'react';
import {Grid, Typography} from '@mui/material';
import errorImg from '../../assets/error.jpg';

const ErrorBoundaryFallback = () => {
    return (
        <Grid container sx={{height: '100vh'}} justifyContent="center" alignItems="center">
            <Grid item sx={{textAlign: 'center'}}>
                <img src={errorImg} style={{width: '50%'}} alt="error"/>
                <Typography variant="h3" sx={{mb: 5}}>Oh no, this is embarrassing...</Typography>
                <Typography variant="body1" sx={{mb: 1}}>
                    Something as unexpectedly gone wrong.
                    This has been reported to the Lumina technical team automatically.
                </Typography>
                <Typography variant="body1" sx={{mb: 1}}>
                    Please try refreshing the page.
                </Typography>
                <Typography variant="body1">
                    If this issue persists, please contact the Lumina technical team directly for assistance.
                </Typography>
            </Grid>
        </Grid>
    )
}

export default ErrorBoundaryFallback;