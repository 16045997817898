import {DraggableColumnConfig, DroppableColumn} from '../../models/draggable-column-config';
import DndDroppableColumn from './column';
import React from 'react';
import styled from 'styled-components';
import {DragDropContext, DropResult} from "react-beautiful-dnd";
import {useAppDispatch} from '../../app/hooks';
import {ConfigState, updateConfig} from '../../store/config/configSlice';
import useConfig from '../../hooks/use-config';

export interface DndContainerProps {
    config: DraggableColumnConfig;
    localStorageKey: string;
}

const DndContainerStyled = styled.div`
  display: flex;
  width: 100%;
  column-gap: 8px;
`;

/**
 * This component serves as a container for droppable items. Generally, this should be used as a column.
 * We will initially load columns information from state. Whatever is returned from state is used to init the columns.
 * We also watch state for column updates. When columns are updated, we'll automatically update state in this component.
 *
 * @param config DraggableColumnConfig Configuration object
 * @param localStorageKey string Key to use for storing updated column values
 * @constructor
 */
const DndContainer: React.FC<DndContainerProps> = ({config, localStorageKey}) => {
    const [columns, setColumns] = React.useState<DroppableColumn[]>(config.columns);
    const dispatch = useAppDispatch();
    const {configState} = useConfig();

    React.useEffect(() => {
        if (configState[localStorageKey as keyof ConfigState]) {
            const columnConfig = configState[localStorageKey as keyof ConfigState];
            if (columnConfig) {
                setColumns(columnConfig);
            }
        }
    }, [configState, localStorageKey]);

    const onDragEnd = (result: DropResult) => {
        if (result.source.droppableId === result.destination?.droppableId && result.source.index === result.destination.index) {
            // Item was dropped in the same location where it started.
            return;
        }

        if (result.destination === undefined) {
            // item was dropped outside of a droppable.
            return;
        }

        const updatedColumns = [...columns];
        if (result.source.droppableId === result.destination?.droppableId) {
            // Item was dropped in the same column, but not at the sane index.
            const columnToUpdateIndex = columns.findIndex(c => c.id === result.source.droppableId); // Find the column that is being reordered.
            const newColumn = {...columns[columnToUpdateIndex], items: [...columns[columnToUpdateIndex].items]} // Create a copy of the column
            const itemToDrag = newColumn.items.find((i) => i.id === result.draggableId); // Find the item that is being dragged.
            if (itemToDrag) {
                // remove the dragged item from the old index and add it to the next index.
                newColumn.items.splice(result.source.index, 1);
                newColumn.items.splice(result.destination.index, 0, itemToDrag);
            }

            updatedColumns[columnToUpdateIndex] = newColumn;
            setColumns(updatedColumns);
        } else if (result.destination && result.source.droppableId !== result.destination.droppableId) {
            // item was dropped in a different column.
            const sourceColumnIndex = columns.findIndex(c => c.id === result.source.droppableId); // Find the column that the draggable came from.
            const destinationColumnIndex = columns.findIndex(c => c.id === result.destination?.droppableId); // Find the destination column for the draggable.
            // Create a copy of the source and dest columns
            const newSourceColumn = {...columns[sourceColumnIndex], items: [...columns[sourceColumnIndex].items]};
            const newDestinationColumn = {...columns[destinationColumnIndex], items: [...columns[destinationColumnIndex].items]};

            const itemToDrag = newSourceColumn.items.find((i) => i.id === result.draggableId); // Find the item being dragged.
            if (itemToDrag) {
                // remove the dragged item from the source list and insert it into the destination list.
                newSourceColumn.items.splice(result.source.index, 1);
                newDestinationColumn.items.splice(result.destination.index, 0, itemToDrag);
            }

            // Update the columns in state.
            updatedColumns[sourceColumnIndex] = newSourceColumn;
            updatedColumns[destinationColumnIndex] = newDestinationColumn;
            setColumns(updatedColumns);
        }
        // If we get here, a change was made to columns. Update state so that tables will also update.
        dispatch(updateConfig({key: localStorageKey, config: updatedColumns}));
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <DndContainerStyled>
                {
                    columns.map((column) => {
                        return (
                            <DndDroppableColumn key={column.id} config={column}/>
                        )
                    })
                }
            </DndContainerStyled>
        </DragDropContext>
    )
}

export default DndContainer;