import {Button, Grid} from '@mui/material';
import {openNewValuationsDialog} from '../../store/valuations/valuationsSlice';
import {Add, AddAlert, Remove} from '@mui/icons-material';
import {
    addExistingSecurityToUserAsync,
    removeSecurityFromUserAsync
} from '../../store/securities/securitiesSlice';
import React from 'react';
import {useAppDispatch} from '../../app/hooks';
import useSecurity from '../../hooks/use-security';
import useUsers from '../../hooks/use-users';
import {openInfoDialog} from '../../store/dialog/dialogSlice';

export interface ValuationActionsProps {
    userId: string;
    securityId: string;
    setSettingsDialogOpen: (o: boolean) => void
}

const ValuationActions: React.FC<ValuationActionsProps> = ({userId, securityId, setSettingsDialogOpen}) => {
    const dispatch = useAppDispatch();
    const {currentUser} = useUsers();
    const {activeSecurity} = useSecurity();

    const {
        doesCurrentUserOwnActiveSecurity,
        isCurrentUserViewingSelf,
    } = useSecurity(securityId, userId);
    const shouldShowAddToList = () => {
        return !isCurrentUserViewingSelf && !doesCurrentUserOwnActiveSecurity;
    }

    const enableAddToList = () => {
        let isSecondaryContributor = false;
        if (currentUser && activeSecurity?.users) {
            isSecondaryContributor = activeSecurity?.users?.findIndex(i => currentUser?.id === i.user) > -1;
        }

        return !isCurrentUserViewingSelf && !doesCurrentUserOwnActiveSecurity && !isSecondaryContributor;
    }

    const showRemoveFromList = () => {
        const isOwner = activeSecurity?.owner?.id === currentUser?.id;
        const viewingValuationsForCurrentUser = userId === currentUser?.id;

        return !isOwner && viewingValuationsForCurrentUser;
    }

    const onClickAddEstimate = () => {
        if (activeSecurity?.latestPrice) {
            dispatch(openNewValuationsDialog())
        } else {
            dispatch(openInfoDialog({message: 'This security does not have any pricing information yet. <br /><br />Please wait for pricing data to be available before creating an estimate. <br /><br />If this error persists for more than 24 hours, please contact support.', title: 'No Price Data Available'}));
        }
    }

    return (
        <Grid container justifyContent="flex-end" columnSpacing={1}>
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClickAddEstimate}
                    size="small"
                    disabled={userId !== currentUser?.id}
                >
                    <Add/> Add Earnings Estimate
                </Button>
            </Grid>
            {
                shouldShowAddToList() && (
                    <Grid item>
                        {
                            <Button size="small" variant="contained" color="secondary" disabled={!enableAddToList()}
                                    onClick={() => {
                                        dispatch(addExistingSecurityToUserAsync({'security': securityId}));
                                    }}>
                                <Add/> Add to my list
                            </Button>
                        }
                    </Grid>
                )
            }
            {
                showRemoveFromList() && (
                    <Grid item>
                        {
                            <Button size="small" variant="contained" color="secondary"
                                    onClick={() => {
                                        if (activeSecurity?.id !== undefined) {
                                            dispatch(removeSecurityFromUserAsync({'id': activeSecurity?.id}));
                                        }
                                    }}>
                                <Remove/> Remove from my list
                            </Button>
                        }
                    </Grid>
                )
            }
            <Grid item>
                <Button size="small" variant="contained" color="secondary"
                        onClick={() => setSettingsDialogOpen(true)}>
                    <AddAlert/> Alerts
                </Button>
            </Grid>
        </Grid>
    )
}

export default ValuationActions;