import {API_BASE} from '../../constants';

export interface AuthLoginRequest {
    idToken: string;
    email: string;
}

export async function login(request: AuthLoginRequest) {
    return await fetch(`${API_BASE}/api/auth/login/`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
