import {useAppSelector} from '../app/hooks';
import {selectConfig} from '../store/config/configSlice';

const useConfig = () => {
    const configState = useAppSelector(selectConfig);

    return {
        configState: configState
    }
}

export default useConfig;