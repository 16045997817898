import React from 'react';
import {DroppableColumn} from '../../models/draggable-column-config';
import styled from 'styled-components';
import DndDraggableItem from './dnd-draggable-item';
import {Droppable, DroppableProvided, DroppableStateSnapshot} from 'react-beautiful-dnd';

export interface DroppableColumnProps {
    config: DroppableColumn;
}

interface DndDroppableColumnStyledProps {
    isDraggingOver: boolean;
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  padding: 8px;
  border-radius: 8px;
`;

const DndDroppableColumnStyled = styled.div<DndDroppableColumnStyledProps>`
  background-color: ${props => (props.isDraggingOver ? 'rgba(200, 200, 200, 0.1)' : 'white')};
  flex-grow: 1;
  transition: all 0.25s ease;
`;

const DndDroppableColumn: React.FC<DroppableColumnProps> = ({config}) => {
    return (
        <Container>
            <p>{config.title}</p>
            <Droppable droppableId={config.id}>
                {
                    (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                        <DndDroppableColumnStyled
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                        >
                            {
                                config.items.map((item, index) => {
                                    return (
                                        <DndDraggableItem key={item.id} item={item} index={index}/>
                                    )
                                })
                            }
                            {provided.placeholder}
                        </DndDroppableColumnStyled>
                    )
                }
            </Droppable>
        </Container>
    )
}

export default DndDroppableColumn;