import React from 'react';
import {BasicSecurityPrice} from '../../models/security-price';
import moment from 'moment';
import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {translateLabel} from '../../utils/renderers/field-labels';
import StyledTableCell from '../styled-table-cell';

export interface PriceHistoryTableProps {
    recentPriceData?: BasicSecurityPrice[];
}

const PriceHistoryTable: React.FC<PriceHistoryTableProps> = ({recentPriceData}) => {
    const [expandMostRecentData, setExpandMostRecentData] = React.useState<boolean>(false);
    const [showAllData, setShowAllData] = React.useState<boolean>(false);
    const [chartOptions, setChartOptions] = React.useState({});

    React.useEffect(() => {
        if (!recentPriceData) {
            return;
        }

        const options = {
            chart: {
                type: 'spline',
            },
            title: {
                text: `Recent Price Data`
            },
            data: {
                dateFormat: 'YYYY/mm/dd'
            },
            tooltip: {
                shared: true,
                formatter: function(): string {
                    // @ts-ignore
                    const {x, y} = this;
                    return `<strong>${moment(x).format('YYYY-MM-DD HH:mm Z')}</strong><br />Price: ${y}`;
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    }
                }
            },
            xAxis: {
                categories: [...recentPriceData].reverse().map((v: BasicSecurityPrice) => {
                    return v.valueDate
                }),
                type: 'datetime',
                labels: {
                    enabled: false,
                }
            },
            yAxis: [{ // Secondary yAxis
                title: {
                    text: 'Share Price',
                },
                opposite: false
            }],
            series: [
                {
                    name: 'Price',
                    data: [...recentPriceData].reverse().map((v: BasicSecurityPrice) => v.securityPrice),
                },
            ]
        };

        setChartOptions(options);
    }, [recentPriceData]);

    // If we don't have any price data, display nothing.
    if (!recentPriceData || recentPriceData.length === 0) {
        return null;
    }

    return (
        <Paper elevation={3}>
            <Grid container>
                <Grid item xs={11} md={9} sx={{p: 3}}>
                    <Typography variant="h6">
                        Most Recent Data
                    </Typography>
                </Grid>
                <Grid item xs={1} md={3} sx={{p: 3, textAlign: 'right'}}>
                    {
                        !expandMostRecentData ?
                            <KeyboardArrowDown onClick={() => setExpandMostRecentData(true)}/> :
                            <KeyboardArrowUp onClick={() => setExpandMostRecentData(false)}/>
                    }
                </Grid>
            </Grid>
            {
                expandMostRecentData && (
                    <>
                        <Grid container>
                            <Grid item xs={12}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={chartOptions}
                                    immutable={false}
                                />
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper} sx={{ maxHeight: 300}}>
                            <Table sx={{minWidth: '700px'}} size="small" aria-label="most recent data" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell size="small">Date</StyledTableCell>
                                        <StyledTableCell size="small">Source</StyledTableCell>
                                        <StyledTableCell size="small">Price</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        recentPriceData.slice(0, showAllData ? recentPriceData.length : 5).map((row, i) => (
                                            <TableRow key={i}>
                                                <StyledTableCell size="small">{moment(row.valueDate).format('YYYY-MM-DD HH:mm')}</StyledTableCell>
                                                <StyledTableCell size="small">{translateLabel(row.source)}</StyledTableCell>
                                                <StyledTableCell size="small">{row.securityPrice}</StyledTableCell>
                                            </TableRow>
                                        ))
                                    }
                                    <TableRow>
                                        <TableCell colSpan={3} sx={{textAlign: 'center'}}>
                                            <Button size="small" color="primary" variant="outlined" onClick={() => {setShowAllData(!showAllData)}}>
                                                Show {showAllData ? 'top 5' : 'all'}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )
            }
        </Paper>
    )
}

export default PriceHistoryTable;