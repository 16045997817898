import {Security} from '../../../models/security';
import {renderValuationFieldValue} from '../../../utils/renderers/valuation-fields';
import moment from 'moment/moment';
import {formatNumber} from '../../../utils/utils';
import {Tooltip} from '@mui/material';
import {Valuation} from '../../../models/valuation';

export const renderSecurityTableField = (fieldName: string, security: Security) => {
    // If the field we are rendering is on the latest valuation, render the valuation field.
    if (security.latestValuation) {
        // Display the EPS value if relevant
        if (['eps0', 'eps1', 'eps2', 'eps3', 'eps4'].indexOf(fieldName) > -1) {
            const idx = parseInt(fieldName[fieldName.length - 1]);
            return (
                <Tooltip title={moment((security.latestValuation.epsValues as any)[idx].epsValueDate).format('YYYY-MM-DD')}>
                    <span>
                    {formatNumber((security.latestValuation.epsValues as any)[idx].value, 2, ' ')}
                    </span>
                </Tooltip>
            )
        }

        if (['earningsGrowthPerc1', 'earningsGrowthPerc2', 'earningsGrowthPerc3', 'earningsGrowthPerc4'].indexOf(fieldName) > -1) {
            try {
                const value: number = Number(security.latestValuation[fieldName as keyof Valuation]);
                return (
                    <>
                        {(value * 100).toFixed(2)} %
                    </>
                )
            } catch (e) {
                return <>{security.latestValuation[fieldName as keyof Valuation]}</>
            }

        }

        // fall back to displaying a field from the latest estimate, if we can.
        if (Object.keys(security.latestValuation).indexOf(fieldName) > -1) {
            return renderValuationFieldValue(security.latestValuation, fieldName);
        }
    }

    // Add additional fields that we might be rendering from the security here.
    switch (fieldName) {
        case 'owner':
            return security.owner ? `${security.owner?.firstName} ${security.owner.lastName}` : 'Unowned';
        case 'sectorClassification':
            return security.sectorClassification ? `${security.sectorClassification.name}` : 'N/A';
        case 'latestValuationDate':
            return security.latestValuation ? moment(security.latestValuation.createdOn).format('YYYY-MM-DD HH:mm') : 'N/A';
        case 'price':
            return security.latestValuation?.relatedPrice?.securityPrice || 'N/A';
        default:
            return 'N/A';
    }
}