import React from 'react';
import {Security} from '../../../models/security';
import styled from 'styled-components';
import {Link as MuiLink} from '@mui/material';

export interface TickerCellProps {
    security: Security;
    navigateToSecurityDetails: (security: Security) => void
}

const MuiClickableLink = styled(MuiLink)`
  cursor: pointer;
  margin-top: 4px;
  display: block;
`;

const TickerCell: React.FC<TickerCellProps> = ({security, navigateToSecurityDetails}) => {
    return (
        <>
            <MuiClickableLink onClick={() => navigateToSecurityDetails(security)}>
                <strong>{security.ticker}</strong>
            </MuiClickableLink>
            <small>
                {security.longCompanyName}
            </small>
        </>
    );
}

export default TickerCell;