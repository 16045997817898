import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {
    addExistingSecurityToUser,
    claimUnownedSecurity,
    createSecurity,
    fetchAllSecurities,
    fetchSecurity, getRecentPricesGraph, getSecurityEPSGraph,
    removeSecurityFromUser,
    subscribeToSecurity,
    unsubscribeFromSecurity,
    updateUserSecuritySettings
} from './securitiesAPI';
import {
    AddExistingSecurityToUserRequest,
    ClaimUnownedSecurityRequest,
    FetchSecurityEPSGraphRequest, FetchSecurityRequest, NewSecurity,
    RemoveSecurityFromUserRequest,
    Security, SecurityDetailResponse,
    SecurityListResponseItem,
    UpdateUserSecuritySettingsRequest
} from '../../models/security';
import {toast} from 'react-toastify';
import {BaseResponse, ListResponse, SingleResponse} from '../../models/responses/base-response';
import {UserSecuritySettings} from '../../models/userSecuritySettings';
import {BasicSecurityPrice} from '../../models/security-price';
import moment from 'moment/moment';

export interface SecuritiesState {
    securities: Security[];
    loading: boolean;
    error: boolean;
    errorDetail: string | undefined;
    newSecurityDialogOpen: boolean;
    activeSecurity?: Security; // The security that is being actively viewed
    activeOwner?: string; // The owner of the security that is being viewed.
    attemptedCreatingExistingSecurity: boolean; // true when the user tries to add a new security that is already owned.
    attemptedCreatingExistingSecurityObject?: NewSecurity; // temp store for the attempted creation of a security that is already owned. Used to assign the security to the current user as a secondary user.
    existingSecurity?: Security; // existing security object when the user attempts to create a security that is already owned.
    securityCreated: boolean;
    subscribing: boolean;
    shouldRefreshSecurities: boolean;
    epsGraphData?: any; // TODO: Figure out the typing here since we have hyphens in the object keys
    recentPriceData?: BasicSecurityPrice[]; // TODO: Figure out the typing here since we have hyphens in the object keys
    creatingNewSecurity: boolean;
}

const initialState: SecuritiesState = {
    securities: [],
    loading: false,
    error: false,
    errorDetail: undefined,
    newSecurityDialogOpen: false,
    activeSecurity: undefined,
    attemptedCreatingExistingSecurity: false,
    attemptedCreatingExistingSecurityObject: undefined,
    existingSecurity: undefined,
    securityCreated: false,
    subscribing: false,
    shouldRefreshSecurities: false,
    epsGraphData: undefined,
    recentPriceData: [],
    creatingNewSecurity: false,
};

export const createSecurityAsync = createAsyncThunk<
    any, // response type
    NewSecurity,
    {
        rejectValue: any // rejection value type
    }
>(
    'securities/createSecurity',
    async (security: NewSecurity, {
        dispatch,
        rejectWithValue
    }) => {
        const response = await createSecurity(security);
        if (!response.ok) {
            const responseJson = await response.json();
            return rejectWithValue({data: await responseJson.data, status: response.status}) // TODO: Type response
        }
        // dispatch(fetchAllSecuritiesAsync(security.owner));
        const responseJson = await response.json();
        return responseJson.data;
    }
);

export const addExistingSecurityToUserAsync = createAsyncThunk<
    any, // response type
    AddExistingSecurityToUserRequest,
    {
        rejectValue: any // rejection value type
    }
>(
    'securities/addExistingSecurityToUser',
    async (request: AddExistingSecurityToUserRequest, {
        dispatch,
        rejectWithValue
    }) => {
        const response = await addExistingSecurityToUser(request);
        const json: BaseResponse = await response.json();
        if (!response.ok) {
            return rejectWithValue(json) // TODO: Type response
        }
        return json.data;
    }
);

export const removeSecurityFromUserAsync = createAsyncThunk<
    any, // response type
    RemoveSecurityFromUserRequest,
    {
        rejectValue: any // rejection value type
    }
>(
    'securities/removeSecurityFromUser',
    async (request: RemoveSecurityFromUserRequest, {
        dispatch,
        rejectWithValue
    }) => {
        const response = await removeSecurityFromUser(request);
        if (!response.ok) {
            const json: BaseResponse = await response.json();
            return rejectWithValue(json) // TODO: Type response
        }
        return null;
    }
);

export const claimUnownedSecurityAsync = createAsyncThunk<
    any, // response type
    ClaimUnownedSecurityRequest,
    {
        rejectValue: any // rejection value type
    }
>(
    'securities/claimUnownedSecurity',
    async (request: ClaimUnownedSecurityRequest, {
        dispatch,
        rejectWithValue
    }) => {
        const response = await claimUnownedSecurity(request);
        const json: BaseResponse = await response.json();
        if (!response.ok) {
            return rejectWithValue(json);
        }
        return json.data;
    }
);

export const fetchAllSecuritiesAsync = createAsyncThunk(
    'securities/fetchSecurities',
    async (ownerId?: string) => {
        const response = await fetchAllSecurities(ownerId);
        const json: ListResponse<SecurityListResponseItem> = await response.json();
        return json.data;
    }
);

export const fetchSecurityAsync = createAsyncThunk(
    'securities/fetchSecurity',
    async ({securityId, userId}: FetchSecurityRequest, {dispatch}) => {
        const response = await fetchSecurity(securityId, userId);
        const json: SingleResponse<SecurityDetailResponse> = await response.json();
        return json.data;
    }
);

export const getSecurityEPSGraphAsync = createAsyncThunk(
    'securities/getSecurityEPSGraph',
    async ({securityId, userId}: FetchSecurityEPSGraphRequest, {dispatch}) => {
        const response = await getSecurityEPSGraph(securityId, userId);
        const json: any = await response.json();
        return json.data;
    }
);

export const getRecentPricesGraphAsync = createAsyncThunk(
    'securities/getRecentPricesGraph',
    async ({securityId}: FetchSecurityEPSGraphRequest) => {
        const response = await getRecentPricesGraph(securityId);
        const json: any = await response.json();
        return json.data;
    }
);

export const subscribeToSecurityAsync = createAsyncThunk(
    'securities/subscribeToSecurity',
    async (securityId: string, {dispatch}) => {
        const response = await subscribeToSecurity(securityId);
        const json: SingleResponse<Security> = await response.json();
        return json.data;
    }
);

export const unsubscribeFromSecurityAsync = createAsyncThunk(
    'securities/unsubscribeFromSecurity',
    async (securityId: string, {dispatch}) => {
        const response = await unsubscribeFromSecurity(securityId);
        const json: SingleResponse<Security> = await response.json();
        return json.data;
    }
);

export const updateUserSecuritySettingsAsync = createAsyncThunk(
    'securities/updateUserSecuritySettings',
    async ({security, userSecuritySettings}: UpdateUserSecuritySettingsRequest, {dispatch}) => {
        const response = await updateUserSecuritySettings(security, userSecuritySettings);
        const json: SingleResponse<UserSecuritySettings> = await response.json();
        return json.data;
    }
);

export const securitiesSlice = createSlice({
    name: 'securities',
    initialState,
    reducers: {
        openNewSecurityDialog: (state) => {
            state.newSecurityDialogOpen = true;
        },
        closeNewSecurityDialog: (state) => {
            state.newSecurityDialogOpen = false;
        },
        setActiveOwner: (state, action: PayloadAction<string>) => {
            state.activeOwner = action.payload;
        },
        acknowledgeSecurityExists: (state) => {
            state.attemptedCreatingExistingSecurity = false;
            state.attemptedCreatingExistingSecurityObject = undefined;
            state.existingSecurity = undefined;
        },
        acknowledgeSecurityCreated: (state) => {
            state.securityCreated = false;
        },
        addNewGraphPoints: (state, action: PayloadAction<any>) => {
            Object.keys(action.payload).forEach(key => {
                let valueToAdd: any = action.payload[key];

                // null values make the graph shift strangely since some series become broken.
                if (action.payload[key] === null) {
                    valueToAdd = 0;
                }

                // All values we're ingesting should be floats except dates that go on the graph.
                if (key !== 'dates') {
                    valueToAdd = parseFloat(valueToAdd);
                }


                if (Object.hasOwn(state.epsGraphData.graphData, key)) {
                    state.epsGraphData.graphData[key as keyof Object].pop();
                    state.epsGraphData.graphData[key as keyof Object].push(valueToAdd);
                } else {
                    console.warn('Received update for non existent key', key);
                }
            });

            const newPriceDataArr = state.recentPriceData ? [...state.recentPriceData] : [];
            // Add new price to state.recentPriceData, ensuring that securityPrice is fixed to 2 decimals, and converted back to a number.
            state.recentPriceData = [{
                valueDate: moment().format('YYYY-MM-DD HH:mm'),
                securityPrice: Number(parseFloat(action.payload.prices).toFixed(2)),
                source: 'twelvedata'
            }, ...newPriceDataArr];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createSecurityAsync.pending, (state, action) => {
                state.loading = true;
                state.error = false;
                state.newSecurityDialogOpen = false;
                state.securityCreated = false;
                state.creatingNewSecurity = true;
            })
            .addCase(createSecurityAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.securityCreated = true;
                toast.success('Security created.');
                state.creatingNewSecurity = false;
            })
            .addCase(createSecurityAsync.rejected, (state, action) => {
                state.loading = false;
                state.creatingNewSecurity = false;

                const response = action.payload;
                state.error = true;
                if (response.status === 409) {
                    state.existingSecurity = response.data;
                }

                if (response.data) {
                    toast.error(response.data);
                } else {
                    toast.error('Failed to create security. If this error persists, please contact support.');
                }
            })
            .addCase(addExistingSecurityToUserAsync.pending, (state, action) => {
                state.loading = true;
                state.error = false;
                state.newSecurityDialogOpen = false;
                state.securityCreated = false;
            })
            .addCase(addExistingSecurityToUserAsync.fulfilled, (state, action) => {
                state.attemptedCreatingExistingSecurityObject = undefined;
                state.attemptedCreatingExistingSecurity = false;
                state.existingSecurity = undefined;
                state.loading = false;
                state.securityCreated = true;
                state.shouldRefreshSecurities = true;
                toast.success('Security added to your list.');
            })
            .addCase(addExistingSecurityToUserAsync.rejected, (state, action) => {
                state.loading = false;

                state.error = true;
                toast.error('Failed to add security to your list.');
            })
            .addCase(removeSecurityFromUserAsync.pending, (state, action) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(removeSecurityFromUserAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.shouldRefreshSecurities = true;
                toast.success('Security removed from your list.');
            })
            .addCase(removeSecurityFromUserAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                toast.error('Failed to remove security from your list.');
            })
            .addCase(fetchAllSecuritiesAsync.pending, (state) => {
                state.loading = true;
                state.error = false;
                state.shouldRefreshSecurities = false;
                state.securities = [];
                state.activeSecurity = undefined;
            })
            .addCase(fetchAllSecuritiesAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.securities = action.payload.map(
                    (d: SecurityListResponseItem) => {
                        return {
                            ...d,
                            latestValuation: d.latestValuation && d.latestValuation.length > 0 ? d.latestValuation[0] : undefined
                        }
                    }
                );
            })
            .addCase(fetchAllSecuritiesAsync.rejected, (state, action) => {
                // If the promise was aborted, we're reloading for another user.
                if (action.error.message?.toLowerCase() !== 'aborted') {
                    state.loading = false;
                    state.error = true;
                }
            })
            .addCase(fetchSecurityAsync.pending, (state) => {
                state.loading = true;
                state.error = false;
                state.activeSecurity = undefined;
            })
            .addCase(fetchSecurityAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.activeSecurity = {
                    ...action.payload,
                    latestValuation: action.payload.latestValuation && action.payload.latestValuation.length > 0 ? action.payload.latestValuation[0] : undefined,
                    securitySettings: action.payload.securitySettings && action.payload.securitySettings.length > 0 ? action.payload.securitySettings[0] : undefined,
                    latestPrice: action.payload.latestPrice && action.payload.latestPrice.length > 0 ? action.payload.latestPrice[0] : undefined,
                };
            })
            .addCase(fetchSecurityAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            })
            .addCase(getSecurityEPSGraphAsync.pending, (state) => {
                state.epsGraphData = undefined;
            })
            .addCase(getSecurityEPSGraphAsync.fulfilled, (state, action) => {
                state.epsGraphData = action.payload;
            })
            .addCase(getSecurityEPSGraphAsync.rejected, (state, action) => {
                console.error('Could not load EPS graph data: ', action)
            })
            .addCase(getRecentPricesGraphAsync.pending, (state) => {
                state.recentPriceData = [];
            })
            .addCase(getRecentPricesGraphAsync.fulfilled, (state, action) => {
                state.recentPriceData = action.payload;
            })
            .addCase(getRecentPricesGraphAsync.rejected, (state, action) => {
                console.error('Could not load recent price graph data: ', action)
            })
            .addCase(claimUnownedSecurityAsync.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(claimUnownedSecurityAsync.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(claimUnownedSecurityAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            })
            .addCase(subscribeToSecurityAsync.pending, (state) => {
                state.subscribing = true;
            })
            .addCase(subscribeToSecurityAsync.fulfilled, (state, action) => {
                state.subscribing = false;
                state.activeSecurity = action.payload;
            })
            .addCase(subscribeToSecurityAsync.rejected, (state, action) => {
                state.subscribing = false;
            })
            .addCase(unsubscribeFromSecurityAsync.pending, (state) => {
                state.subscribing = true;
            })
            .addCase(unsubscribeFromSecurityAsync.fulfilled, (state, action) => {
                state.subscribing = false;
                state.activeSecurity = action.payload;
            })
            .addCase(unsubscribeFromSecurityAsync.rejected, (state, action) => {
                state.subscribing = false;
            })
            .addCase(updateUserSecuritySettingsAsync.fulfilled, (state, action) => {
                if (state.activeSecurity) {
                    state.activeSecurity.securitySettings = action.payload;
                    toast.success('Security settings updated.');
                }
            });
    },
});

export const {
    openNewSecurityDialog,
    closeNewSecurityDialog,
    setActiveOwner,
    acknowledgeSecurityExists,
    acknowledgeSecurityCreated,
    addNewGraphPoints
} = securitiesSlice.actions;

export const selectSecurities = (state: RootState) => state.securities;

export default securitiesSlice.reducer;
