import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {fetchAllSectors} from './sectorsAPI';
import {Sector} from '../../models/sector';
import {ListResponse} from '../../models/responses/base-response';

export interface SecuritySectorsState {
    sectors: Sector[];
    loading: boolean;
    error: boolean;
    errorDetail: string | undefined;
}

const initialState: SecuritySectorsState = {
    sectors: [],
    loading: false,
    error: false,
    errorDetail: undefined
};

export const fetchAllSectorsAsync = createAsyncThunk(
    'sectors/fetchAllSectors',
    async () => {
        const response = await fetchAllSectors();
        const json: ListResponse<Sector> = await response.json();
        return json.data;
    }
);

export const sectorsSlice = createSlice({
    name: 'sectors',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllSectorsAsync.pending, (state) => {
                state.loading = true;
                state.error = false;
                state.sectors = [];
            })
            .addCase(fetchAllSectorsAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.sectors = action.payload;
            })
            .addCase(fetchAllSectorsAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export const selectSecuritySectors = (state: RootState) => state.sectors;

export default sectorsSlice.reducer;
