import {useAppSelector} from '../app/hooks';
import {selectUsers} from '../store/users/usersSlice';

const useUsers = () => {
    const usersState = useAppSelector(selectUsers);

    return {
        currentUser: usersState.currentUser,
        isLoadingUsers: usersState.loading,
        isLoadingGroups: usersState.loadingGroups,
        users: usersState.users,
        groups: usersState.groups,
        editedUser: usersState.editedUser,
    }
}

export default useUsers;