import {DraggableColumnConfig, DraggableColumnItem} from '../models/draggable-column-config';
import {translateLabel} from '../utils/renderers/field-labels';

const sortColumnItems = (columns: DraggableColumnItem[]) => {
    return columns.sort((a, b) => a.content.localeCompare(b.content));
}

const DndValuationFields: DraggableColumnConfig = {
    columns: [
        {
            id: 'available',
            title: 'Available',
            items: sortColumnItems([
                {id: 'terminalGrowth48m', content: translateLabel('terminalGrowth48m')},
                {id: 'roe', content: translateLabel('roe')},
                {id: 'rotnav', content: translateLabel('rotnav')},
                {id: 'rotnavAdjusted', content: translateLabel('rotnavAdjusted')},
                {id: 'currencyPercentageZar', content: translateLabel('currencyPercentageZar')},
                {id: 'currencyPercentageUsd', content: translateLabel('currencyPercentageUsd')},
                {id: 'currencyPercentageEur', content: translateLabel('currencyPercentageEur')},
                {id: 'currencyPercentageGbp', content: translateLabel('currencyPercentageGbp')},
                {id: 'currencyPercentageCny', content: translateLabel('currencyPercentageCny')},
                {id: 'currencyPercentageOther', content: translateLabel('currencyPercentageOther')},
                {id: 'interestCover', content: translateLabel('interestCover')},
                {id: 'cumFrac', content: translateLabel('cumFrac')},
                {id: 'apportionFactor', content: translateLabel('apportionFactor')},
                {id: 'rolledEarningsT0', content: translateLabel('rolledEarningsT0')},
                {id: 'rolledEarningsT1', content: translateLabel('rolledEarningsT1')},
                {id: 'rolledEarningsT2', content: translateLabel('rolledEarningsT2')},
                {id: 'rolledEarningsT3', content: translateLabel('rolledEarningsT3')},
                {id: 'rolledEarningsT4', content: translateLabel('rolledEarningsT4')},
                {id: 'cumDiv', content: translateLabel('cumDiv')},
                {id: 'consGrowthPerc1', content: translateLabel('consGrowthPerc1')},
                {id: 'consGrowthPerc2', content: translateLabel('consGrowthPerc2')},
                {id: 'consGrowthPerc3', content: translateLabel('consGrowthPerc3')},
                {id: 'blendedIntDiff', content: translateLabel('blendedIntDiff')},
                {id: 'exitPrice', content: translateLabel('exitPrice')},
                {id: 'dps1', content: translateLabel('dps1')},
                {id: 'dps2', content: translateLabel('dps2')},
                {id: 'dps3', content: translateLabel('dps3')},
                {id: 'epsGrowthPerc1', content: translateLabel('epsGrowthPerc1')},
                {id: 'epsGrowthPerc2', content: translateLabel('epsGrowthPerc2')},
                {id: 'epsGrowthPerc3', content: translateLabel('epsGrowthPerc3')},
                {id: 'epsGrowthPerc4', content: translateLabel('epsGrowthPerc4')},
                {id: 'revenueGrowthPerc1', content: translateLabel('revenueGrowthPerc1')},
                {id: 'revenueGrowthPerc2', content: translateLabel('revenueGrowthPerc2')},
                {id: 'revenueGrowthPerc3', content: translateLabel('revenueGrowthPerc3')},
                {id: 'revenueGrowthPerc4', content: translateLabel('revenueGrowthPerc4')},
                {id: 'earningsGrowthPerc1', content: translateLabel('earningsGrowthPerc1')},
                {id: 'earningsGrowthPerc2', content: translateLabel('earningsGrowthPerc2')},
                {id: 'earningsGrowthPerc3', content: translateLabel('earningsGrowthPerc3')},
                {id: 'earningsGrowthPerc4', content: translateLabel('earningsGrowthPerc4')},
                {id: 'peg', content: translateLabel('peg')},
                {id: 'irr24mLc', content: translateLabel('irr24mLc')},
                {id: 'irr48mLc', content: translateLabel('irr48mLc')},
                {id: 'targetBuyingPrice', content: translateLabel('targetBuyingPrice')},
                {id: 'targetSellingPrice', content: translateLabel('targetSellingPrice')},
                {id: 'owner', content: translateLabel('owner')},
                {id: 'latestValuationDate', content: translateLabel('latestValuationDate')},
                {id: 'sectorClassification', content: translateLabel('sectorClassification')},
                {id: 'payoutPercentage', content: translateLabel('payoutPercentage')},
                {id: 'dividendPaymentFrequency', content: translateLabel('dividendPaymentFrequency')},
                {id: 'netCashPerShare', content: translateLabel('netCashPerShare')},
                {id: 'exitMultiple24m', content: translateLabel('exitMultiple24m')},
                {id: 'exitMultiple48m', content: translateLabel('exitMultiple48m')},
                {id: 'eps0', content: translateLabel('eps0')},
                {id: 'eps1', content: translateLabel('eps1')},
                {id: 'eps2', content: translateLabel('eps2')},
                {id: 'eps3', content: translateLabel('eps3')},
                {id: 'eps4', content: translateLabel('eps4')},
            ])
        },
        {
            id: 'active', title: 'Selected', items: [
                {id: 'price', content: translateLabel('price')},
                {id: 'fpe1', content: translateLabel('fpe1')},
                {id: 'dividendYield', content: translateLabel('dividendYield')},
                {id: 'irr24mZar', content: translateLabel('irr24mZar')},
                {id: 'irr48mZar', content: translateLabel('irr48mZar')},
            ]
        },
    ],
}

export default DndValuationFields;