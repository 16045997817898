import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid
} from '@mui/material';
import {VALUATIONS_TABLE_COLUMNS_CONFIG_KEY} from '../../constants';
import DndContainer from '../dnd/dnd-container';
import DndValuationFields from '../../config/dnd-valuation-fields';

export interface ValuationsTableConfigModalProps {
    open: boolean;
    onClose: () => void;
}

const ValuationsTableConfigModal: React.FC<ValuationsTableConfigModalProps> = ({open, onClose}) => {
    return <Dialog
        open={open}
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth={true}
    >
        <DialogTitle id="alert-dialog-title">
            Table Configuration
        </DialogTitle>
        <DialogContent>

            <DialogContentText id="alert-dialog-description" variant="body2">
                Select which fields you would like to show in the earnings estimates table.
            </DialogContentText>
            <Grid container>
                <DndContainer config={DndValuationFields} localStorageKey={VALUATIONS_TABLE_COLUMNS_CONFIG_KEY}/>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onClose()} autoFocus>
                Update
            </Button>
        </DialogActions>
    </Dialog>
}

export default ValuationsTableConfigModal;