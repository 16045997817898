import {Button, Container, Grid, Paper, Typography} from '@mui/material';
import React from 'react';
import {useMsal} from '@azure/msal-react';
import luminaLogo from './../../lumina-logo-black.png';
import peregrineLogo from './../../peregrine-logo-black.svg';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';

const LuminaLogo = styled.img`
    width: 150px;
`;
const PeregrineLogo = styled.img`
  width: 100px;
  position: absolute;
  bottom: 100px;
  left: calc(50% - 50px);
`;

const LoginPage: React.FC = () => {
    const {instance} = useMsal();

    return (
        <Container maxWidth={false} sx={{minHeight: '100vh', 'bgcolor': 'rgb(238, 242, 246)'}}>
            <Helmet>
                <title>
                    Login | Lumina
                </title>
            </Helmet>
            <Grid container sx={{minHeight: '100vh', 'bgcolor': 'rgb(238, 242, 246)'}} alignItems="center" justifyContent="center" columnSpacing={5}>
                <Grid item xs={12} sm={8} md={4} lg={3}>
                    <Paper elevation={3} sx={{p: 2, borderRadius: 2, textAlign: 'center'}}>
                        <LuminaLogo src={luminaLogo}/>
                        <Typography variant='body2' align="center" sx={{mb: 5, mt: 3}}>
                            To get started, please click the button below to log in.
                        </Typography>
                        <Button variant="contained" color="primary" size="small" onClick={() => instance.loginRedirect()}>Log in</Button>
                    </Paper>
                </Grid>
            </Grid>
            <PeregrineLogo src={peregrineLogo}/>
        </Container>
    )
}

export default LoginPage;