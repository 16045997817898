import {createTheme} from '@mui/material/styles';
import {teal} from '@mui/material/colors';

declare module '@mui/material/styles' {
    interface Palette {
        selected: Palette['primary'];
    }

    interface PaletteOptions {
        selected?: PaletteOptions['primary'];
    }
}

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#00897b',
        },
        secondary: {
            main: '#ffb300',
        },
        background: {
            default: '#eeeeee',
        },
        success: {
            main: '#00796b',
        },
    },
    typography: {
        fontFamily: 'Montserrat',
        fontSize: 14,
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: teal[300],
                        color: '#FFF',
                        borderBottom: '0'
                    },
                },
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: teal[500],
                }
            }
        },
        // MuiButton: {
        //     styleOverrides: {
        //         containedInfo: {
        //             backgroundColor: lightBlue[900],
        //             color: '#FFF',
        //         }
        //     }
        // }
    },
});


export default theme;