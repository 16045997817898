import React from 'react';
import {RowData} from '../../models/row-data';
import {TableRow} from '@mui/material';
import StyledTableCell from '../styled-table-cell';

export type DataBlockProps = {
    datum: RowData;
}

const DataBlockNode: React.FC<DataBlockProps> = ({datum}) => {
    return (
        <TableRow>
            <StyledTableCell size="small" width="40%">{datum.label}</StyledTableCell>
            <StyledTableCell size="small" sx={{textAlign: 'right'}}>
                {datum.value}
            </StyledTableCell>
        </TableRow>
    )
}

export default DataBlockNode;