import React from 'react';
import {Button, ButtonGroup, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import useSectors from '../../hooks/use-sectors';
import {Exchange} from '../../models/security';

export interface ToolbarContainerProps {
    setSearchText: (searchText: string) => void;
    onFilterSector: (sector: string) => void;
    selectedSector: string;
    onSetSelectedExchanges: (exchanges: string[]) => void;
}

interface ExchangeLabel {
    label: string;
    value: string;
}

const DashboardToolbar: React.FC<ToolbarContainerProps> = ({setSearchText, onFilterSector, selectedSector, onSetSelectedExchanges}) => {
    const {sectors} = useSectors();
    const exchanges: ExchangeLabel[] = [
        {
            label: 'JSE',
            value: 'SJ'
        },
        {
            label: 'Non-JSE',
            value: '!SJ'
        },
    ];
    const [activeExchanges, setActiveExchanges] = React.useState<ExchangeLabel[]>(exchanges);



    // TODO: Refactor this into an ExchangeSelector component.
    // Since this component is the only aware of all the exchanges and such, give the information of
    // all selected exchanges to the parent component.
    const toggleExchange = (exchange: ExchangeLabel) => {
        let newExchanges = [...activeExchanges];
        if (activeExchanges.find(e => e.value === exchange.value)) {
            newExchanges = newExchanges.filter(ex => ex.value !== exchange.value);
        } else {
            newExchanges= [...newExchanges, exchange];
        }

        if (newExchanges.length > 0) {
            setActiveExchanges(newExchanges);
        } else {
            setActiveExchanges(exchanges);
        }
    }

    React.useEffect(() => {
        onSetSelectedExchanges(activeExchanges.map((e) => e.value));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeExchanges]);

    return (
        <Grid container sx={{py: 1}} justifyContent="space-between" spacing={2}>
            <Grid item xs={12} md={9}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={5}>
                        <FormControl sx={{mr: 3, display: 'flex'}}>
                            <InputLabel id="ddashboard-sector-filter-label" shrink>Sector</InputLabel>
                            <Select
                                labelId="dashboard-sector-filter-label"
                                id="dashboard-sector-filter-label"
                                label="Sector"
                                onChange={(event) => onFilterSector(event.target.value as string)}
                                size="small"
                                value={selectedSector}
                                displayEmpty
                                notched
                                autoWidth={true}
                            >
                                <MenuItem value="">
                                    All sectors
                                </MenuItem>
                                {
                                    sectors.map((s) => (
                                        <MenuItem value={s.id} key={s.id}>
                                            {s.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl>
                            <ButtonGroup variant="contained" aria-label="Basic button group">
                                {
                                    exchanges.map(exchange => (
                                        <Button
                                            key={exchange.value}
                                            color='primary'
                                            variant={activeExchanges.find(e => e.value === exchange.value) || activeExchanges.length === 0 ? 'contained' : 'outlined'}
                                            onClick={() => toggleExchange(exchange)}
                                        >
                                            {exchange.label}
                                        </Button>
                                    ))
                                }
                            </ButtonGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    size="small"
                    placeholder="Search..."
                    onChange={(e) => setSearchText(e.target.value)}
                    fullWidth
                />
            </Grid>
        </Grid>
    )
}

export default DashboardToolbar;