import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface DialogState {
    open: boolean;
    message: string;
    title: string;
}

export interface OpenDialogAction {
    message: string;
    title: string;
}

const initialState: DialogState = {
    open: false,
    message: '',
    title: ''
};

export const dialogSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        openInfoDialog: (state, action: PayloadAction<OpenDialogAction>) => {
            state.message = action.payload.message;
            state.title = action.payload.title;
            state.open = true;
        },
        closeInfoDialog: (state) => {
            state.open = false;
        }
    },
    extraReducers: (builder) => {
    },
});

export const {openInfoDialog, closeInfoDialog} = dialogSlice.actions;
export const selectDialog = (state: RootState) => state.dialog;

export default dialogSlice.reducer;
