import React, {useState} from 'react';
import {
    Alert,
    Box,
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid, MenuItem, Select, Switch, TextField, Typography
} from '@mui/material';
import {useAppDispatch} from '../../../app/hooks';
import {useDebounce} from 'use-debounce';
import {API_BASE} from '../../../constants';
import useUsers from '../../../hooks/use-users';
import {useNavigate} from 'react-router-dom';
import Loader from '../../loader';

export interface NewSecuritySearchDialogProps {
    onClose: () => void;
    open: boolean;
}

interface SingleSearchResult {
    country: string;
    currency: string;
    exchange: string;
    figiCode: string;
    micCode: string;
    name: string;
    symbol: string;
    type: string;
    open: boolean;
}

const NewSecuritySearchDialog: React.FC<NewSecuritySearchDialogProps> = ({onClose, open}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {currentUser} = useUsers();
    const [searchInput, setSearchInput] = useState('');
    const [searchValue] = useDebounce(searchInput, 500);
    const [selectedCountry, setSelectedCountry] = React.useState('South Africa');
    const [searchResults, setSearchResults] = useState<SingleSearchResult[]>([]);
    const [selectedSearchResult, setSelectedSearchResult] = React.useState<SingleSearchResult | undefined>();
    const [isCreating, setIsCreating] = React.useState(false);
    const [creationError, setCreationError] = React.useState(false);
    const [errorDetail, setErrorDetail] = React.useState<string|undefined>();
    const [includeOtc, setIncludeOtc] = React.useState<boolean>(false);

    React.useEffect(() => {
        // When the open state changes (i.e. open or close the dialog), reset all inputs.
        setSearchInput('');
        setSelectedCountry('South Africa');
        setSearchResults([]);
    }, [open]);

    React.useEffect(() => {
        if (searchValue && searchValue.length >= 3) {
            fetch(`${API_BASE}/api/securities/search_all/?search=${searchValue}&country=${selectedCountry}&include_otc=${includeOtc}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            }).then((response) => {
                response.json().then(({data}) => {
                    setSearchResults(data);
                });
            });
        }
    }, [searchValue, selectedCountry, includeOtc]);

    const setOpenSearchResult = (index: number) => {
        setSearchResults(searchResults.map((item, itemIndex) => ({
            ...item,
            open: itemIndex === index ? !item.open : false
        })));
    }

    const countryOptions = [
        'Argentina',
        'Australia',
        'Austria',
        'Belgium',
        'Botswana',
        'Brazil',
        'Canada',
        'Chile',
        'China',
        'Colombia',
        'Czech Republic',
        'Denmark',
        'Egypt',
        'Estonia',
        'Finland',
        'France',
        'Germany',
        'Greece',
        'Hong Kong',
        'Iceland',
        'India',
        'Indonesia',
        'Ireland',
        'Israel',
        'Italy',
        'Japan',
        'Kuwait',
        'Latvia',
        'Lithuania',
        'Malaysia',
        'Mexico',
        'Netherlands',
        'New Zealand',
        'Norway',
        'Pakistan',
        'Peru',
        'Poland',
        'Portugal',
        'Qatar',
        'Romania',
        'Russia',
        'Saudi Arabia',
        'Singapore',
        'South Africa',
        'South Korea',
        'Spain',
        'Sweden',
        'Switzerland',
        'Taiwan',
        'Thailand',
        'Turkey',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'Venezuela',
    ];

    const selectSecurity = async (security: SingleSearchResult) => {
        setIsCreating(true);
        setErrorDetail(undefined);
        setCreationError(false);
        setSelectedSearchResult(security);
        try {
            const response = await fetch(`${API_BASE}/api/securities/create_from_search_results/`, {
                method: 'POST',
                body: JSON.stringify(security),
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            const result = await response.json();

            if (response.status !== 201) {
                console.log(result.data);
                if (result.data) {
                    setErrorDetail(result.data);
                }
                setCreationError(true);
                setIsCreating(false);
                return;
            }

            const {id} = result.data;
            if (currentUser?.id) {
                navigate(`securities-valuations/${id}/user/${currentUser.id}/`);
            }
        } catch (err) {
            console.error('Could not create new security', err);
            setIsCreating(false);
            setCreationError(true);
        }
    }

    const handleOtcSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIncludeOtc(event.target.checked);
    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                if (!isCreating) onClose()
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">
                Add New Security
            </DialogTitle>
            {
                (isCreating && selectedSearchResult) && (
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Loader
                                message={`${selectedSearchResult.name} (${selectedSearchResult.symbol}) is being added to your list. Please wait...`}
                                loading={true}></Loader>
                        </DialogContentText>
                    </DialogContent>
                )
            }
            {
                !isCreating && (
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {
                                creationError && (
                                    <Alert severity="error" sx={{my: 3}}>
                                        An error occurred. Please try again. If the issue persists, please contact support.<br />
                                        {
                                            errorDetail && (
                                                <span><strong>Detail:</strong> {errorDetail}</span>
                                            )
                                        }
                                    </Alert>
                                )
                            }
                            Enter a search term for the security you'd like to add to Lumina.
                        </DialogContentText>
                        <Grid container sx={{mt: 3, mb: 5}} alignItems="center">
                            <Grid item xs={12} md={6}>
                                <Select
                                    labelId="dashboard-sector-filter-label"
                                    id="dashboard-sector-filter-label"
                                    label="Sector"
                                    onChange={(event) => setSelectedCountry(event.target.value as string)}
                                    size="small"
                                    value={selectedCountry}
                                    displayEmpty
                                    notched
                                    fullWidth={true}
                                >
                                    <MenuItem value="">
                                        Global
                                    </MenuItem>
                                    {
                                        countryOptions.map((s) => (
                                            <MenuItem value={s} key={s}>
                                                {s}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{pl: 3}}>
                                <TextField
                                    variant="standard"
                                    size="small"
                                    inputProps={{style: {textAlign: 'left'}}}
                                    required={true}
                                    fullWidth={true}
                                    InputLabelProps={{shrink: true}}
                                    multiline={false}
                                    onChange={(e) => {
                                        setSearchInput(e.target.value);
                                    }}
                                    placeholder={"Search..."}
                                />
                            </Grid>
                            <Grid item xs={12}sx={{mt: 1}}>
                                <Switch onChange={handleOtcSwitch}/>
                                <Typography sx={{display: 'inline'}}>
                                    Include OTC
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} style={{
                                maxHeight: '300px',
                                minHeight: '300px',
                                overflowY: 'scroll',
                                border: '1px solid #333'
                            }}>
                                {
                                    searchResults.length > 0 ? searchResults.map((searchResult: SingleSearchResult, index: number) => (
                                        <Box key={`${searchResult.symbol}:${searchResult.exchange}`}
                                             style={{borderBottom: '1px solid #CCC'}} sx={{px: 1, py: 1}}
                                             onClick={() => setOpenSearchResult(index)}>
                                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                                <strong>{searchResult.symbol}: {searchResult.name}</strong>
                                                <span>{searchResult.country}</span>
                                            </Box>
                                            {
                                                searchResult.open && (
                                                    <Grid container justifyContent="space-between" alignItems="center">
                                                        <Box>
                                                            <span>Currency: {searchResult.currency}</span><br/>
                                                            <span>Exchange: {searchResult.exchange}</span>
                                                        </Box>
                                                        <Button size="small" variant="contained" onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            selectSecurity(searchResult);
                                                        }}>
                                                            Select
                                                        </Button>
                                                    </Grid>
                                                )
                                            }
                                        </Box>
                                    )) : (
                                        <Grid container justifyContent="center" alignItems="center" sx={{p: 5}}>
                                            <Typography variant="body2" color="textSecondary">
                                                No search results found. Please adjust your search term or country.
                                            </Typography>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>
                )
            }
            {
                !isCreating && (
                    <DialogActions>
                        <Grid container justifyContent="space-between">
                            <Button onClick={onClose}>
                                Close
                            </Button>
                        </Grid>
                    </DialogActions>
                )
            }
        </Dialog>
    )
}

export default NewSecuritySearchDialog;