import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {DraggableColumnItem, DroppableColumn} from '../../models/draggable-column-config';
import {SECURITIES_TABLE_COLUMNS_CONFIG_KEY, VALUATIONS_TABLE_COLUMNS_CONFIG_KEY} from '../../constants';
import DndValuationFields from '../../config/dnd-valuation-fields';

export interface ConfigState {
    [SECURITIES_TABLE_COLUMNS_CONFIG_KEY]: DroppableColumn[],
    [VALUATIONS_TABLE_COLUMNS_CONFIG_KEY]: DroppableColumn[],
}

const loadTableConfig = (key: string) => {
    const configJson = localStorage.getItem(key);
    if (configJson) {
        const columnConfig: DroppableColumn[] = JSON.parse(configJson);

        // Check for new columns in DndValuationFields that are not present in localStorage config.
        // These columns can exist when new columns are added.
        const existingColumns: string[] = []
        columnConfig.forEach((column) => {
            column.items.forEach((item) => {
                existingColumns.push(item.id);
            })
        });
        DndValuationFields.columns[0].items.forEach((availableColumn) => {
            // check if the field is present in either the avaialble or active columns in storage
            if (existingColumns.findIndex((existingItem: string) => existingItem === availableColumn.id) < 0) {
                // If avaiableColumn (from DndValuationFields) is not present in existingColumns (string[] of items in config, both available and selected)
                columnConfig[0].items.push(availableColumn);
            }
        });

        return columnConfig;
    }

    return DndValuationFields.columns;
}

const initialState: ConfigState = {
    [SECURITIES_TABLE_COLUMNS_CONFIG_KEY]: loadTableConfig(SECURITIES_TABLE_COLUMNS_CONFIG_KEY),
    [VALUATIONS_TABLE_COLUMNS_CONFIG_KEY]: loadTableConfig(VALUATIONS_TABLE_COLUMNS_CONFIG_KEY),
};

export interface UpdateTableConfigPayload {
    key: string;
    config: DroppableColumn[];
}

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        updateConfig: (state, action: PayloadAction<UpdateTableConfigPayload>) => {
            state[action.payload.key as keyof ConfigState] = action.payload.config;
            localStorage.setItem(action.payload.key, JSON.stringify(action.payload.config));
        },
    },
    extraReducers: (builder) => {
        // Nothing to do here.
    },
});

export const { updateConfig} = configSlice.actions;

export const selectConfig = (state: RootState) => state.config;

export default configSlice.reducer;
