import {Button, TableHead, TableRow} from '@mui/material';
import React from 'react';
import {translateLabel} from '../../utils/renderers/field-labels';
import SettingsIcon from '@mui/icons-material/Settings';
import StyledTableCell from '../../components/styled-table-cell';

export interface ValuationTableHeadersProps {
    onOpenValuationTableFieldsConfigDialog: () => void;
    fields: string[];
}

const ValuationTableHeaders: React.FC<ValuationTableHeadersProps> = ({fields, onOpenValuationTableFieldsConfigDialog}) => {
    return (
        <TableHead>
            <TableRow>
                <StyledTableCell size="small">
                    <strong>
                        Date
                    </strong>
                </StyledTableCell>
                {
                    fields.map((field: string, index: number) => (
                        // Use a "2" colSpan for the last column to allow for the view button in subsequent table rows
                        <StyledTableCell size="small" style={{textAlign: 'end'}} key={field}>
                            <strong>
                                {translateLabel(field)}
                            </strong>
                        </StyledTableCell>
                    ))
                }
                <StyledTableCell style={{textAlign: 'end'}}>
                    <Button onClick={onOpenValuationTableFieldsConfigDialog}>
                        <SettingsIcon/>
                    </Button>
                </StyledTableCell>
            </TableRow>
        </TableHead>
    )
}

export default ValuationTableHeaders;