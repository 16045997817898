import {Breadcrumbs} from '@mui/material';
import styled from 'styled-components';

const StyledBreadcrumbs = styled(Breadcrumbs)`
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: white;
  width: 100%;
  padding: 8px;
  box-shadow: 0px -7px 10px -10px black;
  z-index: 1200;
`;

export default StyledBreadcrumbs;