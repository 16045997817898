import React, {PropsWithChildren} from 'react';
import {
    Box, CssBaseline, Grid,
    Toolbar, Typography
} from '@mui/material';
import MainAppBar from './main-app-bar';
import MainDrawer from './main-drawer';
import {Helmet} from "react-helmet";
import UseRequirePermissions from '../../hooks/use-require-permissions';
import useLuminaWebsocket from '../../hooks/use-lumina-websocket';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorBoundaryFallback from '../error-boundary-fallback';
import * as Sentry from '@sentry/react';

export interface PagePermissionSettings {
    permissions: string[];
    requireAll: boolean;
}

type PageContainerProps = {
    pageTitle?: string;
    browserTabTitle?: string;
    pagePermissions?: PagePermissionSettings;
}

const PageContainer: React.FC<PropsWithChildren<PageContainerProps>> = ({
                                                                            children,
                                                                            pageTitle,
                                                                            browserTabTitle,
                                                                            pagePermissions
                                                                        }: PropsWithChildren<PageContainerProps>) => {
    const isAuthorized = UseRequirePermissions(pagePermissions?.permissions, pagePermissions?.requireAll);
    useLuminaWebsocket();
    const [menuExpanded, setMenuExpanded] = React.useState(false);

    const formulateBrowserTabTitle = () => {
        if (!isAuthorized) {
            return 'Unauthorized | Lumina';
        }

        if (browserTabTitle) {
            return `${browserTabTitle} | Lumina`;
        }

        return 'Lumina';
    }

    const unauthorizedContent = () => {
        return (
            <>
                <Typography variant="h3">
                    Access denied.
                </Typography>
                <Typography variant="body2">
                    You are not allowed to access this page. <br/>
                    The remove this message, please contact technical support.
                </Typography>
            </>
        );
    }

    return (
        <>
            <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback} showDialog={false}>
                <Helmet>
                    <title>
                        {formulateBrowserTabTitle()}
                    </title>
                </Helmet>
                <Box sx={{display: 'flex'}}>
                    <CssBaseline/>
                    <MainAppBar pageTitle={pageTitle} menuExpanded={menuExpanded}
                                onExpandMenu={() => setMenuExpanded(true)}/>
                    <MainDrawer menuExpanded={menuExpanded} onCollapseMenu={() => setMenuExpanded(false)}/>
                    <Box
                        component="main"
                        sx={{flexGrow: 1, px: 2, minHeight: '100vh', display: 'flex', flexDirection: 'column'}}
                    >
                        <Toolbar/>
                        <Grid container sx={{flex: 1}}>
                            <Grid item xs={12} sx={{
                                p: 2,
                                bgcolor: 'rgb(238, 242, 246)',
                                borderRadius: '8px',
                                position: 'relative',
                                paddingBottom: '48px'
                            }}>
                                {
                                    !isAuthorized ? unauthorizedContent() : children
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Sentry.ErrorBoundary>
        </>
    )
}

export default PageContainer;