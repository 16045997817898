import {useAppDispatch, useAppSelector} from '../app/hooks';
import {selectUsers} from '../store/users/usersSlice';
import React from 'react';
import {WS_BASE_URL} from '../constants';
import useWebSocket from 'react-use-websocket';
import {toast} from 'react-toastify';
import {fetchAllNotificationsAsync, getUnreadNotificationsCountAsync} from '../store/notifications/notificationsSlice';

const createWebSocketOptions = () => ({
    shouldReconnect: () => {
        return true;
    },
    // Attempt to reconnect twice a minute for 5 mins.
    reconnectAttempts: 10,
    reconnectInterval: 30000,
    onReconnectStop: () => {
        toast.error('You have been disconnected. Please refresh the page.', {autoClose: false});
    }
});

const useLuminaWebsocket = () => {
    const dispatch = useAppDispatch();
    const usersState = useAppSelector(selectUsers);
    const [webSocketURL] = React.useState<string>(`${WS_BASE_URL}/${usersState.currentUser?.id}/`);
    const websocketConnection = useWebSocket(webSocketURL, createWebSocketOptions());

    React.useEffect(() => {
        if (websocketConnection && websocketConnection.lastMessage && usersState.currentUser?.id) {
            const messageDetails = JSON.parse(websocketConnection.lastMessage.data);
            if (messageDetails.type === 'notification') {
                if (messageDetails.message.sent_by !== usersState.currentUser.id) {
                    toast.info(messageDetails.message.message);
                    dispatch(fetchAllNotificationsAsync({page: 1, read: false}));
                    dispatch(getUnreadNotificationsCountAsync());
                }
            }
        }
    }, [dispatch, websocketConnection, usersState.currentUser]);
}

export default useLuminaWebsocket;