import {FormControl, Grid, TextField} from '@mui/material';
import {Control, Controller, FieldError} from 'react-hook-form';
import React from 'react';

export type FTextFieldProps = {
    control: Control<any>,
    name: string,
    label?: string,
    textAlign?: 'left' | 'right',
    required?: boolean;
    variant?: 'outlined' | 'filled' | 'standard';
    fullWidth?: boolean;
    multiline?: boolean;
    rows?: number;
    pattern?: any;
    error?: FieldError;
    patternErrorText?: string;
}

const FTextField: React.FC<FTextFieldProps> = ({
                                                   control,
                                                   name,
                                                   label,
                                                   textAlign,
                                                   required,
                                                   variant = 'standard',
                                                   fullWidth = false,
                                                   multiline = false,
                                                   rows = 1,
                                                   pattern,
                                                   error,
                                                   patternErrorText
                                               }) => {
    const getHelperText = () => {
        if (error) {
            if (error.type === 'required') {
                return 'This field is required.'
            }

            if (error.type === 'pattern') {
                return patternErrorText || 'Enter a valid value.';
            }

            return 'Enter a valid value.'
        }


        return '';
    }
    return (
        <Grid item xs={12} sx={{mt: 2}}>
            <FormControl fullWidth sx={{mb: 3}} size="small">
                <Controller
                    name={name}
                    control={control}
                    rules={{pattern}}
                    render={
                        ({field}) =>
                            <TextField
                                error={error !== undefined}
                                label={label}
                                variant={variant}
                                size="small"
                                inputProps={{style: {textAlign: textAlign || 'left'}}}
                                required={required}
                                fullWidth={fullWidth}
                                InputLabelProps={{shrink: true}}
                                multiline={multiline}
                                rows={rows}
                                helperText={getHelperText()}
                                {...field}
                            />
                    }
                />
            </FormControl>
        </Grid>
    )
}

export default FTextField;