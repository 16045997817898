import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {useAppDispatch} from '../../../app/hooks';
import {fetchValuationAsync} from '../../../store/valuations/valuationsSlice';
import PageHeader from '../../../components/page-header';
import NewValuationDrawer from '../new-valuation-drawer';
import PageContainer from '../../../components/page-container';
import Loader from '../../../components/loader';
import {Box, Button, Grid, Typography} from '@mui/material';
import {EpsValue, Valuation} from '../../../models/valuation';
import moment from 'moment/moment';
import {RowData} from '../../../models/row-data';
import DataBlock from '../../../components/data-block/data-block';
import StyledBreadcrumbs from '../../../components/styled-breadcrumbs';
import {formatNumber} from '../../../utils/utils';
import useValuations from '../../../hooks/use-valuations';
import useSecurity from '../../../hooks/use-security';
import {translateLabel} from '../../../utils/renderers/field-labels';

export type ValuationDetailRouteParams = {
    securityId: string;
    userId: string;
    valuationId: string;
}

const ValuationDetailPage: React.FC = () => {
    const {valuationId, securityId} = useParams<ValuationDetailRouteParams>();
    const {currentValuation, valuationsLoading} = useValuations();
    const {activeSecurity} = useSecurity();
    const dispatch = useAppDispatch();
    const [valuation, setValuation] = React.useState<Valuation | undefined>(undefined);
    const [inputs, setInputs] = React.useState<RowData[]>([]);
    const [calculatedFields, setCalculatedFields] = React.useState<RowData[]>([]);
    const [bloomberPriceFields, setBloomberPriceFields] = React.useState<RowData[]>([]);

    React.useEffect(() => {
        /**
         * Fetch valuation data when loading the page (or changing valuationId)
         */
        if (valuationId) {
            dispatch(fetchValuationAsync(valuationId))
        }
    }, [dispatch, valuationId]);

    React.useEffect(() => {
        /**
         * Keep track of the current valuation in this component. Enables mutation of the valuation.
         */
        setValuation(currentValuation);
    }, [currentValuation]);

    /**
     * Create a numerically formatted field using the formatNumber method.
     * @param label string: The label to display.
     * @param value number | undefined: The value to format. Undefined will be handled by formatNumber.
     * @param decimals number: The number of decimals to display.
     * @param sep string: Thousands separator.
     * @param suffix string: Suffix to display
     */
    const createFormattedNumberField = (label: string, value: number | undefined, decimals: number, sep: string, suffix: string = '') => {
        return {
            label,
            value: formatNumber(value, decimals, sep, suffix)
        };
    }

    /**
     * Generate a key value pair of label and value
     * @param label string: The label to display
     * @param value any: The value to display
     */
    const createField = (label: string, value: any) => {
        return {
            label,
            value,
        };
    }

    /**
     * Create groups of fields to display.
     * Used to map objects into smaller groups with specific keys.
     */
    React.useEffect(() => {
        if (!valuation) {
            return;
        }

        // Analyst input fields
        const inputFields = [
            createField('Alternative scenario', valuation.alternativeScenario),
            createField('Comment', valuation.comment),
            createFormattedNumberField('Payout percentage', valuation.payoutPercentage, 2, ' ', '%'),
            createField('Dividend payment frequency', valuation.dividendPaymentFrequency),
            createFormattedNumberField('Associate Net Cash per Share', valuation.netCashPerShare, 2, ' ', ''),
            createFormattedNumberField('Exit multiple 24m', valuation.exitMultiple24m, 2, ' ', 'x'),
            createFormattedNumberField('Exit multiple 48m', valuation.exitMultiple48m, 2, ' ', 'x'),
            createFormattedNumberField('Terminal growth 48m', valuation.terminalGrowth48m, 2, ' ', '%'),
            createFormattedNumberField('ROE', valuation.roe, 2, ' ', '%'),
            createFormattedNumberField('ROT NAV', valuation.rotnav, 2, ' ', '%'),
            createFormattedNumberField('ROT NAV adjusted', valuation.rotnavAdjusted, 2, ' ', '%'),
            createFormattedNumberField('Currency percentage zar', valuation.currencyPercentageZar, 2, ' ', '%'),
            createFormattedNumberField('Currency percentage usd', valuation.currencyPercentageUsd, 2, ' ', '%'),
            createFormattedNumberField('Currency percentage eur', valuation.currencyPercentageEur, 2, ' ', '%'),
            createFormattedNumberField('Currency percentage gbp', valuation.currencyPercentageGbp, 2, ' ', '%'),
            createFormattedNumberField('Currency percentage cny', valuation.currencyPercentageCny, 2, ' ', '%'),
            createFormattedNumberField('Currency percentage other', valuation.currencyPercentageOther, 2, ' ', '%'),
            createFormattedNumberField('Interest cover', valuation.interestCover, 2, ' ', ''),
            createFormattedNumberField('Target Buying Price', valuation.targetBuyingPrice, 2, ' ', ''),
            createFormattedNumberField('Target Selling Price', valuation.targetSellingPrice, 2, ' ', ''),
        ];

        // Calculated output fields
        const calculatedFields = [
            createFormattedNumberField('CUM Frac', valuation.cumFrac !== undefined ? valuation.cumFrac * 100 : undefined, 2, ' ', '%'),
            createField('CUM Frac Date', valuation.cumFracDate ? valuation.cumFracDate: 'N/A'),
            createFormattedNumberField('Apportion Factor', valuation.apportionFactor !== undefined ? valuation.apportionFactor * 100 : undefined, 2, ' ', '%'),
            createFormattedNumberField('Rolled Earnings T0', valuation.rolledEarningsT0, 2, ' ', ''),
            createFormattedNumberField('Rolled Earnings T1', valuation.rolledEarningsT1, 2, ' ', ''),
            createFormattedNumberField('Rolled Earnings T2', valuation.rolledEarningsT2, 2, ' ', ''),
            createFormattedNumberField('Rolled Earnings T3', valuation.rolledEarningsT3, 2, ' ', ''),
            createFormattedNumberField('Rolled Earnings T4', valuation.rolledEarningsT4, 2, ' ', ''),
            createFormattedNumberField('CUM Div', valuation.cumDiv, 2, ' ', ''),
            createFormattedNumberField('FPE 1', valuation.fpe1, 2, ' ', 'x'),
            createFormattedNumberField('Cons Growth % 1', valuation.consGrowthPerc1 !== undefined ? valuation.consGrowthPerc1 * 100 : undefined, 2, ' ', '%'),
            createFormattedNumberField('Cons Growth % 2', valuation.consGrowthPerc2 !== undefined ? valuation.consGrowthPerc2 * 100 : undefined, 2, ' ', '%'),
            createFormattedNumberField('Cons Growth % 3', valuation.consGrowthPerc3 !== undefined ? valuation.consGrowthPerc3 * 100 : undefined, 2, ' ', '%'),
            createFormattedNumberField('Blended Int Diff', valuation.blendedIntDiff, 2, ' ', '%'),
            createField(`Swap Rate (USD - ${moment(valuation.swapRateUsd?.valueDate).format('YYYY-MM-DD')})`, valuation.swapRateUsd ? `${valuation.swapRateUsd?.value.toFixed(5)}` : 'N/A'),
            createField(`Swap Rate (EUR - ${moment(valuation.swapRateEur?.valueDate).format('YYYY-MM-DD')})`, valuation.swapRateEur ? `${valuation.swapRateEur?.value.toFixed(5)}` : 'N/A'),
            createField(`Swap Rate (GBP - ${moment(valuation.swapRateGbp?.valueDate).format('YYYY-MM-DD')})`, valuation.swapRateGbp ? `${valuation.swapRateGbp?.value.toFixed(5)}` : 'N/A'),
            createField(`Swap Rate (CNY - ${moment(valuation.swapRateCny?.valueDate).format('YYYY-MM-DD')})`, valuation.swapRateCny ? `${valuation.swapRateCny?.value.toFixed(5)}` : 'N/A'),
            createFormattedNumberField('Exit Price', valuation.exitPrice, 2, ' ', ''),
            createFormattedNumberField('DPS 1', valuation.dps1, 2, ' ', ''),
            createFormattedNumberField('DPS 2', valuation.dps2, 2, ' ', ''),
            createFormattedNumberField('DPS 3', valuation.dps3, 2, ' ', ''),
            createFormattedNumberField('EPS Growth % 1', valuation.epsGrowthPerc1 !== undefined ? valuation.epsGrowthPerc1 * 100 : undefined, 2, ' ', '%'),
            createFormattedNumberField('EPS Growth % 2', valuation.epsGrowthPerc2 !== undefined ? valuation.epsGrowthPerc2 * 100 : undefined, 2, ' ', '%'),
            createFormattedNumberField('EPS Growth % 3', valuation.epsGrowthPerc3 !== undefined ? valuation.epsGrowthPerc3 * 100 : undefined, 2, ' ', '%'),
            createFormattedNumberField('EPS Growth % 4', valuation.epsGrowthPerc4 !== undefined ? valuation.epsGrowthPerc4 * 100 : undefined, 2, ' ', '%'),
            createFormattedNumberField('Revenue Growth % 1', valuation.revenueGrowthPerc1, 2, ' ', ''),
            createFormattedNumberField('Revenue Growth % 2', valuation.revenueGrowthPerc2, 2, ' ', ''),
            createFormattedNumberField('Revenue Growth % 3', valuation.revenueGrowthPerc3, 2, ' ', ''),
            createFormattedNumberField('Revenue Growth % 4', valuation.revenueGrowthPerc4, 2, ' ', ''),
            createFormattedNumberField('Dividend Yield', valuation.dividendYield, 2, ' ', ''),
            createFormattedNumberField('Earnings Growth % 1', valuation.earningsGrowthPerc1, 2, ' ', ''),
            createFormattedNumberField('Earnings Growth % 2', valuation.earningsGrowthPerc2, 2, ' ', ''),
            createFormattedNumberField('Earnings Growth % 3', valuation.earningsGrowthPerc3, 2, ' ', ''),
            createFormattedNumberField('Earnings Growth % 4', valuation.earningsGrowthPerc4, 2, ' ', ''),
            createFormattedNumberField('PEG', valuation.peg, 2, ' ', 'x'),
            createFormattedNumberField('IRR 24m LC', valuation.irr24mLc, 2, ' ', '%'),
            createFormattedNumberField('IRR 24m ZAR', valuation.irr24mZar, 2, ' ', '%'),
            createFormattedNumberField('IRR 48m LC', valuation.irr48mLc, 2, ' ', '%'),
            createFormattedNumberField('IRR 48m ZAR', valuation.irr48mZar, 2, ' ', '%'),
        ];

        setCalculatedFields(calculatedFields);

        // Price data fields.
        const {relatedPrice} = valuation;
        if (relatedPrice) {
            const priceFields = [
                createFormattedNumberField('Price', relatedPrice.securityPrice, 2, ' ', ''),
                createField('Currency', activeSecurity?.currencyCode),
                createFormattedNumberField('Cons EPS 0', relatedPrice.consEps0, 2, ' ', ''),
                createFormattedNumberField('Cons EPS 1', relatedPrice.consEps1, 2, ' ', ''),
                createFormattedNumberField('Cons EPS 2', relatedPrice.consEps2, 2, ' ', ''),
                createFormattedNumberField('Cons EPS 3', relatedPrice.consEps3, 2, ' ', ''),
                createFormattedNumberField('Cons EPS 4', relatedPrice.consEps4, 2, ' ', ''),
                createField('Last dividend date', relatedPrice.lastDividendDate),
                createField('Year end', relatedPrice.yearEnd),
                createField('Value date', relatedPrice.valueDate),
                createFormattedNumberField('Market cap LC', relatedPrice.marketCapLc, 0, ' ', ''),
                createFormattedNumberField('Market cap USD', relatedPrice.marketCapUsd, 0, ' ', ''),
                createFormattedNumberField('Liquidity LC', relatedPrice.liquidityLc, 0, ' ', ''),
                createFormattedNumberField('Cons REV 0', relatedPrice.consRev0, 2, ' ', ''),
                createFormattedNumberField('Cons REV 1', relatedPrice.consRev1, 2, ' ', ''),
                createFormattedNumberField('Cons REV 2', relatedPrice.consRev2, 2, ' ', ''),
                createFormattedNumberField('Cons REV 3', relatedPrice.consRev3, 2, ' ', ''),
                createFormattedNumberField('Currency factor', relatedPrice.currencyFactor, 2, ' ', ''),
                createField('Source', translateLabel(relatedPrice.source || '')),
            ];
            setBloomberPriceFields(priceFields);
        }

        if (valuation) {
            if (valuation.epsValues) {
                const epsOutputs = valuation.epsValues.map((epsValue: EpsValue, index) => {
                    return {
                        label: `EPS ${index} (${moment(epsValue.epsValueDate).format('YYYY-MM-DD')})`,
                        value: `${epsValue.value}`,
                    }
                });
                // Add analyst EPS outputs to the beginning of the analyst inputs.
                inputFields.unshift(...epsOutputs);
            }

            setInputs(inputFields);
        }
    }, [activeSecurity?.currencyCode, activeSecurity?.epsDateRange, valuation]);

    return (
        <PageContainer browserTabTitle="Earnings Estimate Detail">
            <NewValuationDrawer/>
            <Loader message="Loading..." loading={valuationsLoading}>
                <Grid container>
                    <Grid item sx={{mb: 1}}>
                        <Link
                            to={`/securities-valuations/${securityId}/user/${currentValuation?.user?.id}/`}>
                            <Button>
                                &lt; Back
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
                <PageHeader title="Earnings Estimate Detail">
                    {
                        valuation && (
                            <Typography variant="body2">
                                <strong>{valuation.securityTicker}</strong> by <strong>{valuation.user?.firstName} {valuation.user?.lastName}</strong> on <strong>{moment(valuation.createdOn).format('YYYY-MM-DD HH:mm')}</strong>
                            </Typography>
                        )
                    }
                </PageHeader>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{mb: 3}}>
                            <DataBlock data={inputs} title="Analyst Input"/>
                        </Box>
                        <Box sx={{mb: 3}}>
                            <DataBlock data={bloomberPriceFields} title="Price"/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{mb: 3}}>
                            <DataBlock data={calculatedFields} title="Calculations"/>
                        </Box>
                    </Grid>

                </Grid>
                <StyledBreadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/">
                        Dashboard
                    </Link>
                    <Link color="inherit" to={`/securities-valuations/${securityId}/user/${valuation?.user?.id}/`}>
                        {valuation?.securityTicker}
                    </Link>
                    <Typography color="text.primary">
                        Detail
                    </Typography>
                </StyledBreadcrumbs>
            </Loader>
        </PageContainer>
    )
}

export default ValuationDetailPage;