import {Checkbox, FormControl, Grid, TextField} from '@mui/material';
import {Control, Controller, FieldError} from 'react-hook-form';
import React from 'react';

export type FCheckboxProps = {
    control: Control<any>,
    name: string,
    label: string,
    textAlign?: 'left' | 'right',
    required?: boolean;
    variant?: 'outlined' | 'filled' | 'standard';
    fullWidth?: boolean;
    multiline?: boolean;
    rows?: number;
    pattern?: any;
    error?: FieldError;
    patternErrorText?: string;
}

const FCheckbox: React.FC<FCheckboxProps> = ({
                                                   control,
                                                   name,
                                                   label,
                                                   textAlign,
                                                   required,
                                                   variant = 'standard',
                                                   fullWidth = false,
                                                   multiline = false,
                                                   rows = 1,
                                                   pattern,
                                                   error,
                                                   patternErrorText
                                               }) => {
    return (
        <Grid item xs={12}>
            <FormControl fullWidth size="small">
                <Controller
                    name={name}
                    control={control}
                    rules={{pattern}}
                    render={
                        ({field: props}) =>
                            <div>
                                <Checkbox
                                    {...props}
                                    checked={!!props.value}
                                    onChange={(e) => props.onChange(e.target.checked)}
                                /> {label}
                            </div>
                    }
                />
            </FormControl>
        </Grid>
    )
}

export default FCheckbox;