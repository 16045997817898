import React from 'react';
import {Tab, Tabs} from '@mui/material';
import {useAppDispatch} from '../../app/hooks';
import {setActiveOwner} from '../../store/securities/securitiesSlice';
import {User} from '../../models/user';
import useUsers from '../../hooks/use-users';
import useSecurity from '../../hooks/use-security';

const UserTabs: React.FC = () => {
    const dispatch = useAppDispatch();
    const {users, currentUser, isLoadingUsers} = useUsers();
    const {activeOwner} = useSecurity();
    const [tabUsers, setTabUsers] = React.useState<User[]>(users);

    const updateTabValue = React.useCallback((_: any, value: string) => {
        // when we're selecting a tab, place the selected user in state. This will be used elsewhere.
        dispatch(setActiveOwner(value))
    }, [dispatch]);

    React.useEffect(() => {
        // If no active owner is set, and we have a list of users, set the active user to the first user we have.
        if (!activeOwner && users.length > 0) {
            dispatch(setActiveOwner(users[0].id))
        }
    }, [dispatch, activeOwner, users, currentUser?.id]);

    const sortUsers = React.useCallback(() => {
        // Put the current user first in the list of tabs. Makes it easy to find your own name.
        const sortedUsers = [...users];
        const currentUserIndex = sortedUsers.findIndex((u) => u.id === currentUser?.id);
        if (currentUserIndex > -1) {
            const foundCurrentUser = sortedUsers[currentUserIndex];
            sortedUsers.splice(currentUserIndex, 1);
            sortedUsers.unshift(foundCurrentUser);
        }

        return sortedUsers;
    }, [currentUser?.id, users]);

    React.useEffect(() => {
        setTabUsers(sortUsers()); // Set the list of tab text to the sorted list of users.
        if (activeOwner) {
            // If there's an active owner (i.e. we've clicked on a tab) then we'll auto select that when we return to this component.
            updateTabValue(null, activeOwner);
        } else if (currentUser && users.findIndex(u => u.id === currentUser.id) > -1) {
            // If we have not selected a user to view yet, view the current user (first tab)
            updateTabValue(null, currentUser?.id);
        } else {
            updateTabValue(null, 'all');
        }
    }, [activeOwner, sortUsers, updateTabValue, currentUser, currentUser?.id, users]);

    // Make sure we have users before rendering this.
    return !!tabUsers && tabUsers.length > 0 ?  (
        <Tabs
            value={activeOwner || 'all'}
            onChange={updateTabValue}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
        >
            <Tab label="Overview" value="all"/>
            {
                tabUsers.filter(u => u !== undefined).map(
                    (u) => <Tab key={u.id} label={`${u.firstName} ${u.lastName}`} value={u.id}/>
                )
            }
        </Tabs>
    ) : null;
}

export default UserTabs;