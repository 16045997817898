import {Typography} from '@mui/material';
import PageContainer from '../../components/page-container';
import React from 'react';

export const AccessDeniedPage = () => {
    return (
        <PageContainer pageTitle="Users" browserTabTitle="Users">
            <Typography variant="h2">Access Denied</Typography>
            <Typography variant="body2">
                You do not have access to this resource.
            </Typography>
        </PageContainer>
    );
}

export default AccessDeniedPage;