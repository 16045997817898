import StyledTableCell from '../../../components/styled-table-cell';
import TickerCell from './TickerCell';
import {renderSecurityTableField} from './render-security-table-field';
import {TableRow} from '@mui/material';
import React from 'react';
import {Security} from '../../../models/security';

export interface SecurityTableRowProps {
    security: Security;
    navigateToSecurityDetails: (security: Security) => void;
    fieldsToDisplay: string[];
}

export const SecurityTableRow: React.FC<SecurityTableRowProps> = ({
                                                                      security,
                                                                      navigateToSecurityDetails,
                                                                      fieldsToDisplay
                                                                  }) => {
    return (
        <TableRow
            className="first-col-left-rest-right"
        >
            <StyledTableCell size="small">
                <TickerCell
                    security={security}
                    navigateToSecurityDetails={navigateToSecurityDetails}
                />
            </StyledTableCell>
            {
                fieldsToDisplay.map((fieldName, index) => (
                    <StyledTableCell size="small" key={`${fieldName}-${index}`}>
                        {renderSecurityTableField(fieldName, security)}
                    </StyledTableCell>
                ))
            }
        </TableRow>
    )
}

export default SecurityTableRow;