import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import React from 'react';
import {useAppDispatch} from '../../app/hooks';
import {closeInfoDialog} from '../../store/dialog/dialogSlice';
import useDialog from '../../hooks/use-dialog';


const InfoDialog: React.FC = () => {
    const dispatch = useAppDispatch();
    const {title, message, open} = useDialog();

    const onClose = () => {
        dispatch(closeInfoDialog());
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{__html: message}}>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default InfoDialog;