import React from 'react';
import PageContainer from '../../components/page-container';
import Loader from '../../components/loader';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch} from '../../app/hooks';
import {
    fetchSecurityAsync, getRecentPricesGraphAsync,
    setActiveOwner,
    updateUserSecuritySettingsAsync
} from '../../store/securities/securitiesSlice';
import {Alert, Button, Grid, Paper, Typography} from '@mui/material';
import ValuationsTable from './valuations-table';
import ValuationsChart from './valuations-chart';
import PageHeader from '../../components/page-header';
import NewValuationDrawer from './new-valuation-drawer';
import SecuritySettingsDialog from './security-settings-dialog';
import {UserSecuritySettings} from '../../models/userSecuritySettings';
import StyledBreadcrumbs from '../../components/styled-breadcrumbs';
import useSecurity from '../../hooks/use-security';
import ValuationActions from './valuation-actions';
import EmptyValuationsMessage from './empty-valuations-message';
import useValuations from '../../hooks/use-valuations';
import useUsers from '../../hooks/use-users';
import useSecurityWebsocket from '../../hooks/use-security-websocket';
import PriceHistoryTable from '../../components/price-history-table';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export type ValuationsPageRouteParams = {
    securityId: string;
    userId: string;
}

const ValuationsPage: React.FC = () => {
    const {securityId, userId} = useParams<ValuationsPageRouteParams>();
    useSecurityWebsocket(securityId);
    const dispatch = useAppDispatch();
    const [settingsDialogOpen, setSettingsDialogOpen] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const {
        userThatOwnsValuations,
        activeSecurity,
        shouldRefreshSecurities,
        securitiesLoading,
        epsGraphData,
        recentPriceData
    } = useSecurity(securityId, userId);
    const {valuations, valuationsLoading} = useValuations();
    const {currentUser} = useUsers();
    const [showErrorDetail, setShowErrorDetail] = React.useState(false);

    React.useEffect(() => {
        if (securityId) {
            dispatch(fetchSecurityAsync({securityId, userId}));
            dispatch(getRecentPricesGraphAsync({securityId}));
        }
    }, [dispatch, securityId, userId]);

    React.useEffect(() => {
        if (shouldRefreshSecurities) {
            if (currentUser?.id) {
                dispatch(setActiveOwner(currentUser?.id));
            }
            navigate(`/`);
        }
    }, [dispatch, navigate, shouldRefreshSecurities, currentUser?.id]);

    const saveSecuritySettings = (securitySettings: UserSecuritySettings) => {
        if (activeSecurity?.id) {
            dispatch(updateUserSecuritySettingsAsync({
                security: activeSecurity.id,
                userSecuritySettings: securitySettings
            }));
            setSettingsDialogOpen(false);
        }
    }

    const getPageTitle = () => {
        return activeSecurity ?
            `Earnings estimates for ${activeSecurity.longCompanyName} (${activeSecurity.ticker}) by ${userThatOwnsValuations?.firstName} ${userThatOwnsValuations?.lastName}` :
            'Loading...'
    }

    const getBrowserTabTitle = () => {
        return !securitiesLoading ? `${activeSecurity?.longCompanyName} Earnings estimates` : 'Loading earnings estimates...';
    }

    const toggleErrorDetails = () => {
        setShowErrorDetail(!showErrorDetail);
    }

    React.useEffect(() => {
        console.log(recentPriceData)
    }, [recentPriceData])

    return (
        <PageContainer
            pageTitle={getPageTitle()}
            browserTabTitle={getBrowserTabTitle()}
        >
            <SecuritySettingsDialog
                open={settingsDialogOpen}
                onClose={() => setSettingsDialogOpen(false)}
                onSave={saveSecuritySettings}
            />
            <NewValuationDrawer/>
            <Loader message="Loading..." loading={valuationsLoading || securitiesLoading}>
                <Grid container>
                    <Grid item sx={{mb: 1}}>
                        <Link
                            to={`/`}>
                            <Button>
                                &lt; Back
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
                {
                    userId && securityId && (
                        <PageHeader
                            title={`Earnings estimates for ${activeSecurity?.longCompanyName} (${activeSecurity?.ticker})`}
                            subtitle={`By ${userThatOwnsValuations?.firstName} ${userThatOwnsValuations?.lastName}`}
                            actions={<ValuationActions userId={userId}
                                                       securityId={securityId}
                                                       setSettingsDialogOpen={setSettingsDialogOpen}/>}
                        >
                            {
                                activeSecurity?.lastUpdateError ? (
                                    <Alert icon={<WarningAmberIcon fontSize="inherit"/>} severity="warning">
                                        There was an error during the last import for this company.
                                        <Button variant="contained" size="small" sx={{mx: 5}}
                                                onClick={toggleErrorDetails}>{showErrorDetail ? 'Hide' : 'Show'} Detail</Button>
                                        {
                                            showErrorDetail && (
                                                <Grid container spacing={2} sx={{my: 1}}>
                                                    <Grid item xs={12}>
                                                        <code>
                                                            {activeSecurity.lastUpdateError}
                                                        </code>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </Alert>
                                ) : ''
                            }
                        </PageHeader>
                    )
                }
                {
                    valuations.length === 0 && (
                        <EmptyValuationsMessage/>
                    )
                }
                <Grid container>
                    {
                        activeSecurity && valuations.length > 0 && (
                            <>
                                <Grid item xs={12} sx={{mb: 5}}>
                                    <ValuationsChart/>
                                </Grid>
                                <Grid item xs={12} sx={{mb: 3}}>
                                    <Paper elevation={3}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{p: 3}}>
                                                <Typography variant="h6">
                                                    Estimate History
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ValuationsTable/>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </>

                        )
                    }
                    {
                        recentPriceData && recentPriceData.length > 0 && (
                            <Grid item xs={12} sx={{mb: 3}}>
                                <Paper elevation={3}>
                                    <Grid container>
                                        <Grid item xs={12}> <PriceHistoryTable
                                            recentPriceData={recentPriceData}
                                        />

                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )
                    }
                </Grid>
                <StyledBreadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/">
                        Dashboard
                    </Link>
                    <Typography color="text.primary">
                        {activeSecurity?.ticker}
                    </Typography>
                </StyledBreadcrumbs>
            </Loader>
        </PageContainer>
    )
}

export default ValuationsPage;