import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {createValuation, fetchValuation, fetchValuations, updateValuation} from './valuationsAPI';
import {Valuation} from '../../models/valuation';
import {FetchValuationsRequest} from '../../models/fetch-valuations-request';
import {PaginatedResponse, SingleResponse} from '../../models/responses/base-response';
import {fetchSecurityAsync} from '../securities/securitiesSlice';

export interface ValuationsState {
    valuations: Valuation[];
    currentValuation?: Valuation;
    loading: boolean;
    creating: boolean;
    error: boolean;
    errorDetail: string | undefined;
    newValuationDialogOpen: boolean;
    editedValuation?: Valuation;
}

const initialState: ValuationsState = {
    valuations: [],
    currentValuation: undefined,
    loading: false,
    creating: false,
    error: false,
    errorDetail: undefined,
    newValuationDialogOpen: false,
    editedValuation: undefined
};

export const fetchValuationsAsync = createAsyncThunk(
    'sectors/fetchValuations',
    async ({security, user}: FetchValuationsRequest, {dispatch}) => {
        const response = await fetchValuations(security, user);
        const json: PaginatedResponse<Valuation> = await response.json();
        return json.data;
    }
);

export const fetchValuationAsync = createAsyncThunk(
    'sectors/fetchValuation',
    async (id: string, {dispatch}) => {
        const response = await fetchValuation(id);
        const json: SingleResponse<Valuation> = await response.json();
        return json.data;
    }
);

export const createValuationAsync = createAsyncThunk(
    'sectors/createValuation',
    async (valuation: Valuation, {dispatch}) => {
        if (valuation.security) {
            const response = await createValuation(valuation);
            const json: SingleResponse<Valuation> = await response.json();
            return json.data;
        }

        throw new Error('Valuation must belong to a security');
    }
);

export const updateValuationAsync = createAsyncThunk(
    'sectors/updateValuation',
    async (valuation: Valuation, {dispatch}) => {
        if (valuation.security) {
            const response = await updateValuation(valuation);
            const json: SingleResponse<Valuation> = await response.json();
            return json.data;
        }

        throw new Error('Valuation must belong to a security');
    }
);

export const valuationsSlice = createSlice({
    name: 'valuations',
    initialState,
    reducers: {
        openNewValuationsDialog: (state, action: PayloadAction<Valuation | undefined>) => {
            if (action.payload) {
                state.editedValuation = action.payload;
            }
            state.newValuationDialogOpen = true;
        },
        closeNewValuationsDialog: (state) => {
            state.newValuationDialogOpen = false;
            state.editedValuation = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSecurityAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.valuations = action.payload.allValuations || [];
            })
            .addCase(fetchValuationsAsync.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchValuationsAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.valuations = action.payload.results; // TODO: include pagination in state.
            })
            .addCase(fetchValuationsAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            })
            .addCase(fetchValuationAsync.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchValuationAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.currentValuation = action.payload;
            })
            .addCase(fetchValuationAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            })
            .addCase(createValuationAsync.pending, (state) => {
                state.creating = true;
                state.error = false;
            })
            .addCase(createValuationAsync.fulfilled, (state, action) => {
                state.creating = false;
                state.newValuationDialogOpen = false;
            })
            .addCase(createValuationAsync.rejected, (state, action) => {
                state.creating = false;
                state.error = true;
            })
            .addCase(updateValuationAsync.pending, (state) => {
                state.creating = true;
                state.error = false;
            })
            .addCase(updateValuationAsync.fulfilled, (state, action) => {
                state.creating = false;
                state.newValuationDialogOpen = false;
            })
            .addCase(updateValuationAsync.rejected, (state, action) => {
                state.creating = false;
                state.error = true;
            });
    },
});

export const { openNewValuationsDialog, closeNewValuationsDialog} = valuationsSlice.actions;

export const selectValuations = (state: RootState) => state.valuations;

export default valuationsSlice.reducer;
