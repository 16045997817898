export const getCurrencyCodeDisplayText = (currencyCode?: string) => {
    if (!currencyCode) {
        return '';
    }

    const lastChar = currencyCode.slice(-1);
    const isCents = lastChar.toLowerCase() === lastChar;

    if (isCents) {
        return `${currencyCode} (cents)`;
    }

    return currencyCode;
}