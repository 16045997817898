import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid, TextField
} from '@mui/material';
import {UserSecuritySettings} from '../../models/userSecuritySettings';
import useSecurity from '../../hooks/use-security';

export interface SecuritySettingsDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (securitySettings: UserSecuritySettings) => void;
}

const SecuritySettingsDialog: React.FC<SecuritySettingsDialogProps> = ({open, onClose, onSave}) => {
    const [securitySettings, setSecuritySettings] = React.useState<UserSecuritySettings>({
        shouldNotifyOnNewValuation: false,
        shouldNotifyOnMovementUp: false,
        shouldNotifyOnMovementDown: false,
        movementUpNotificationThreshold: 0,
        movementDownNotificationThreshold: 0,
    });
    const {activeSecurity} = useSecurity();

    React.useEffect(() => {
        if (activeSecurity?.securitySettings) {
            setSecuritySettings(activeSecurity.securitySettings);
        }
    }, [activeSecurity?.securitySettings]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">
                Alert Settings - {activeSecurity?.longCompanyName} ({activeSecurity?.name})
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Select the notifications you'd like to receive for this security.
                </DialogContentText>
                <Grid container>
                    <Grid item xs={12}>
                        <Checkbox
                            checked={securitySettings.shouldNotifyOnNewValuation}
                            onChange={() => setSecuritySettings({
                                ...securitySettings,
                                shouldNotifyOnNewValuation: !securitySettings.shouldNotifyOnNewValuation
                            })}
                        /> Notify me when someone else creates a new valuation
                        for {activeSecurity?.longCompanyName}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            checked={securitySettings.shouldNotifyOnMovementUp}
                            onChange={() => setSecuritySettings({
                                ...securitySettings,
                                shouldNotifyOnMovementUp: !securitySettings.shouldNotifyOnMovementUp
                            })}
                        /> Notify me when this security price has increased
                        <TextField
                            variant="standard"
                            size="small"
                            inputProps={{style: {textAlign: 'center', width: '3rem'}}}
                            required={securitySettings.shouldNotifyOnMovementUp}
                            disabled={!securitySettings.shouldNotifyOnMovementUp}
                            sx={{margin: '0 16px'}}
                            value={securitySettings.movementUpNotificationThreshold}
                            onChange={(e) => setSecuritySettings({
                                ...securitySettings,
                                movementUpNotificationThreshold: parseFloat(e.target.value)
                            })}
                        /> % since the previous close.
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            checked={securitySettings.shouldNotifyOnMovementDown}
                            onChange={(e) => setSecuritySettings({
                                ...securitySettings,
                                shouldNotifyOnMovementDown: !securitySettings.shouldNotifyOnMovementDown
                            })}
                        /> Notify me when this security price has decreased
                        <TextField
                            variant="standard"
                            size="small"
                            inputProps={{style: {textAlign: 'center', width: '3rem'}}}
                            required={securitySettings.shouldNotifyOnMovementDown}
                            disabled={!securitySettings.shouldNotifyOnMovementDown}
                            sx={{margin: '0 16px'}}
                            value={securitySettings.movementDownNotificationThreshold}
                            onChange={(e) => setSecuritySettings({
                                ...securitySettings,
                                movementDownNotificationThreshold: parseFloat(e.target.value)
                            })}
                        /> % since the previous close.
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus>
                    Close
                </Button>
                <Button onClick={() => onSave(securitySettings)} autoFocus>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SecuritySettingsDialog;