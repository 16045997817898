import React from 'react';
import {
    Button,
    Grid, Paper, Typography
} from '@mui/material';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useAppDispatch} from '../../app/hooks';
import {
    createValuationAsync,
    fetchValuationsAsync,
    updateValuationAsync
} from '../../store/valuations/valuationsSlice';
import {Valuation} from '../../models/valuation';
import FTextField from '../../components/forms/text-field';
import Loader from '../../components/loader';
import {useParams} from 'react-router-dom';
import {ValuationsPageRouteParams} from './index';
import useSecurity from '../../hooks/use-security';
import useValuations from '../../hooks/use-valuations';

export type NewValuationFormProps = {
    onCancel: () => void;
}

const NewValuationForm: React.FC<NewValuationFormProps> = ({onCancel}) => {
    const dispatch = useAppDispatch();
    const {securityId, userId} = useParams<ValuationsPageRouteParams>();

    const {activeSecurity} = useSecurity();
    const {editedValuation, valuationCreating} = useValuations();


    /**
     *
     * @param index {number}: The EPS value to get. Generally ranges from -1 to 4.
     * @returns {Date}: The requested EPS date. -1 will be the previous FYE, 0 will be the current FYE, etc.
     */
    const getEpsDate = (index: number): Date => activeSecurity?.epsDateRange[`${index}`]

    /**
     *
     * @param index {number}: The EPS value to get. Index corresponds to date as noted in getEpsDate().
     * @returns {number|undefined}: The EPS value for the given date index.
     */
    const getLatestEpsValue = (index: number): number | undefined => {
        if (editedValuation) {
            return editedValuation.epsValues?.find(v => {
                const valueDate = new Date(v.epsValueDate);
                const targetDate = new Date(new Date(getEpsDate(index)).toLocaleDateString('en-US', {timeZone: 'Africa/Johannesburg'}));
                return !(valueDate > targetDate || valueDate < targetDate);
            })?.value;
        }

        return activeSecurity?.latestValuation?.epsValues?.find(v => {
            const valueDate = new Date(v.epsValueDate);
            const targetDate = new Date(new Date(getEpsDate(index)).toLocaleDateString('en-US', {timeZone: 'Africa/Johannesburg'}));
            return !(valueDate > targetDate || valueDate < targetDate);
        })?.value;
    }

    const getInitialValuationValues = () => {
        if (editedValuation) {
            return editedValuation;
        }

        if (activeSecurity?.latestValuation) {
            return activeSecurity?.latestValuation;
        }

        // fill out the default currency if this is a brand new valuation
        const priceValues = {
            currencyPercentageZar: 0,
            currencyPercentageUsd: 0,
            currencyPercentageEur: 0,
            currencyPercentageGbp: 0,
            currencyPercentageCny: 0,
        };

        if (activeSecurity?.currencyCode?.toLowerCase() === 'zar') {
            priceValues.currencyPercentageZar = 100;
        }

        if (activeSecurity?.currencyCode?.toLowerCase() === 'usd') {
            priceValues.currencyPercentageUsd = 100;
        }

        if (activeSecurity?.currencyCode?.toLowerCase() === 'eur') {
            priceValues.currencyPercentageEur = 100;
        }

        if (activeSecurity?.currencyCode?.toLowerCase() === 'gbp') {
            priceValues.currencyPercentageGbp = 100;
        }

        if (activeSecurity?.currencyCode?.toLowerCase() === 'cny') {
            priceValues.currencyPercentageCny = 100;
        }

        return priceValues;
    }

    const {control, handleSubmit, formState: {errors}} = useForm({
        // Add EPS values manually to the form from the array of epsValues objects on the latest valuation.
        defaultValues: {
            // ...activeSecurity?.latestValuation,
            ...getInitialValuationValues(),
            eps0: getLatestEpsValue(0),
            eps1: getLatestEpsValue(1),
            eps2: getLatestEpsValue(2),
            eps3: getLatestEpsValue(3),
            eps4: getLatestEpsValue(4),
        }
    });

    const onSubmit: SubmitHandler<Valuation> = async (valuation) => {
        const data = {
            // Populate epsValues manually in a way that the API expects them.
            ...valuation, epsValues: [
                {
                    epsValueDate: getEpsDate(0),
                    value: valuation.eps0 || 0
                },
                {
                    epsValueDate: getEpsDate(1),
                    value: valuation.eps1 || 0
                },
                {
                    epsValueDate: getEpsDate(2),
                    value: valuation.eps2 || 0
                },
                {
                    epsValueDate: getEpsDate(3),
                    value: valuation.eps3 || 0
                },
                {
                    epsValueDate: getEpsDate(4),
                    value: valuation.eps4 || 0
                },
            ]
        };

        // We don't need these fields.
        delete valuation.eps0;
        delete valuation.eps1;
        delete valuation.eps2;
        delete valuation.eps3;
        delete valuation.eps4;

        if (!securityId) return;

        try {
            if (editedValuation) {
                await dispatch(updateValuationAsync(data)).unwrap();
            } else {
                // Unwrap the returned promise so that we can fetch the valuations for the current security and user afterwards.
                await dispatch(createValuationAsync({...data, security: securityId})).unwrap();
            }
            dispatch(fetchValuationsAsync({security: securityId, user: userId}));
        } catch (err) {
            console.error(err);
        }
    }

    const handleClose = () => {
        onCancel();
    }

    return (
        <Loader message="Please wait..." loading={valuationCreating}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Paper sx={{p: 2, mb: 3}} elevation={4}>
                    <Grid container columns={5} columnSpacing={5}>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="alternativeScenario"
                                label="Alternative Scenario"
                                textAlign="left"
                                required={false}
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="exitMultiple24m"
                                label="24m Exit Multiple"
                                textAlign="right"
                                required={true}
                                pattern={/^\d+\.?\d*$/}
                                error={errors.exitMultiple24m}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="terminalGrowth48m"
                                label="48m Terminal Growth %"
                                textAlign="right"
                                required={true}
                                pattern={/^\d+\.?\d*$/}
                                error={errors.terminalGrowth48m}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="exitMultiple48m"
                                label="48m Exit Multiple"
                                textAlign="right"
                                required={true}
                                pattern={/^\d+\.?\d*$/}
                                error={errors.exitMultiple48m}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="payoutPercentage"
                                label="Payout %"
                                textAlign="right"
                                required={true}
                                pattern={/^\d+\.?\d*$/}
                                error={errors.payoutPercentage}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="dividendPaymentFrequency"
                                label="Dividend Payment Frequency"
                                textAlign="right"
                                required={true}
                                pattern={/^\d+\.?\d*$/}
                                error={errors.dividendPaymentFrequency}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="eps0"
                                label={`EPS 0 (${getEpsDate(0)})`}
                                textAlign="right"
                                required={true}
                                pattern={/^-?\d+\.?\d*$/}
                                error={errors.eps0}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="eps1"
                                label={`EPS 1 (${getEpsDate(1)})`}
                                textAlign="right"
                                required={true}
                                pattern={/^-?\d+\.?\d*$/}
                                error={errors.eps1}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="eps2"
                                label={`EPS 2 (${getEpsDate(2)})`}
                                textAlign="right"
                                required={true}
                                pattern={/^-?\d+\.?\d*$/}
                                error={errors.eps2}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="eps3"
                                label={`EPS 3 (${getEpsDate(3)})`}
                                textAlign="right"
                                required={true}
                                pattern={/^-?\d+\.?\d*$/}
                                error={errors.eps3}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="eps4"
                                label={`EPS 4 (${getEpsDate(4)})`}
                                textAlign="right"
                                required={true}
                                pattern={/^-?\d+\.?\d*$/}
                                error={errors.eps4}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="netCashPerShare"
                                label="Associate Net Cash Per Share"
                                textAlign="right"
                                required={true}
                                pattern={/^\d+\.?\d*$/}
                                error={errors.netCashPerShare}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="roe"
                                label="ROE"
                                textAlign="right"
                                required={false}
                                pattern={/^\d+\.?\d*$/}
                                error={errors.roe}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="rotnav"
                                label="ROT NAV"
                                textAlign="right"
                                required={false}
                                pattern={/^\d+\.?\d*$/}
                                error={errors.rotnav}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="rotnavAdjusted"
                                label="ROT NAV Adjusted"
                                textAlign="right"
                                required={false}
                                pattern={/^\d+\.?\d*$/}
                                error={errors.rotnavAdjusted}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="interestCover"
                                label="Interest Cover"
                                textAlign="right"
                                required={false}
                                pattern={/^\d+\.?\d*$/}
                                error={errors.interestCover}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="targetBuyingPrice"
                                label="Target Buying Price"
                                textAlign="right"
                                required={false}
                                pattern={/^\d+\.?\d*$/}
                                error={errors.targetBuyingPrice}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={5} md={1}>
                            <FTextField
                                control={control}
                                name="targetSellingPrice"
                                label="Target Selling Price"
                                textAlign="right"
                                required={false}
                                pattern={/^\d+\.?\d*$/}
                                error={errors.targetSellingPrice}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper sx={{p: 2, mb: 3}} elevation={4}>
                    <Typography variant="body2">
                        <strong>Currency Allocation</strong>
                    </Typography>
                    <Grid container columnSpacing={8} sx={{my: 3}}>
                        <Grid item xs={12} md={2}>
                            <FTextField
                                control={control}
                                name="currencyPercentageZar"
                                label="CCY ZAR %"
                                textAlign="right"
                                pattern={/^\d+\.?\d*$/}
                                error={errors.currencyPercentageZar}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FTextField
                                control={control}
                                name="currencyPercentageUsd"
                                label="CCY USD %"
                                textAlign="right"
                                pattern={/^\d+\.?\d*$/}
                                error={errors.currencyPercentageUsd}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FTextField
                                control={control}
                                name="currencyPercentageEur"
                                label="CCY EUR %"
                                textAlign="right"
                                pattern={/^\d+\.?\d*$/}
                                error={errors.currencyPercentageEur}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FTextField
                                control={control}
                                name="currencyPercentageGbp"
                                label="CCY GBP %"
                                textAlign="right"
                                pattern={/^\d+\.?\d*$/}
                                error={errors.currencyPercentageGbp}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FTextField
                                control={control}
                                name="currencyPercentageCny"
                                label="CCY CNY %"
                                textAlign="right"
                                pattern={/^\d+\.?\d*$/}
                                error={errors.currencyPercentageCny}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FTextField
                                control={control}
                                name="currencyPercentageOther"
                                label="CCY Other %"
                                textAlign="right"
                                pattern={/^\d+\.?\d*$/}
                                error={errors.currencyPercentageOther}
                                patternErrorText="Enter a number with an optional decimal value."
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="body2">
                        <em>Currency allocations should add up to 100% if used.</em>
                    </Typography>
                </Paper>
                <Paper sx={{p: 2, mb: 3}} elevation={4}>

                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                <strong>Comment</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FTextField
                                control={control}
                                name="comment"
                                variant="outlined"
                                fullWidth={true}
                                multiline={true}
                                rows={4}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Button onClick={handleClose}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button type="submit" color="primary" variant="contained">Submit</Button>
                    </Grid>
                </Grid>
            </form>
        </Loader>
    );
}

export default NewValuationForm;