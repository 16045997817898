import React from 'react';
import {DraggableColumnItem} from '../../models/draggable-column-config';
import styled from 'styled-components';
import {Draggable} from 'react-beautiful-dnd';

export interface DndDraggableItemProps {
    item: DraggableColumnItem;
    index: number;
}

interface DndDraggableItemContainerProps {
    isDragging: boolean;
}

const DndDraggableItemContainer = styled.div<DndDraggableItemContainerProps>`
  border: 1px solid lightgray;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
  background-color: ${props => (props.isDragging ? 'rgba(250, 250, 255, 1)' : 'white')};
  box-shadow: ${props => (props.isDragging ? '0 0 10px 0px rgba(0, 0, 0, 0.25)' : 'none')};
  transition: all 0.25s ease;
`;

const DndDraggableItem: React.FC<DndDraggableItemProps> = ({item, index}) => {
    return (
        <Draggable
            draggableId={item.id}
            index={index}
        >
            {
                (provided, snapshot) => (
                    <DndDraggableItemContainer
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        isDragging={snapshot.isDragging}
                    >
                        {item.content}
                    </DndDraggableItemContainer>
                )
            }
        </Draggable>
    )
}

export default DndDraggableItem;