import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {fetchAllSecurityPrices,} from './securityPricesAPI';
import {ListResponse} from '../../models/responses/base-response';
import {SecurityPrice} from '../../models/security-price';
import {fetchSecurityAsync} from '../securities/securitiesSlice';

export interface SecurityPricesState {
    prices: SecurityPrice[];
    loading: boolean;
    error: boolean;
}

const initialState: SecurityPricesState = {
    prices: [],
    loading: false,
    error: false,
};


export const fetchAllSecurityPricesAsync = createAsyncThunk(
    'securityPrices/fetchAllSecurityPrices',
    async (securityId?: string) => {
        const response = await fetchAllSecurityPrices(securityId);
        const json: ListResponse<SecurityPrice> = await response.json();
        return json.data;
    }
);

export const securityPricesSlice = createSlice({
    name: 'securityPrices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSecurityAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.prices = action.payload.allPrices || [];
            })
            .addCase(fetchAllSecurityPricesAsync.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchAllSecurityPricesAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.prices = action.payload;
            })
            .addCase(fetchAllSecurityPricesAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

// export const {} = securityPricesSlice.actions;

export const selectSecurityPrices = (state: RootState) => state.securityPrices;

export default securityPricesSlice.reducer;
