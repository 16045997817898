import React from 'react';
import {Alert, Box, Drawer, Grid, Typography} from '@mui/material';
import {useAppDispatch} from '../../app/hooks';
import {closeNewValuationsDialog} from '../../store/valuations/valuationsSlice';
import NewValuationForm from './new-valuation-form';
import PageHeader from '../../components/page-header';
import moment from 'moment';
import styled from 'styled-components';
import useValuations from '../../hooks/use-valuations';
import useSecurity from '../../hooks/use-security';
import {getCurrencyCodeDisplayText} from '../../utils/currency-utils';

const FilledGridItem = styled.div`
  text-align: center;
  background-color: #00897b;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
`;

const NewValuationDrawer: React.FC = () => {
    const dispatch = useAppDispatch();
    const {editedValuation, newValuationDialogOpen} = useValuations();
    const {activeSecurity} = useSecurity();

    const handleClose = () => {
        dispatch(closeNewValuationsDialog())
    }

    const getHeading = () => {
        if (editedValuation) {
            return `Edit earnings estimate for ${activeSecurity?.longCompanyName} (${activeSecurity?.ticker})`
        }

        return `New earnings estimate for ${activeSecurity?.longCompanyName} (${activeSecurity?.ticker})`;
    }

    return (
        <Drawer
            anchor="right"
            open={newValuationDialogOpen}
            onClose={handleClose}
            sx={{
                '& .MuiDrawer-paper': {
                    width: '75vw',
                    boxSizing: 'border-box',
                    borderRight: 'none'
                },
            }}
        >
            <PageHeader title={getHeading()} bgColor="primary"/>
            <Box sx={{px: 3, pb: '5rem'}}>
                <Grid container justifyContent="flex-end" spacing={10} sx={{mb: 3}}>
                    <Grid item>
                        <FilledGridItem>
                            <Typography variant="body2">
                                <strong>
                                    Currency
                                </strong>
                            </Typography>
                            <Typography variant="body2">
                                {getCurrencyCodeDisplayText(activeSecurity?.currencyCode)}
                            </Typography>
                        </FilledGridItem>
                    </Grid><Grid item>
                        <FilledGridItem>
                            <Typography variant="body2">
                                <strong>
                                    EPS Currency
                                </strong>
                            </Typography>
                            <Typography variant="body2">
                                {activeSecurity?.epsCurrencyCode || getCurrencyCodeDisplayText(activeSecurity?.currencyCode)}
                            </Typography>
                        </FilledGridItem>
                    </Grid>
                    <Grid item>
                        <FilledGridItem>
                            <Typography variant="body2">
                                <strong>
                                    Share Price
                                </strong>
                            </Typography>
                            <Typography variant="body2">
                                {activeSecurity?.latestPrice?.securityPrice}
                            </Typography>
                        </FilledGridItem>
                    </Grid>
                    <Grid item>
                        <FilledGridItem>
                            <Typography variant="body2">
                                <strong>
                                    Year End
                                </strong>
                            </Typography>
                            <Typography variant="body2">
                                {moment(activeSecurity?.latestPrice?.yearEnd).format('YYYY-MM-DD')}
                            </Typography>
                        </FilledGridItem>
                    </Grid>
                </Grid>
                <hr />
                {
                    moment(activeSecurity?.latestPrice?.yearEnd).isBefore(moment()) && (
                        <Alert severity="warning" sx={{my: 3}}>
                            The FYE for {activeSecurity?.ticker} is {moment(activeSecurity?.latestPrice?.yearEnd).format('YYYY-MM-DD')}. <strong>This is in the past.</strong><br />
                            Please make sure that any new EPS estimates correspond with the correct period on Lumina to avoid incorrect data being generated
                        </Alert>
                    )
                }
                <NewValuationForm onCancel={handleClose}/>
            </Box>
        </Drawer>
    )
}

export default NewValuationDrawer;