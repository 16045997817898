import {Valuation} from '../../models/valuation';
import {API_BASE} from '../../constants';

export async function fetchValuations(security: string, user?: string) {
    const params = new URLSearchParams({
        security,
    });

    if (user) {
        params.append('user', user);
    }

    const url = `${API_BASE}/api/securities/valuations/?${params.toString()}`;

    return await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}

export async function fetchValuation(id: string) {
    const url = `${API_BASE}/api/securities/valuations/${id}/`;

    return await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}

export async function createValuation(valuation: Valuation) {
    // remove null or empty keys
    Object.keys(valuation).forEach((k) => {
        if (valuation[k as keyof Valuation] === '' || valuation[k as keyof Valuation] === null || valuation[k as keyof Valuation] === undefined) {
            delete valuation[k as keyof Valuation];
        }
    });

    return await fetch(`${API_BASE}/api/securities/valuations/`, {
        method: 'POST',
        body: JSON.stringify(valuation),
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}

export async function updateValuation(valuation: Valuation) {
    // remove null or empty keys
    Object.keys(valuation).forEach((k) => {
        if (valuation[k as keyof Valuation] === '' || valuation[k as keyof Valuation] === null || valuation[k as keyof Valuation] === undefined) {
            delete valuation[k as keyof Valuation];
        }
    });

    return await fetch(`${API_BASE}/api/securities/valuations/${valuation.id}/`, {
        method: 'PATCH',
        body: JSON.stringify(valuation),
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}