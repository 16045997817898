import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid
} from '@mui/material';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useAppDispatch} from '../../app/hooks';
import {
    claimUnownedSecurityAsync,
    setActiveOwner
} from '../../store/securities/securitiesSlice';
import FSelect from '../../components/forms/select';
import {Security} from '../../models/security';
import {useNavigate} from 'react-router-dom';
import useUsers from '../../hooks/use-users';
import useSecurity from '../../hooks/use-security';

export type SetSecurityOwnerDialogProps = {
    handleClose: () => void;
    open: boolean;
    unownedSecurity: Security;
}
const SetSecurityOwnerDialog: React.FC<SetSecurityOwnerDialogProps> = ({handleClose, open, unownedSecurity}: SetSecurityOwnerDialogProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {currentUser, users} = useUsers();
    const {securitiesLoading} = useSecurity();

    const {control, handleSubmit} = useForm({
        defaultValues: {
            user: '',
        },
    });

    const onSubmit: SubmitHandler<any> = async (data: any) => {
        try{
            // Cool way to dispatch things after we're done with them.
            await dispatch(claimUnownedSecurityAsync({...data, security: unownedSecurity.id})).unwrap();
            dispatch(setActiveOwner(data.user));
            handleClose();
            if (data.user === currentUser?.id) {
                navigate(`securities-valuations/${unownedSecurity.id}/user/${data.user}/`);
            }
        } catch (err) {
            console.error(err);
        }
    }

    const userOptions = React.useCallback(() => {
        return users.map((user) => {
            return {value: user.id, label: user.firstName}
        });
    }, [users]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Set {unownedSecurity.name} Owner</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <strong>{unownedSecurity.name}</strong> does not have an owner. <br />
                        Select the owner for <strong>{unownedSecurity.name}</strong>
                    </DialogContentText>
                    <Grid container>
                        <FSelect
                            control={control}
                            name="user"
                            label=""
                            options={userOptions()}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={securitiesLoading}>Cancel</Button>
                    <Button type="submit" color="primary" variant="contained" disabled={securitiesLoading}>Submit</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default SetSecurityOwnerDialog;