import {FormControl, Grid, InputLabel, MenuItem, Select} from '@mui/material';
import {Controller} from 'react-hook-form';
import React from 'react';

export type FSelectOption = {
    value: string;
    label: string;
}

export type FSelectProps = {
    control: any;
    name: string;
    label: string;
    options: FSelectOption[];
    required?: boolean;
}

const FSelect: React.FC<FSelectProps> = ({control, name, label, options, required = false}) => {
    return (
        <Grid item xs={12} sx={{mt: 2}}>
            <FormControl fullWidth sx={{mb: 3}} size="small">
                <InputLabel>{label}</InputLabel>
                <Controller
                    name={name}
                    control={control}
                    render={({field}) => (
                        <Select
                            variant="standard"
                            defaultValue=""
                            required={required}
                            {...field}
                        >
                            {
                                options.map((option) => <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>)
                            }
                        </Select>
                    )}
                />
            </FormControl>
        </Grid>
    )
}

export default FSelect;