import {User} from '../models/user';
import {Permission} from '../models/permission';

export const getUserByIdFromList = (userId: string, users: User[]): User => {
    const result = users.find(u => u.id === userId);
    if (!result) {
        throw new Error(`Could not find user with id ${userId}`);
    }

    return result;
}

export const formatNumber = (num?: number, decimals: number = 2, sep: string = ' ', suffix = ''): string => {
    if (num === undefined || num === null) {
        return '';
    }

    const fixedText = (+num).toFixed(decimals);
    const numberParts = fixedText.split('.');
    const reversedText = numberParts[0].split('').reverse();

    let output = [];
    for (let i = 0; i < reversedText.length; i++) {
        if (i % 3 === 0 && i > 0) {
            output.push(' ');
        }
        output.push(reversedText[i]);
    }
    let formattedNumber = output.reverse().join('');
    if (numberParts.length > 1) {
        formattedNumber = formattedNumber + '.' + numberParts[1];
    }

    return `${formattedNumber}${suffix}`;
}

export const doesUserHavePermission = (user: User | undefined, permissionCode: string) => {
    return user?.groups.some(
        group => group.permissions.some(
            (permission: Permission) => permission.codename === permissionCode
        )
    );
}