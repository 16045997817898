import {useAppSelector} from '../app/hooks';
import {selectAuth} from '../store/auth/authSlice';

const useAuth = () => {
    const authState = useAppSelector(selectAuth); // TODO: Refactor to hook.

    return {
        token: authState.token,
        authError: authState.error
    }
}

export default useAuth;