import React from 'react';
import {AccountInfo, AuthenticationResult} from '@azure/msal-browser';
import {loginAsync, selectAuth} from '../store/auth/authSlice';
import {useMsal} from '@azure/msal-react';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import {checkLogin} from '../store/users/usersAPI';

export interface MSALAuthAccounts {
    accounts: AccountInfo[],
    canLogin: boolean|null;
}

/**
 * Authenticates the user using MSAL (Microsoft Authentication Library) and returns the user accounts.
 *
 * @returns {AccountInfo[]} The user accounts.
 */

const useMsalAuth = (): MSALAuthAccounts => {
    const {instance, accounts} = useMsal();
    const authState = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();
    const [canLogin, setCanLogin] = React.useState<boolean | null>(null);

    /**
     * Callback function to handle authentication response.
     *
     * @param {AuthenticationResult} response - The authentication result.
     */
    const handleAuthResponse = React.useCallback((response: AuthenticationResult) => {
        checkLogin(accounts[0].username).then((loginResponse) => {
            loginResponse.json().then(({data: {allowed}}) => {
                setCanLogin(allowed);
                if (allowed) {
                    if (!authState.error) {
                        dispatch(loginAsync({
                            email: accounts[0].username,
                            idToken: response.accessToken
                        }));
                    }
                }
            });
        });
    }, [accounts, authState.error, dispatch]);

    /**
     * Show a popup (or navigate to MS auth screen) to authenticate the user.
     */
    const showMSALPopup = React.useCallback((error: any) => {
        if (!accounts) {
            return;
        }
        instance
            .acquireTokenPopup(error)
            .then((response: AuthenticationResult) => {
                handleAuthResponse(response)
            })
            .catch((error) => {
                console.error('acquireTokenPopup failed', error)
            });
    }, [accounts, handleAuthResponse, instance]);

    /**
     * Attempt to get the user's auth token from MS. If unsuccessful, showMSALPopup.
     */
    const acquireMSALToken = React.useCallback(() => {
        if (!accounts) {
            return;
        }
        instance.acquireTokenSilent({
            scopes: ["User.Read"], account: accounts[0]
        }).then((response: AuthenticationResult) => {
            handleAuthResponse(response)
        }).catch((error) => {
            showMSALPopup(error);
        });
    }, [accounts, handleAuthResponse, instance, showMSALPopup]);

    /**
     * Redirect to MS for authentication
     */
    const handleMSALRedirect = React.useCallback(() => {
        instance.handleRedirectPromise()
            .then((result) => {
                acquireMSALToken();
            })
            .catch((error) => {
                console.error('Unexpected error from handleRedirectPromise', error);
            });
    }, [acquireMSALToken, instance]);

    /**
     * Init MSAL auth.
     */
    const initMSAL = React.useCallback(() => {
        instance.initialize()
            .then(() => {
                handleMSALRedirect();
            })
            .catch((error) => {
                console.error('Could not init MSAL: ', error);
            });
    }, [handleMSALRedirect, instance]);

    /**
     * Handle authentication state.
     * If not authenticated with MS, initMSAL.
     * If not authenticated with the API (no token), redirect to the login page with a ?next param.
     */
    React.useEffect(() => {
        if (accounts.length === 0) {
            // if we don't know who the user is from ms auth
            const {pathname} = window.location;
            if (pathname !== '/') {
                window.location.href = '/?next=' + encodeURIComponent(pathname);
            }

            return;
        }

        if (!authState.token && !authState.loading) {
            // if we know who the user is from MS auth
            initMSAL();

            return;
        }
    }, [accounts.length, authState.loading, authState.token, initMSAL])

    return {accounts, canLogin};
}

export default useMsalAuth;