import React from 'react';
import {useAppSelector} from '../app/hooks';
import {ConfigState, selectConfig} from '../store/config/configSlice';

const useTableConfig = (key: string, defaultFieldsToDisplay: string[] = []) => {
    const configState = useAppSelector(selectConfig);
    const [fieldsToDisplay, setFieldsToDisplay] = React.useState(defaultFieldsToDisplay);

    React.useEffect(() => {
        const tableConfig = configState[key as keyof ConfigState];
        if (tableConfig) {
            const activeColumn = tableConfig.find(c => c.id === 'active')
            if (activeColumn) {
                setFieldsToDisplay(activeColumn.items.map(i => i.id));
            }
        }

    }, [configState, key]);

    return fieldsToDisplay;
}

export default useTableConfig;