import {TableRow} from '@mui/material';
import moment from 'moment';
import React from 'react';
import {Valuation} from '../../models/valuation';
import {Link} from 'react-router-dom';
import {Edit, Visibility} from '@mui/icons-material';
import {renderValuationFieldValue} from '../../utils/renderers/valuation-fields';
import StyledTableCell from '../../components/styled-table-cell';
import {openNewValuationsDialog} from '../../store/valuations/valuationsSlice';
import {useAppDispatch} from '../../app/hooks';
import {openInfoDialog} from '../../store/dialog/dialogSlice';
import WithPopover from '../../components/with-popover';
import useUsers from '../../hooks/use-users';

export type ValuationRowProps = {
    valuation: Valuation;
    fields: string[];
};
const ValuationRow: React.FC<ValuationRowProps> = ({valuation, fields}) => {
    const dispatch = useAppDispatch();
    const {currentUser} = useUsers();

    const canEditValuation = () => {
        if (!valuation.createdOn) {
            return false;
        }

        const today = new Date();

        const editingCutoff = new Date(valuation.createdOn);
        editingCutoff.setDate(editingCutoff.getDate() + 7);

        return today <= editingCutoff;
    }

    const editValuation = (valuation: Valuation) => {
        const canEdit = canEditValuation();

        if (canEdit) {
            dispatch(openNewValuationsDialog(valuation));
        } else {
            dispatch(openInfoDialog({
                message: 'This valuation is older than 7 days and can no longer be edited. Please create a new valuation.',
                title: 'Valuation is not editable'
            }));
        }
    }

    const userOwnsValuation = () => {
        return currentUser?.id === valuation.user?.id;
    }

    return (
        <TableRow>
            <StyledTableCell size="small">
                <Link
                    to={`/securities-valuations/${valuation.security}/${valuation.id}`}>
                    {moment(valuation.createdOn).format('YYYY-MM-DD HH:mm')}
                </Link>
            </StyledTableCell>
            {
                fields.map((field) => (
                    <StyledTableCell size="small" style={{textAlign: 'end'}} key={field}>
                        {renderValuationFieldValue(valuation, field)}
                    </StyledTableCell>
                ))
            }
            <StyledTableCell  style={{textAlign: 'end'}}>
                <Link
                    to={`/securities-valuations/${valuation.security}/${valuation.id}`}>
                    <WithPopover text="View">
                        <Visibility />
                    </WithPopover>
                </Link>
                {
                    userOwnsValuation() && (
                        <WithPopover text="Edit">
                            <Edit onClick={() => editValuation(valuation)}/>
                        </WithPopover>
                    )
                }
            </StyledTableCell>
        </TableRow>
    )
}

export default ValuationRow;