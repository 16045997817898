import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import {AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Dashboard from './pages/dashboard';
import LoginPage from './pages/login-page';
import {useAppDispatch} from './app/hooks';
import {fetchAllUsersAsync, getMyProfileAsync} from './store/users/usersSlice';
import ValuationsPage from './pages/securities-valuations';
import Loader from './components/loader';
import {fetchAllSectorsAsync} from './store/sectors/sectorsSlice';
import {ToastContainer} from 'react-toastify';
import ValuationDetailPage from './pages/securities-valuations/detail';
import {fetchAllNotificationsAsync, getUnreadNotificationsCountAsync} from './store/notifications/notificationsSlice';
import NotificationsPage from './pages/notifications';
import InfoDialog from './components/info-dialog';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import {Grid, Typography} from '@mui/material';
import useLuminaWebsocket from './hooks/use-lumina-websocket';
import useMsalAuth from './hooks/use-msal-auth';
import useAuth from './hooks/use-auth';
import useUsers from './hooks/use-users';
import AdminUsers from './pages/admin/users';
import AccessDeniedPage from './pages/403';
import AdminManageUser from './pages/admin/user-detail';

function App() {
    const {accounts, canLogin} = useMsalAuth();
    const dispatch = useAppDispatch();

    const {token, authError} = useAuth();
    const {currentUser, users} = useUsers();

    /**
     * Fetch the user profile if a token is set or changed.
     */
    React.useEffect(() => {
        if (token) {
            dispatch(getMyProfileAsync());
        }
    }, [token, dispatch]);

    /**
     * Fetch notifications, users and sectors from the backend when a user is signed in.
     */
    React.useEffect(() => {
        if (currentUser?.id) {
            dispatch(fetchAllNotificationsAsync({page: 1, read: false}));
            dispatch(getUnreadNotificationsCountAsync());
            dispatch(fetchAllUsersAsync());
            dispatch(fetchAllSectorsAsync());
        }
    }, [dispatch, currentUser?.id]);

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Dashboard/>,
        },
        {
            path: "/securities-valuations",
            element: <ValuationsPage/>,
        },
        {
            path: "/securities-valuations/:securityId/",
            element: <ValuationsPage/>,
        },
        {
            path: "/securities-valuations/:securityId/:valuationId",
            element: <ValuationDetailPage/>,
        },
        {
            path: "/securities-valuations/:securityId/user/:userId",
            element: <ValuationsPage/>,
        },
        {
            path: "/notifications",
            element: <NotificationsPage/>,
        },
        {
            path: "/users",
            element: <AdminUsers/>,
        },
        {
            path: "/users/:userId/",
            element: <AdminManageUser/>,
        },
        {
            path: "/403",
            element: <AccessDeniedPage/>,
        },
    ]);

    /**
     * The authenticated view, allowing users to navigate the application when logged in.
     * TODO: The amount of stuff being looked at before making the loading screen go away is VAST, resulting in a long
     * delay of loading. Fix this.
     */
    const authenticatedView = () => {
        if (accounts.length > 0 && token && currentUser?.id) {
            return <RouterProvider router={router}/>
        }

        if (authError) {
            return loginError();
        }

        if (canLogin === false) {
            return noAccess();
        }

        return <Loader message="Please wait..." loading={true}></Loader>;
    }

    /**
     * Displayed when something went wrong during MSAL or API auth.
     */
    const loginError = () => {
        return (
            <Grid container style={{height: '100vh', alignItems: 'center', textAlign: 'center'}}>
                <Grid item xs={12}>
                    <Typography variant="h3" sx={{mb: 5}}>
                        Oops!
                    </Typography>
                    <Typography variant="body1">
                        An unexpected error occurred while logging you in. Please refresh the page to try again.<br />
                        If this error persists, please contact technical support.
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    /**
     * Displayed when something went wrong during MSAL or API auth.
     */
    const noAccess = () => {
        const message = accounts.length > 0 ? 'The Microsoft account you\'re signed in with is not authorized to use this application.' : 'Please sign into an authorized Microsoft account.';
        return (
            <Grid container style={{height: '100vh', alignItems: 'center', textAlign: 'center'}}>
                <Grid item xs={12}>
                    <Typography variant="h3" sx={{mb: 5}}>
                        Access Denied
                    </Typography>
                    <Typography variant="body1">
                        {message}<br />
                        Please contact Lumina administrators to be granted access.
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <AuthenticatedTemplate>
                    {
                        authenticatedView()
                    }
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <LoginPage/>
                </UnauthenticatedTemplate>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
                <InfoDialog/>
            </ThemeProvider>
        </>
    )
}

export default App;
