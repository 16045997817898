import React from 'react';
import PageContainer from '../../components/page-container';
import {
    Box,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    Pagination,
    Paper, Tab, Tabs,
    Typography
} from '@mui/material';
import {useAppDispatch} from '../../app/hooks';
import PageHeader from '../../components/page-header';
import {UserNotification} from '../../models/user-notification';
import {
    fetchAllNotificationsAsync, getUnreadNotificationsCountAsync,
    markNotificationAsReadAsync
} from '../../store/notifications/notificationsSlice';
import Loader from '../../components/loader';
import moment from 'moment';
import {openInfoDialog} from '../../store/dialog/dialogSlice';
import StyledBreadcrumbs from '../../components/styled-breadcrumbs';
import useNotifications from '../../hooks/use-notifications';

const NotificationsPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const {allNotifications, notificationsLoading} = useNotifications();
    const [page, setPage] = React.useState(1);
    const [readFilter, setReadFilter] = React.useState<string>('unread');
    const [tabValue, setTabValue] = React.useState<string>('unread');

    React.useEffect(() => {
        dispatch(fetchAllNotificationsAsync({page, read: readFilter === 'unread' ? false : undefined}));
    }, [dispatch, page, readFilter]);

    const handlePaginationChange = (_: any, page: number) => {
        setPage(page);
    }

    const handleTabChange = (_: any, value: string) => {
        setTabValue(value);
        setReadFilter(value);
        setPage(1);
    }

    const getNumPages = () => {
        return Math.ceil(allNotifications.count / 10);
    }

    const openDialog = async (notification: UserNotification) => {
        await dispatch(markNotificationAsReadAsync(notification.id)).unwrap();
        dispatch(getUnreadNotificationsCountAsync());
        dispatch(openInfoDialog({message: notification.pushNotificationContent, title: notification.title}));
    }

    return (
        <PageContainer pageTitle="Notifications" browserTabTitle="Notifications">
            <PageHeader title="Notifications"/>
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                <Grid item xs={12}>
                    <Paper elevation={3}>
                        <Grid container sx={{py: notificationsLoading ? 3 : 0}}>
                            <Loader message="Loading notifications..." loading={notificationsLoading}>
                                <Grid item xs={12}>
                                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                        <Tabs value={tabValue} onChange={handleTabChange}
                                              textColor="secondary"
                                              indicatorColor="secondary">
                                            <Tab label="Unread" value="unread"/>
                                            <Tab label="All" value="all"/>
                                        </Tabs>
                                    </Box>
                                    {
                                        allNotifications.count === 0 ? (
                                            <Box sx={{p: 3, textAlign: 'center'}}>
                                                <Typography>
                                                    No notifications.
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <>
                                                <List>
                                                    {
                                                        allNotifications.results.map((notification: UserNotification) => (
                                                            <div
                                                                key={notification.id}
                                                            >
                                                                <ListItem
                                                                    disablePadding
                                                                    sx={{
                                                                        minWidth: '300px',
                                                                    }}
                                                                    className={['notification-row', !notification.read && 'notification-unread'].join(' ')}
                                                                    onClick={() => openDialog(notification)}
                                                                >
                                                                    <ListItemButton>
                                                                        <Box sx={{py: 1, width: '100%'}}>
                                                                            <Grid container>
                                                                                <Grid item xs={12}>
                                                                                    <Typography variant="body2">
                                                                                        {notification.title}<br/>
                                                                                        <small>
                                                                                            {moment(notification.createdOn).fromNow()} - {notification.createdBy.firstName} {notification.createdBy.lastName}
                                                                                        </small>
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider sx={{m: 0}}/>
                                                            </div>
                                                        ))

                                                    }
                                                </List>
                                                <Grid item xs={12}
                                                      sx={{p: 3, display: 'flex', justifyContent: 'center'}}>
                                                    <Pagination
                                                        count={getNumPages()}
                                                        page={page}
                                                        onChange={handlePaginationChange}
                                                    />
                                                </Grid>
                                            </>
                                        )
                                    }
                                </Grid>
                            </Loader>

                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <StyledBreadcrumbs aria-label="breadcrumb">
                <Typography color="text.primary">Notifications</Typography>
            </StyledBreadcrumbs>
        </PageContainer>
    )
}

export default NotificationsPage;