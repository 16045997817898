import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../store/auth/authSlice';
import usersReducer from '../store/users/usersSlice';
import securitySectorsReducer from '../store/sectors/sectorsSlice';
import securitiesReducer from '../store/securities/securitiesSlice';
import valuationsReducer from '../store/valuations/valuationsSlice';
import notificationsReducer from '../store/notifications/notificationsSlice';
import dialogReducer from '../store/dialog/dialogSlice';
import securityPricesReducer from '../store/security-prices/securityPricesSlice';
import configReducer from '../store/config/configSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    sectors: securitySectorsReducer,
    securities: securitiesReducer,
    valuations: valuationsReducer,
    notifications: notificationsReducer,
    dialog: dialogReducer,
    securityPrices: securityPricesReducer,
    config: configReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
