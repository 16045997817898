import React from 'react';
import {RowData} from '../../models/row-data';
import {Divider, Paper, Table, TableBody, TableContainer, Typography} from '@mui/material';
import DataBlockNode from '../data-block-node/data-block-node';

export type DataBlockProps = {
    title?: string;
    data: RowData[];
}

const DataBlock: React.FC<DataBlockProps> = ({title, data}) => {
    const renderTitle = (title?: string) => {
        if (title) {
            return <>
                <Typography variant="h6">
                    {title}
                </Typography>
                <Divider sx={{mt: 1, mb: 3}}/>
            </>
        }
    }

    const renderTableBody = (data: RowData[]) => {
        return data.map((datum) => (
            <DataBlockNode datum={datum} key={datum.label} />
        ));
    }

    return (
        <Paper elevation={3} sx={{p: 3}}>
            {renderTitle(title)}
            <TableContainer>
                <Table>
                    <TableBody>
                        {renderTableBody(data)}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

export default DataBlock;