import {Security} from '../models/security';

/**
 * Find a field in an object using a recursive search. If the field is not found, return undefined.
 *
 * Example:
 * const obj = {
 *     foo: {
 *         bar: {
 *             baz: 'I am here!'
 *         }
 *     }
 * }
 * const bazValue = deepFind(obj, 'foo.bar.baz'); // returns baz
 *
 * @param obj any: The object to search within
 * @param path string: The path to search for
 */
export const deepFind = (obj: any, path: string) => {
    const pathParts = path.split('.');
    let current = obj;

    for (let i = 0; i < pathParts.length; i++) {
        current = current[pathParts[i]];
        if (current === undefined || current === null) {
            return undefined;
        }
    }

    return current;
}

/**
 * Returns a sorting value for the .sort() function (-1, 0, 1)
 * @param a any: The first value to compare
 * @param b any: The second value to compare
 */
export const getSortValue = (a: any, b: any) => {
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;
}

/**
 * Sorts objects by looking at a field (can be a deepFind result).
 *
 * Example:
 * const objs = [
 *     {foo: {bar: { baz: 'Value 1'}}},
 *     {foo: {bar: { baz: 'Value 3'}}},
 *     {foo: {bar: { baz: 'Value 2'}}},
 * ];
 * const sortedObjs = sortObjectsByField(objs, 'foo.bar.baz', 'asc'); // will sort such that baz is ordered to be value 1, value2, value 3
 *
 * @param objs any[]: array of objects to sort
 * @param fieldPath string: Field path to sort by
 * @param sortDirection string ('desc'|'asc'): sort direction. Asc for ascending, desc for descending.
 */
export const sortObjectsByField = (objs: any[], fieldPath: string, sortDirection: string = 'asc') => {
    const doesNotHaveValue = objs.filter(s => deepFind(s, fieldPath) === undefined);
    const hasValue = objs.filter(s => deepFind(s, fieldPath) !== undefined);

    let result = hasValue.sort((a: Security, b: Security) => {
        return getSortValue(
            deepFind(a, fieldPath),
            deepFind(b, fieldPath),
        );
    });

    if (sortDirection === 'desc') {
        result = result.reverse();
    }

    return [...result, ...doesNotHaveValue];
}