import {Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import {DashboardRounded} from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import React, {ReactElement} from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useUsers from '../../hooks/use-users';
import {doesUserHavePermission} from '../../utils/utils';

export interface MenuItem {
    to: string;
    icon: ReactElement;
    text: string;
}

export interface MenuSection {
    items: MenuItem[];
    isVisible: boolean;
}

const MainMenuList: React.FC = () => {
    const theme = useTheme();
    const {pathname} = useLocation();
    const {currentUser} = useUsers();

    const mainMenuItems: MenuItem[] = [
        {to: '/', icon: <DashboardRounded/>, text: 'Dashboard'},
    ];

    const userMenuItems: MenuItem[] = [
        {to: '/notifications', icon: <NotificationsIcon/>, text: 'Notifications'},
    ];

    const adminMenuItems: MenuItem[] = [
        {to: '/users', icon: <PersonIcon/>, text: 'Users'},
    ];

    const menuSections = [
        {
            items: mainMenuItems,
            isVisible: true,
        },
        {
            items: userMenuItems,
            isVisible: true,
        },
        {
            items: adminMenuItems,
            isVisible: doesUserHavePermission(currentUser, 'access_admin')
        },
    ];

    return (
        <>
            {
                menuSections.filter(m => m.isVisible).map((section, index) => (
                    <div key={`menu-section-${index}`}>
                        <List dense={true}>
                            {
                                section.items.map((item) => (
                                    <ListItem disablePadding key={`menu-section-${index}-${item.text}`}>
                                        <ListItemButton component={Link} to={item.to} sx={{
                                            pr: 5, borderRadius: '0 8px 8px 0',
                                            backgroundColor: pathname === item.to ? theme.palette.primary.light : ''
                                        }}
                                                        className={pathname === item.to ? 'menu-item-active' : ''}>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.text}/>
                                        </ListItemButton>
                                    </ListItem>
                                ))
                            }
                        </List>
                        <Divider/>
                    </div>
                ))
            }
        </>
    );
}

export default MainMenuList;