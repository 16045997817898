import React, {PropsWithChildren} from 'react';
import {CircularProgress, Grid, Typography} from '@mui/material';

type LoaderProps = {
    message: string;
    loading: boolean;
}
const Loader: React.FC<PropsWithChildren<LoaderProps>> = ({children, message, loading}: PropsWithChildren<LoaderProps>) => {
    if (!loading) {
        return (
            <>
                {children}
            </>
        )
    }

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item>
                <CircularProgress />
            </Grid>
            <Grid item>
                <Typography variant={'body1'}>
                    {message || 'Loading...'}
                </Typography>
            </Grid>

        </Grid>
    )
}

export default Loader;