import {useAppSelector} from '../app/hooks';
import {selectSecuritySectors} from '../store/sectors/sectorsSlice';

const useSectors = () => {
    const sectorState = useAppSelector(selectSecuritySectors);

    return {
        sectors: sectorState.sectors,
    }
}

export default useSectors;