import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {AuthLoginRequest, login} from './authAPI';
import {User} from '../../models/user';
import {LoginResponse} from '../../models/responses/auth/login-response';

export interface AuthState {
    token: string | undefined;
    user: User | undefined;
    loading: boolean;
    error: boolean;
    errorDetail: string | undefined;
}

const initialState: AuthState = {
    token: undefined,
    user: undefined,
    loading: false,
    error: false,
    errorDetail: undefined
};

export const loginAsync = createAsyncThunk(
    'auth/login',
    async (request: AuthLoginRequest) => {
        const response: Response = await login(request);
        const json: LoginResponse = await response.json();
        return json.data;
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.loading = true;
                state.error = false;
                state.user = undefined;
                state.token = undefined;
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.token = action.payload.access;
                localStorage.setItem('token', action.payload.access);
            })
            .addCase(loginAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
