import {
    AddExistingSecurityToUserRequest,
    ClaimUnownedSecurityRequest,
    CreateSecurityRequest, NewSecurity, RemoveSecurityFromUserRequest
} from '../../models/security';
import {UserSecuritySettings} from '../../models/userSecuritySettings';
import {API_BASE} from '../../constants';

// TODO: Decide whether or not we want to keep this since we are getting a list of securities from our data import.
export async function createSecurity(security: NewSecurity) {
    return await fetch(`${API_BASE}/api/securities/`, {
        method: 'POST',
        body: JSON.stringify(security),
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}

export async function fetchAllSecurities(ownerId?: string) {
    const params = new URLSearchParams();
    if (ownerId && ownerId !== 'all') {
        params.append('user', ownerId);
    }
    return await fetch(`${API_BASE}/api/securities/?${params.toString()}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}

export async function fetchSecurity(securityId: string, userId?: string) {
    const params = new URLSearchParams();
    if (userId && userId !== 'all') {
        params.append('user', userId);
    }
    return await fetch(`${API_BASE}/api/securities/${securityId}/?${params.toString()}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}

export async function addExistingSecurityToUser(request: AddExistingSecurityToUserRequest) {
    return await fetch(`${API_BASE}/api/securities/user-securities/`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}

export async function removeSecurityFromUser(request: RemoveSecurityFromUserRequest) {
    return await fetch(`${API_BASE}/api/securities/remove-security-from-my-profile/`, {
        method: 'DELETE',
        body: JSON.stringify(request),
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}

export async function claimUnownedSecurity(request: ClaimUnownedSecurityRequest) {
    return await fetch(`${API_BASE}/api/securities/${request.security}/claim_unowned_security/`, {
        method: 'PUT',
        body: JSON.stringify({
            owner: request.user
        }),
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}

export async function subscribeToSecurity(securityId: string) {
    return await fetch(`${API_BASE}/api/securities/${securityId}/subscribe/`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}

export async function unsubscribeFromSecurity(securityId: string) {
    return await fetch(`${API_BASE}/api/securities/${securityId}/subscribe/`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}

export async function updateUserSecuritySettings(securityId: string, userSecuritySettings: UserSecuritySettings) {
    return await fetch(`${API_BASE}/api/securities/${securityId}/security_settings/`, {
        method: 'PATCH',
        body: JSON.stringify(userSecuritySettings),
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}

export async function getSecurityEPSGraph(securityId: string, user?: string) {
    const params = new URLSearchParams();

    if (user) {
        params.append('user', user);
    }

    return await fetch(`${API_BASE}/api/securities/${securityId}/eps_graph/?${params.toString()}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}

export async function getRecentPricesGraph(securityId: string) {
    return await fetch(`${API_BASE}/api/securities/${securityId}/recent_price_graph/`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}