import React from 'react';
import PageContainer from '../../components/page-container';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import PageHeader from '../../components/page-header';
import StyledBreadcrumbs from '../../components/styled-breadcrumbs';
import Loader from '../../components/loader';
import useUsers from '../../hooks/use-users';
import {Edit} from '@mui/icons-material';
import {useAppDispatch} from '../../app/hooks';
import {fetchAllGroupsAsync, fetchAllUsersAsync} from '../../store/users/usersSlice';
import {Link} from 'react-router-dom';

const AdminUsers: React.FC = () => {
    const {users, isLoadingUsers, isLoadingGroups, groups} = useUsers();
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (users.length === 0 && !isLoadingUsers) {
            dispatch(fetchAllUsersAsync());
        }
    }, [dispatch, isLoadingUsers, users.length]);

    React.useEffect(() => {
        if (groups.length === 0 && !isLoadingGroups) {
            dispatch(fetchAllGroupsAsync());
        }
    }, [dispatch, groups.length, isLoadingGroups]);

    return (
        <PageContainer pageTitle="Users" browserTabTitle="Users"
                       pagePermissions={{permissions: ['access_admin'], requireAll: true}}>
            <PageHeader title="Users"/>
            <Loader message="Please wait..." loading={isLoadingUsers}>
                <Paper elevation={3} sx={{mb: 5}}>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: '700px'}} size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell size="small" colSpan={5}>Name</TableCell>
                                    <TableCell size="small" colSpan={5}>E-mail</TableCell>
                                    <TableCell colSpan={1}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    users.map((user) => (
                                        <TableRow key={user.id}>
                                            <TableCell colSpan={5}>{user.firstName} {user.lastName}</TableCell>
                                            <TableCell colSpan={5}>{user.email}</TableCell>
                                            <TableCell colSpan={1} style={{textAlign: 'right'}}>
                                                <Link to={`/users/${user.id}/`}>
                                                    <Edit />
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>


                </Paper>
            </Loader>

            <StyledBreadcrumbs aria-label="breadcrumb">
                <Typography color="text.primary">Users</Typography>
            </StyledBreadcrumbs>
        </PageContainer>
    )
}

export default AdminUsers;