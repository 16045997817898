import {useAppSelector} from '../app/hooks';
import {selectValuations} from '../store/valuations/valuationsSlice';

const useValuations = () => {
    const valuationsState = useAppSelector(selectValuations); // TODO: Refactor to hook.

    return {
        valuationsLoading: valuationsState.loading,
        valuations: valuationsState.valuations,
        editedValuation: valuationsState.editedValuation,
        newValuationDialogOpen: valuationsState.newValuationDialogOpen,
        valuationCreating: valuationsState.creating,
        currentValuation: valuationsState.currentValuation
    }
}

export default useValuations;