import React from 'react';
import {Paper, Table, TableBody, TableContainer} from '@mui/material';
import ValuationTableHeaders from './valuation-table-headers';
import ValuationRow from './valuation-row';
import {VALUATIONS_TABLE_COLUMNS_CONFIG_KEY} from '../../constants';
import ValuationsTableConfigModal from '../../components/valuations-table-config-modal';
import useValuations from '../../hooks/use-valuations';
import useConfig from '../../hooks/use-config';

const ValuationsTable: React.FC = () => {
    const {valuations} = useValuations();
    const {configState} = useConfig();
    const [valuationsTableConfigModalOpen, setValuationsTableConfigModalOpen] = React.useState(false);
    const [fieldsToDisplay, setFieldsToDisplay] = React.useState(
        [
            'fpe1',
        ]);

    React.useEffect(() => {
        const tableConfig = configState[VALUATIONS_TABLE_COLUMNS_CONFIG_KEY];
        if (tableConfig) {
            const activeColumn = tableConfig.find(c => c.id === 'active')
            if (activeColumn) {
                setFieldsToDisplay(activeColumn.items.map(i => i.id));
            }
        }

    }, [configState]);

    return (
        <>
            <ValuationsTableConfigModal  open={valuationsTableConfigModalOpen} onClose={() => setValuationsTableConfigModalOpen(false)}/>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table" size="small">
                    <ValuationTableHeaders fields={fieldsToDisplay} onOpenValuationTableFieldsConfigDialog={() => setValuationsTableConfigModalOpen(true)}/>
                    <TableBody>
                        {valuations.map((row) => (
                            <ValuationRow valuation={row} key={row.id} fields={fieldsToDisplay}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ValuationsTable;