import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from '@mui/material';
import React from 'react';
import {useAppDispatch} from '../../app/hooks';
import {useNavigate} from 'react-router-dom';
import {Security} from '../../models/security';
import SetSecurityOwnerDialog from './set-security-owner-dialog';
import {SECURITIES_TABLE_COLUMNS_CONFIG_KEY} from '../../constants';
import SecurityTableRow from './securities-table/security-table-row';
import useTableConfig from '../../hooks/use-table-config';
import SecurityTableHeader from './securities-table/security-table-header';
import useSecurity from '../../hooks/use-security';
import {sortObjectsByField} from '../../utils/object-utils';

export type SecuritiesTableProps = {
    search?: string;
    sector?: string;
    securitiesList: Security[];
    disableSorting?: boolean;
    selectedExchanges: string[];
};

const SecuritiesTable: React.FC<SecuritiesTableProps> = ({
                                                             search,
                                                             sector,
                                                             securitiesList,
                                                             disableSorting = false,
                                                             selectedExchanges
                                                         }) => {
    useAppDispatch();
    const [securityOwnerDialogOpen, setSecurityOwnerDialogOpen] = React.useState(false);
    const [unownedSecurity, setUnownedSecurity] = React.useState<Security | undefined>(undefined);
    const navigate = useNavigate();
    const fieldsToDisplay = useTableConfig(SECURITIES_TABLE_COLUMNS_CONFIG_KEY, [
        'price',
        'fpe1',
        'dividendYield',
        'irr24mLc',
        'irr48mLc'
    ]);
    const {activeOwner} = useSecurity();
    const [sortDirection, setSortDirection] = React.useState<string>(localStorage.getItem('sortDirection') || 'desc');
    const [sortField, setSortField] = React.useState<string | undefined>(localStorage.getItem('sortField') || 'irr24mZar');

    /**
     * Navigate to a security. This takes into account the owner that you are viewing as well.
     * If you are viewing an "all securities" overview page, the primary owner of the security will be used.
     * If a security does not have a primary owner, the user will be prompted to select one.
     * @param security
     */
    const navigateToSecurityDetails = (security: Security) => {
        if (activeOwner !== 'all') {
            navigate(`/securities-valuations/${security.id}/user/${activeOwner}/`);
            return;
        } else {
            if (security.owner) {
                navigate(`/securities-valuations/${security.id}/user/${security.owner?.id}/`);
                return;
            } else {
                setUnownedSecurity(security);
                setSecurityOwnerDialogOpen(true);
            }
        }
    }

    /**
     * Check if a security matches a search param.
     */
    const securityMatchesSearch = React.useCallback((security: Security) => {
        if (!search) {
            return true;
        }
        const tickerMatch = security.ticker.toLowerCase().includes(search.toLowerCase());
        const companyNameMatch = security.longCompanyName && security.longCompanyName.toLowerCase().includes(search.toLowerCase());
        return tickerMatch || companyNameMatch;
    }, [search]);

    /**
     * Set the sorting column.
     */
    const setSorting = (fieldName: string) => {
        if (fieldName === sortField) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortDirection('asc');
            setSortField(fieldName);
        }
    }

    /**
     * Save sorting settings to localStorage
     */
    React.useEffect(() => {
        if (sortField && sortDirection) {
            localStorage.setItem('sortField', sortField);
            localStorage.setItem('sortDirection', sortDirection);
        }
    }, [sortField, sortDirection]);

    /**
     * Get securities filtered by search term and sector where applicable.
     */
    const getFilteredSecurities = React.useCallback((securitiesList: Security[]) => {
        let result = [...securitiesList]; //unpack and make a new instance.

        return result.filter((security) => {
            let match = true;
            if (search && !securityMatchesSearch(security)) {
                match = false;
            }

            if (sector && security.sectorClassification?.id !== sector) {
                match = false;
            }

            if (!!selectedExchanges && selectedExchanges.length > 0) {
                let hasExchangeMatch = false;
                selectedExchanges.forEach((e) => {
                    // Negative selection (i.e. !SJ = Non-JSE)
                    if (e[0] === '!') {
                        if (security.exchange?.name !== e.replace('!', '')) {
                            hasExchangeMatch = true;
                        }
                    } else {
                        // If it should match the exchange, but doesn't.
                        if (security.exchange?.name === e) {
                            hasExchangeMatch = true;
                        }
                    }
                });

                match = match && hasExchangeMatch;
            }

            return match;
        });
    }, [search, sector, securityMatchesSearch, selectedExchanges]);

    /**
     * Sort securities by any field.
     */
    const getSortedSecurities = () => {
        if (disableSorting) {
            // Sort by default, being ticker ASC
            return sortObjectsByField(getFilteredSecurities(securitiesList), 'ticker', 'asc');
        }

        switch (sortField) {
            case 'price':
                return sortObjectsByField(getFilteredSecurities(securitiesList), 'latestValuation.relatedPrice.securityPrice', sortDirection);
            case 'owner':
                return sortObjectsByField(getFilteredSecurities(securitiesList), 'owner.firstName', sortDirection);
            case 'sectorClassification':
                return sortObjectsByField(getFilteredSecurities(securitiesList), 'sectorClassification.name', sortDirection);
            case 'latestValuationDate':
                return sortObjectsByField(getFilteredSecurities(securitiesList), 'latestValuation.createdOn', sortDirection);
            case 'ticker':
                return sortObjectsByField(getFilteredSecurities(securitiesList), 'ticker', sortDirection);
            default:
                return sortObjectsByField(getFilteredSecurities(securitiesList), `latestValuation.${sortField}`, sortDirection)
        }
    };

    /**
     * Checks if the currently selected user has any securities that are viewable.
     */
    const hasSecurities = React.useMemo(() => {
        return getFilteredSecurities.length > 0;
    }, [getFilteredSecurities]);

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: '700px'}} size="small" aria-label="simple table">
                    <SecurityTableHeader fieldsToDisplay={fieldsToDisplay} onSort={setSorting} sortField={sortField}
                                         sortDirection={sortDirection} disableSorting={disableSorting}/>
                    <TableBody>
                        {
                            hasSecurities ? getSortedSecurities().map((row) => (
                                <SecurityTableRow
                                    key={row.id}
                                    security={row}
                                    navigateToSecurityDetails={navigateToSecurityDetails}
                                    fieldsToDisplay={fieldsToDisplay}
                                />
                            )) : (
                                <TableRow
                                    className="first-col-left-rest-right"
                                >
                                    <TableCell colSpan={fieldsToDisplay.length}>
                                        No securities found for this user.
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                unownedSecurity && (
                    <SetSecurityOwnerDialog
                        open={securityOwnerDialogOpen}
                        handleClose={() => setSecurityOwnerDialogOpen(false)}
                        unownedSecurity={unownedSecurity}
                    />
                )
            }
        </>

    )
}

export default SecuritiesTable;