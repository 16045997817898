import {Valuation} from '../../models/valuation';
import {formatNumber} from '../utils';
import moment from 'moment/moment';
import {Tooltip} from '@mui/material';

// Used to initialize forms where these fields are displayed.
export const initialValuationFieldSettings = {
    payoutPercentage: false,
    dividendPaymentFrequency: false,
    netCashPerShare: false,
    exitMultiple24m: false,
    exitMultiple48m: false,
    terminalGrowth48m: false,
    roe: false,
    rotnav: false,
    rotnavAdjusted: false,
    currencyPercentageZar: false,
    currencyPercentageUsd: false,
    currencyPercentageEur: false,
    currencyPercentageGbp: false,
    currencyPercentageCny: false,
    currencyPercentageOther: false,
    interestCover: false,
    cumFrac: false,
    apportionFactor: false,
    rolledEarningsT0: false,
    rolledEarningsT1: false,
    rolledEarningsT2: false,
    rolledEarningsT3: false,
    rolledEarningsT4: false,
    cumDiv: false,
    fpe1: false,
    consGrowthPerc1: false,
    consGrowthPerc2: false,
    consGrowthPerc3: false,
    blendedIntDiff: false,
    exitPrice: false,
    dps1: false,
    dps2: false,
    dps3: false,
    epsGrowthPerc1: false,
    epsGrowthPerc2: false,
    epsGrowthPerc3: false,
    epsGrowthPerc4: false,
    revenueGrowthPerc1: false,
    revenueGrowthPerc2: false,
    revenueGrowthPerc3: false,
    revenueGrowthPerc4: false,
    dividendYield: false,
    earningsGrowthPerc1: false,
    earningsGrowthPerc2: false,
    earningsGrowthPerc3: false,
    earningsGrowthPerc4: false,
    peg: false,
    irr24mLc: false,
    irr24mZar: false,
    irr48mLc: false,
    irr48mZar: false,
    targetBuyingPrice: false,
    targetSellingPrice: false,
    owner: false,
    latestValuationDate: false,
};

export const numericValuationFields: string[] = [
    'payoutPercentage',
    'dividendPaymentFrequency',
    'netCashPerShare',
    'exitMultiple24m',
    'exitMultiple48m',
    'terminalGrowth48m',
    'roe',
    'rotnav',
    'rotnavAdjusted',
    'currencyPercentageZar',
    'currencyPercentageUsd',
    'currencyPercentageEur',
    'currencyPercentageGbp',
    'currencyPercentageCny',
    'currencyPercentageOther',
    'interestCover',
    'cumFrac',
    'apportionFactor',
    'rolledEarningsT0',
    'rolledEarningsT1',
    'rolledEarningsT2',
    'rolledEarningsT3',
    'rolledEarningsT4',
    'cumDiv',
    'fpe1',
    'consGrowthPerc1',
    'consGrowthPerc2',
    'consGrowthPerc3',
    'blendedIntDiff',
    'exitPrice',
    'dps1',
    'dps2',
    'dps3',
    'epsGrowthPerc1',
    'epsGrowthPerc2',
    'epsGrowthPerc3',
    'epsGrowthPerc4',
    'revenueGrowthPerc1',
    'revenueGrowthPerc2',
    'revenueGrowthPerc3',
    'revenueGrowthPerc4',
    'dividendYield',
    'earningsGrowthPerc1',
    'earningsGrowthPerc2',
    'earningsGrowthPerc3',
    'earningsGrowthPerc4',
    'peg',
    'irr24mLc',
    'irr24mZar',
    'irr48mLc',
    'irr48mZar',
    'targetBuyingPrice',
    'targetSellingPrice',
];

const getIrrClassName = (irrValue: number): string => {
    if (irrValue <= 0) {
        return 'irr-indicator warning-danger';
    }

    if (irrValue <= 3) {
        return 'irr-indicator warning-low';
    }

    if (irrValue <= 5) {
        return 'irr-indicator warning-mid';
    }

    if (irrValue <= 10) {
        return 'irr-indicator good-low';
    }

    if (irrValue <= 20) {
        return 'irr-indicator good-mid';
    }

    return 'irr-indicator good-great';
}

const getDivYieldClassName = (divYieldValue: number): string => {
    if (divYieldValue <= 0) {
        return 'div-yield-indicator warning-danger';
    }

    if (divYieldValue <= 3) {
        return 'div-yield-indicator warning-low';
    }

    if (divYieldValue <= 5) {
        return 'div-yield-indicator warning-mid';
    }

    if (divYieldValue <= 10) {
        return 'div-yield-indicator good-low';
    }

    if (divYieldValue <= 20) {
        return 'div-yield-indicator good-mid';
    }

    return 'div-yield-indicator good-great';
}

export const renderValuationFieldValue = (valuation: Valuation, fieldName: string) => {
    if (['earningsGrowthPerc1', 'earningsGrowthPerc2', 'earningsGrowthPerc3', 'earningsGrowthPerc4'].indexOf(fieldName) > -1) {
        try {
            const value: number = Number(valuation[fieldName as keyof Valuation]);
            return (
                <>
                    {(value * 100).toFixed(2)} %
                </>
            )
        } catch (e) {
            return <>{valuation[fieldName as keyof Valuation]}</>
        }

    }

    if (numericValuationFields.indexOf(fieldName) > -1 && fieldName in valuation) {
        // If we're looking at an IRR field, return it in a formatted way for the user to be alerted with a colour.
        if (['irr24mLc', 'irr24mZar', 'irr48mLc', 'irr48mZar'].indexOf(fieldName) > -1) {
            return (
                <span className={getIrrClassName((valuation as any)[fieldName])} >
                    {formatNumber((valuation as any)[fieldName], 2, ' ', ' %')}
                </span>
            )
        }

        if (['dividendYield'].indexOf(fieldName) > -1) {
            return (
                <span className={getDivYieldClassName((valuation as any)[fieldName])} >
                    {formatNumber((valuation as any)[fieldName], 2, ' ', ' %')}
                </span>
            )
        }

        // The weirdness below is so that we can use string indices on an object.
        return formatNumber((valuation as any)[fieldName], 2);
    }

    // Display the EPS value if relevant
    if (['eps0', 'eps1', 'eps2', 'eps3', 'eps4'].indexOf(fieldName) > -1) {
        const idx = parseInt(fieldName[fieldName.length - 1]);
        return (
            <Tooltip title={moment((valuation.epsValues as any)[idx].epsValueDate).format('YYYY-MM-DD')}>
                <span>
                    {formatNumber((valuation.epsValues as any)[idx].value, 2, ' ')}
                </span>
            </Tooltip>
        )
    }

    switch (fieldName) {
        case 'latestValuationDate':
            return moment(valuation.createdOn).format('YYYY-MM-DD HH:mm');
        case 'price':
            return valuation.relatedPrice?.securityPrice || 'N/A';
        default:
            return 'N/A';
    }
}