import {API_BASE} from '../../constants';

export type FetchAllNotificationsQueryParams  = {
    page: number;
    read?: boolean;
}

export async function fetchAllNotifications(params: FetchAllNotificationsQueryParams) {
    const urlParams = new URLSearchParams({
        page: params.page.toString(),
    });

    if (params.read !== undefined) {
        urlParams.append('read', `${params.read}`);
    }

    return await fetch(`${API_BASE}/api/notifications/?${urlParams.toString()}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
    });
}

export async function getUnreadNotificationsCount() {
    return await fetch(`${API_BASE}/api/notifications/unread/`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
    });
}

export async function markAllNotificationsAsRead() {
    return await fetch(`${API_BASE}/api/notifications/mark_all_notifications_read/`, {
        method: 'PATCH',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });
}

export async function markNotificationAsRead(notificationId: string) {
    return await fetch(`${API_BASE}/api/notifications/${notificationId}/`, {
        body: JSON.stringify({read: true}),
        method: 'PATCH',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}

