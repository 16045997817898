import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {
    fetchAllNotifications,
    FetchAllNotificationsQueryParams, getUnreadNotificationsCount,
    markAllNotificationsAsRead,
    markNotificationAsRead
} from './notificationsAPI';
import {PaginatedResponse, PaginatedResponseData} from '../../models/responses/base-response';
import {UserNotification} from '../../models/user-notification';

export interface NotificationsState {
    loading: boolean;
    unreadNotificationsCount: number;
    allNotifications: PaginatedResponseData<UserNotification>;
}

const initialState: NotificationsState = {
    loading: false,
    unreadNotificationsCount: 0,
    allNotifications: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
};

export const fetchAllNotificationsAsync = createAsyncThunk(
    'notifications/fetchAllNotifications',
    async (params: FetchAllNotificationsQueryParams) => {
        const response = await fetchAllNotifications(params);
        const json: PaginatedResponse<UserNotification> = await response.json();
        return json.data;
    }
);

export const getUnreadNotificationsCountAsync = createAsyncThunk(
    'notifications/getUnreadNotificationsCount',
    async () => {
        const response = await getUnreadNotificationsCount();
        const json = await response.json();
        return json.data;
    }
);

export const markAllNotificationsAsReadAsync = createAsyncThunk(
    'notifications/markAllNotificationsAsRead',
    async () => {
        const response = await markAllNotificationsAsRead();
        const json: PaginatedResponse<UserNotification> = await response.json();
        return json.data;
    }
);

export const markNotificationAsReadAsync = createAsyncThunk(
    'notifications/markNotificationAsRead',
    async (notificationId: string) => {
        const response = await markNotificationAsRead(notificationId);
        const json: PaginatedResponse<UserNotification> = await response.json();
        return json.data;
    }
);

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllNotificationsAsync.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchAllNotificationsAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.allNotifications = action.payload;
            })
            .addCase(fetchAllNotificationsAsync.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getUnreadNotificationsCountAsync.fulfilled, (state, action) => {
                state.unreadNotificationsCount = action.payload.unreadNotifications;
                // state.allNotifications = action.payload;
            })
            .addCase(markAllNotificationsAsReadAsync.pending, (state) => {
                state.allNotifications.results = [];
                state.allNotifications.count = 0;
            })
            .addCase(markNotificationAsReadAsync.pending, (state, action) => {
                state.unreadNotificationsCount -= 1;
                const index = state.allNotifications.results.findIndex((notification: UserNotification) => notification.id === action.meta.arg);
                if (index > -1) {
                    state.allNotifications.results[index].read = true;
                }
            });
    },
});

export const selectNotifications = (state: RootState) => state.notifications;

export default notificationsSlice.reducer;
