import "./sentry-instrumentation";
import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import {Configuration, PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from '@azure/msal-react';
import {ThemeProvider} from '@mui/material';
import luminaTheme from './lumina-theme';

const container = document.getElementById('root')!;
const root = createRoot(container);

const configuration: Configuration = {
    auth: {
        authority: 'https://login.microsoftonline.com/organizations/',
        clientId: '66f380af-990d-405b-94ef-6cdad3b1a9e8'
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
    }
};

const pca = new PublicClientApplication(configuration);

root.render(
        <ThemeProvider theme={luminaTheme}>
            <Provider store={store}>
                <MsalProvider instance={pca}>
                    <App/>
                </MsalProvider>
            </Provider>
        </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
