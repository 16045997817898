import {useAppSelector} from '../app/hooks';
import {selectSecurities} from '../store/securities/securitiesSlice';
import {selectUsers} from '../store/users/usersSlice';
import React from 'react';
import {getUserByIdFromList} from '../utils/utils';
import {User} from '../models/user';
import {deepFind} from '../utils/object-utils';
import {Security} from '../models/security';

const useSecurity = (securityId?: string, userId?: string) => {
    const usersState = useAppSelector(selectUsers);
    const [userThatOwnsValuations, setUserThatOwnsValuations] = React.useState<User | undefined>();
    const {
        activeSecurity,
        shouldRefreshSecurities,
        loading,
        activeOwner,
        securities,
        epsGraphData,
        recentPriceData,
        securityCreated,
        creatingNewSecurity,
        existingSecurity
    } = useAppSelector(selectSecurities);
    const [listedSecurities, setListedSecurities] = React.useState<Security[]>([]);
    const isCurrentUserViewingSelf = () => userId === usersState.currentUser?.id;
    const doesCurrentUserOwnActiveSecurity = () => userId === usersState.currentUser?.id;

    React.useEffect(() => {
        if (userId && usersState.users.length > 0) {
            setUserThatOwnsValuations(getUserByIdFromList(userId, usersState.users));
        }
    }, [userId, usersState.users]);

    /**
     * Separate securities list into "listed" and "other" securities.
     * Listed securities on the dashboard overview tab will appear at the top since they have data (IRR 24m ZAR)
     * Other securities will be folded away until they have earnings estimates.
     */
    React.useEffect(() => {
        if (activeOwner && activeOwner !== 'all') {
            setListedSecurities(securities);
        } else {
            setListedSecurities(
                [
                    ...securities.filter(s => deepFind(s, 'latestValuation.irr24mZar') !== undefined),
                    ...securities.filter(s => deepFind(s, 'latestValuation.irr24mZar') === undefined)
                ]);
        }
    }, [activeOwner, securities]);

    return {
        doesCurrentUserOwnActiveSecurity: doesCurrentUserOwnActiveSecurity(),
        isCurrentUserViewingSelf: isCurrentUserViewingSelf(),
        userThatOwnsValuations: userThatOwnsValuations,
        activeSecurity,
        shouldRefreshSecurities,
        securitiesLoading: loading,
        activeOwner,
        securities,
        epsGraphData,
        listedSecurities,
        securityCreated,
        recentPriceData,
        creatingNewSecurity,
        existingSecurity
    }
}

export default useSecurity;