import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {assignUserGroups, fetchAllGroups, fetchAllUsers, fetchUser, getMyProfile} from './usersAPI';
import {User} from '../../models/user';
import {ListResponse, SingleResponse} from '../../models/responses/base-response';
import {Group} from '../../models/group';
import {AssignUserGroupsRequest} from '../../models/requests/assign-user-groups';

export interface UsersState {
    currentUser: User | undefined;
    users: User[],
    groups: Group[],
    loading: boolean;
    loadingGroups: boolean;
    error: boolean;
    errorDetail: string | undefined;
    editedUser?: User;
    assigningUserGroups: boolean;
}

const initialState: UsersState = {
    currentUser: undefined,
    users: [],
    groups: [],
    loading: false,
    loadingGroups: false,
    error: false,
    errorDetail: undefined,
    editedUser: undefined,
    assigningUserGroups: false
};

export const getMyProfileAsync = createAsyncThunk(
    'users/getMyProfile',
    async () => {
        const response = await getMyProfile();
        const json: SingleResponse<User> = await response.json();
        return json.data;
    }
);

export const fetchAllUsersAsync = createAsyncThunk(
    'users/fetchAllUsers',
    async () => {
        const response = await fetchAllUsers();
        const json: ListResponse<User> = await response.json()
        return json.data;
    }
);

export const fetchUserAsync = createAsyncThunk(
    'users/fetchUser',
    async (id: string) => {
        const response = await fetchUser(id);
        const json: SingleResponse<User> = await response.json()
        return json.data;
    }
);

export const fetchAllGroupsAsync = createAsyncThunk(
    'users/fetchAllGroups',
    async () => {
        const response = await fetchAllGroups();
        const json: ListResponse<Group> = await response.json()
        return json.data;
    }
);


export const assignUserGroupsAsync = createAsyncThunk(
    'users/assignUserGroups',
    async (request: AssignUserGroupsRequest) => {
        const response = await assignUserGroups(request);
        const json: SingleResponse<User> = await response.json()
        return json.data;

    }
);

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMyProfileAsync.pending, (state) => {
                state.loading = true;
                state.error = false;
                state.currentUser = undefined;
            })
            .addCase(getMyProfileAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.currentUser = action.payload;
            })
            .addCase(getMyProfileAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            })
            .addCase(fetchAllUsersAsync.pending, (state) => {
                state.loading = true;
                state.error = false;
                state.users = [];
            })
            .addCase(fetchAllUsersAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.users = action.payload;
            })
            .addCase(fetchAllUsersAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            })
            .addCase(fetchUserAsync.pending, (state) => {
                state.loading = true;
                state.error = false;
                state.editedUser = undefined;
            })
            .addCase(fetchUserAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.editedUser = action.payload;
            })
            .addCase(fetchUserAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            })
            .addCase(fetchAllGroupsAsync.pending, (state) => {
                state.groups = [];
                state.loadingGroups = true;
            })
            .addCase(fetchAllGroupsAsync.fulfilled, (state, action) => {
                state.groups = action.payload;
                state.loadingGroups = false;
            })
            .addCase(fetchAllGroupsAsync.rejected, (state, action) => {
                state.loadingGroups = false;
            })
            .addCase(assignUserGroupsAsync.pending, (state) => {
                state.assigningUserGroups = true;
            })
            .addCase(assignUserGroupsAsync.fulfilled, (state, action) => {
                // Overwrite the user in state
                state.users = state.users.map(user => {
                    if (user.id === action.payload.id) {
                        return action.payload;
                    }
                    return user;
                });
                state.assigningUserGroups = false;
            })
            .addCase(assignUserGroupsAsync.rejected, (state, action) => {
                // TODO: handle this error
                state.assigningUserGroups = false;
            });
    },
});

export const selectUsers = (state: RootState) => state.users;

export default usersSlice.reducer;
