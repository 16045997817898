import React from 'react';
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from '@mui/material';
import {useAppDispatch} from '../../../app/hooks';
import {
    acknowledgeSecurityExists,
    addExistingSecurityToUserAsync,
    claimUnownedSecurityAsync, setActiveOwner
} from '../../../store/securities/securitiesSlice';
import useSecurity from '../../../hooks/use-security';
import useUsers from '../../../hooks/use-users';
import {useNavigate} from 'react-router-dom';

const ExistingSecurityDialog: React.FC = () => {
    const dispatch = useAppDispatch();
    const {existingSecurity} = useSecurity();
    const {currentUser} = useUsers();
    const navigate = useNavigate();

    const onClose = () => {
        dispatch(acknowledgeSecurityExists());
    }

    const addToCurrentUserList = async () => {
        if (!!existingSecurity && existingSecurity.id && !!currentUser) {
            await dispatch(addExistingSecurityToUserAsync({security: existingSecurity.id})).unwrap();
            dispatch(setActiveOwner(currentUser.id));
            dispatch(acknowledgeSecurityExists());
            if (currentUser?.id) {
                navigate(`securities-valuations/${existingSecurity.id}/user/${currentUser.id}/`);
            }
        }
    }

    const claimSecurity = async () => {
        if (!!existingSecurity && existingSecurity.id && !!currentUser) {
            await dispatch(claimUnownedSecurityAsync({user: currentUser.id, security: existingSecurity.id})).unwrap();
            dispatch(setActiveOwner(currentUser.id));
            dispatch(acknowledgeSecurityExists());
            if (currentUser?.id) {
                navigate(`securities-valuations/${existingSecurity.id}/user/${currentUser.id}/`);
            }
        }
    }

    if (!existingSecurity) {
        return null;
    }

    return (
        <Dialog
            open={!!existingSecurity}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Security Already Exists
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2">
                    The security you've tried to create already exists.<br />
                    {
                        !!existingSecurity.owner ?
                            `${existingSecurity.bloombergCode} is owned by ${existingSecurity.owner.firstName} ${existingSecurity.owner.lastName}. Would you like to add ${existingSecurity.bloombergCode} to your list?` :
                            `${existingSecurity.bloombergCode} is unowned, you can take ownership of it.`
                    }
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                {
                    !!existingSecurity.owner ?
                        (<Button onClick={addToCurrentUserList}>Add to my list</Button>) :
                        (<Button onClick={claimSecurity}>Take Ownership</Button>)
                }
            </DialogActions>
        </Dialog>
    )
}

export default ExistingSecurityDialog;