import {API_BASE} from '../../constants';
import {AssignUserGroupsRequest} from '../../models/requests/assign-user-groups';

export async function getMyProfile() {
    return await fetch(`${API_BASE}/api/users/my-profile/`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });
}

export async function fetchAllUsers() {
    return await fetch(`${API_BASE}/api/users/`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });
}

export async function fetchUser(id: string) {
    return await fetch(`${API_BASE}/api/users/${id}/`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });
}

export async function fetchAllGroups() {
    return await fetch(`${API_BASE}/api/users/groups/`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });
}

export async function assignUserGroups(request: AssignUserGroupsRequest) {
    return await fetch(`${API_BASE}/api/users/${request.userId}/assign_groups/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
            ids: request.groupIds
        })
    });
}

export async function checkLogin(email: string) {
    return await fetch(`${API_BASE}/api/users/check-login/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: email
        })
    });
}