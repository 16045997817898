import React from 'react';
import {Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent} from '@mui/material';
import {useAppDispatch} from '../../app/hooks';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment/moment';
import {getRecentPricesGraphAsync, getSecurityEPSGraphAsync} from '../../store/securities/securitiesSlice';
import Loader from '../../components/loader';
import {useParams} from 'react-router-dom';
import {ValuationsPageRouteParams} from './index';
import useSecurity from '../../hooks/use-security';

interface DayOption {
    days: number;
    label: string;
    isMax: boolean;
}

const ValuationsChart: React.FC = () => {
    const [chartOptions, setChartOptions] = React.useState({});
    const dispatch = useAppDispatch();
    const {securityId, userId} = useParams<ValuationsPageRouteParams>();
    const [epsIndex, setEpsIndex] = React.useState<number>(1);
    const {activeSecurity, epsGraphData, recentPriceData} = useSecurity();
    const [selectedEpsDate, setSelectedEpsDate] = React.useState<undefined | string>(undefined);


    const daysOptions: DayOption[] = [
        {days: 180, label: '180D', isMax: false},
        {days: 365, label: '1Y', isMax: false},
        {days: 1096, label: '3Y', isMax: false},
        {days: 1827, label: '5Y', isMax: false},
        {days: 0, label: 'Max', isMax: true},
    ];
    const [selectedDaysOption, setSelectedDaysOption] = React.useState<DayOption>(daysOptions[daysOptions.length - 1]);
    const [maxEnabledDay, setMaxEnabledDay] = React.useState(365);

    React.useEffect(() => {
        if (securityId && userId) {
            dispatch(getSecurityEPSGraphAsync({securityId, userId}));
        }
    }, [dispatch, securityId, userId]);

    React.useEffect(() => {
        if (epsGraphData && (!selectedEpsDate || epsGraphData.epsDates.indexOf(selectedEpsDate) === -1)) {
            // if no eps date is selected, or the selected date is not valid, select the first valid date.
            // Find the FYE of the selected company, then set the EPS date to that.
            const activeSecurityFye = activeSecurity?.latestPrice?.yearEnd;
            // If no FYE is found, default to the first one.
            setSelectedEpsDate(activeSecurityFye || epsGraphData.epsDates[0]);
        }
    }, [epsGraphData, selectedEpsDate]);

    const getLastNElements = (arr: any[], n: number) => {
        if (n === 0) {
            return arr;
        }
        return arr.slice(1).slice(-n);
    }

    React.useEffect(() => {
        if (!epsGraphData || !epsGraphData.graphData) {
            return;
        }

        const options = {
            chart: {
                type: 'spline',
            },
            title: {
                text: `EPS Comparison (FYE ${selectedEpsDate})`
            },
            data: {
                dateFormat: 'YYYY/mm/dd'
            },
            tooltip: {
                shared: true,
                valueDecimals: 2
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    }
                }
            },
            xAxis: {
                categories: getLastNElements(epsGraphData.graphData.dates, selectedDaysOption.days).map((v: any) => {
                    return v
                }),
                labels: {
                    formatter: function (label: any): string {
                        const date = moment(label.value);
                        const dateLabel = date.format('YYYY-MM-DD');
                        if (date.date() === 14 || dateLabel === date.endOf('month').format('YYYY-MM-DD')) {
                            return dateLabel;
                        }

                        return '';
                    },
                    step: 1,
                    allowOverlap: false,
                }
            },
            yAxis: [{ // Primary yAxis
                title: {
                    text: 'EPS',
                },

            }, { // Secondary yAxis
                title: {
                    text: 'Share Price',
                },
                opposite: true,
            }],
            series: [
                {
                    name: `EPS ${epsGraphData.epsDates[0]}`,
                    data: getLastNElements(epsGraphData.graphData[`eps-${epsGraphData.epsDates[0]}` as keyof object], selectedDaysOption.days).map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[0] || !selectedEpsDate,
                },
                {
                    name: `CONS EPS ${epsGraphData.epsDates[0]}`,
                    data: getLastNElements(epsGraphData.graphData[`cons-eps-${epsGraphData.epsDates[0]}` as keyof object], selectedDaysOption.days).map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[0] || !selectedEpsDate,
                },
                {
                    name: `EPS ${epsGraphData.epsDates[1]}`,
                    data: getLastNElements(epsGraphData.graphData[`eps-${epsGraphData.epsDates[1]}` as keyof object], selectedDaysOption.days).map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[1] || !selectedEpsDate,
                },
                {
                    name: `CONS EPS ${epsGraphData.epsDates[1]}`,
                    data: getLastNElements(epsGraphData.graphData[`cons-eps-${epsGraphData.epsDates[1]}` as keyof object], selectedDaysOption.days).map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[1] || !selectedEpsDate,
                },
                {
                    name: `EPS ${epsGraphData.epsDates[2]}`,
                    data: getLastNElements(epsGraphData.graphData[`eps-${epsGraphData.epsDates[2]}` as keyof object], selectedDaysOption.days).map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[2] || !selectedEpsDate,
                },
                {
                    name: `CONS EPS ${epsGraphData.epsDates[2]}`,
                    data: getLastNElements(epsGraphData.graphData[`cons-eps-${epsGraphData.epsDates[2]}` as keyof object], selectedDaysOption.days).map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[2] || !selectedEpsDate,
                },
                {
                    name: `EPS ${epsGraphData.epsDates[3]}`,
                    data: getLastNElements(epsGraphData.graphData[`eps-${epsGraphData.epsDates[3]}` as keyof object], selectedDaysOption.days).map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[3] || !selectedEpsDate,
                },
                {
                    name: `CONS EPS ${epsGraphData.epsDates[3]}`,
                    data: getLastNElements(epsGraphData.graphData[`cons-eps-${epsGraphData.epsDates[3]}` as keyof object], selectedDaysOption.days).map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[3] || !selectedEpsDate,
                },
                {
                    name: `EPS ${epsGraphData.epsDates[4]}`,
                    data: getLastNElements(epsGraphData.graphData[`eps-${epsGraphData.epsDates[4]}` as keyof object], selectedDaysOption.days).map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[4] || !selectedEpsDate,
                },
                {
                    name: `CONS EPS ${epsGraphData.epsDates[4]}`,
                    data: getLastNElements(epsGraphData.graphData[`cons-eps-${epsGraphData.epsDates[4]}` as keyof object], selectedDaysOption.days).map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[4] || !selectedEpsDate,
                },
                {
                    name: 'Price',
                    data: getLastNElements(epsGraphData.graphData.prices, selectedDaysOption.days).map((v: any) => v),
                    yAxis: 1
                },
            ]
        };

        setChartOptions(options);
        setMaxEnabledDay(epsGraphData.graphData.dates.length);
    }, [activeSecurity?.epsDateRange, epsGraphData, epsIndex, selectedEpsDate, setChartOptions, selectedDaysOption]);

    const onSetSelectedEpsDate = (e: SelectChangeEvent) => {
        setSelectedEpsDate(e.target.value);
    }

    return (
        <Paper elevation={3} sx={{px: 3, py: 5}}>
            <Loader loading={!epsGraphData} message={"Loading chart..."}>
                <Grid container justifyContent="flex-end" sx={{mb: 3}}>
                    <Grid item>
                        {
                            selectedEpsDate && (
                                <FormControl>
                                    <InputLabel id="fye-selector-label" shrink>FYE</InputLabel>
                                    <Select
                                        labelId="fye-selector"
                                        id="fye-selector-label"
                                        label="FYE"
                                        onChange={onSetSelectedEpsDate}
                                        size="small"
                                        value={selectedEpsDate}
                                        displayEmpty
                                        notched
                                    >
                                        {
                                            epsGraphData?.epsDates && epsGraphData.epsDates.map((v: any) => (
                                                <MenuItem key={v} value={v} selected={selectedEpsDate === v}>
                                                    {v}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            )
                        }
                    </Grid>
                    <Grid item>
                        {
                            daysOptions.map((v: DayOption) => (
                                <Button
                                    variant={selectedDaysOption.days === v.days ? 'contained' : 'outlined'}
                                    onClick={() => setSelectedDaysOption(v)}
                                    sx={{mx: 1}}
                                    disabled={maxEnabledDay < v.days && !v.isMax}
                                    key={v.label}
                                >
                                    {v.label}
                                </Button>
                            ))
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptions}
                            immutable={false}
                        />
                    </Grid>
                </Grid>
            </Loader>
        </Paper>

    )
}

export default ValuationsChart;