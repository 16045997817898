import {useAppDispatch} from '../app/hooks';
import React from 'react';
import {WS_BASE_URL} from '../constants';
import useWebSocket from 'react-use-websocket';
import {toast} from 'react-toastify';
import {addNewGraphPoints} from '../store/securities/securitiesSlice';

const createWebSocketOptions = () => ({
    shouldReconnect: () => {
        return true;
    },
    // Attempt to reconnect twice a minute for 5 mins.
    reconnectAttempts: 10,
    reconnectInterval: 30000,
    onReconnectStop: () => {
        toast.error('You have been disconnected. Please refresh the page.', {autoClose: false});
    }
});

const useSecurityWebsocket = (securityId?: string) => {
    const dispatch = useAppDispatch();
    const [webSocketURL] = React.useState<string>(`${WS_BASE_URL}/security/${securityId}/`);
    const websocketConnection = useWebSocket(webSocketURL, createWebSocketOptions());

    React.useEffect(() => {
        if (websocketConnection.lastMessage) {
            const messageDetails = JSON.parse(websocketConnection.lastMessage.data);
            if (messageDetails.type === 'price_update') {
                dispatch(addNewGraphPoints(messageDetails.message.message.price))
            }
        }
    }, [dispatch, websocketConnection.lastMessage]);
}

export default useSecurityWebsocket;