import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid, Typography
} from '@mui/material';
import DndContainer from '../dnd/dnd-container';
import DndValuationFields from '../../config/dnd-valuation-fields';
import {SECURITIES_TABLE_COLUMNS_CONFIG_KEY} from '../../constants';
import {updateConfig} from '../../store/config/configSlice';
import {useAppDispatch} from '../../app/hooks';

export interface SecuritiesTableConfigModalProps {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
}

const SecuritiesTableConfigModal: React.FC<SecuritiesTableConfigModalProps> = ({open, onClose}) => {
    const dispatch = useAppDispatch();
    const reset = () => {
        localStorage.removeItem(SECURITIES_TABLE_COLUMNS_CONFIG_KEY);
        dispatch(updateConfig({key: SECURITIES_TABLE_COLUMNS_CONFIG_KEY, config: DndValuationFields.columns}));
    }

    return <Dialog
        open={open}
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
    >
        <DialogTitle id="alert-dialog-title">
            Table Configuration
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description" variant="body2" sx={{mb: 3}}>
                <Grid container>
                    <Grid item xs={12} md={9}>
                        <Typography variant="body2">
                            Select which fields you would like to show in the securities table.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{textAlign: 'right'}}>
                        <Button variant="contained" color="secondary" onClick={reset}>
                            Reset
                        </Button>
                    </Grid>
                </Grid>
            </DialogContentText>
            <Grid container>
                <DndContainer config={DndValuationFields} localStorageKey={SECURITIES_TABLE_COLUMNS_CONFIG_KEY}/>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onClose()} autoFocus>
                Close
            </Button>
        </DialogActions>
    </Dialog>
}

export default SecuritiesTableConfigModal;