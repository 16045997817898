import {Divider, Grid, Paper, Typography, useTheme} from '@mui/material';
import React, {ReactNode} from 'react';

export type PageHeaderProps = {
    title: string;
    subtitle?: string;
    bgColor?: 'primary' | 'success' | 'info' | 'error' | 'warning';
    children?: ReactNode;
    actions?: ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({title, subtitle, bgColor, children, actions}) => {
    const theme = useTheme();
    const [paperBackground, setPaperBackground] = React.useState(theme.palette.background.paper);
    const [paperText, setPaperText] = React.useState(theme.palette.text.primary);

    React.useEffect(() => {
        if (bgColor) {
            setPaperBackground(theme.palette[bgColor].main)
            setPaperText(theme.palette[bgColor].contrastText)
        }
    }, [bgColor, theme.palette]);

    return (
        <Paper sx={{p: 3, mb: 3, bgcolor: paperBackground, color: paperText}}>
            <Grid container justifyContent="space-between" alignItems="center" sx={{mb: 2}}>
                <Grid item xs={12} md={actions ? 4 : 12}>
                    <Typography variant="h5" sx={{ mb: 0}}>
                        {title}
                    </Typography>
                    {
                        subtitle && (
                            <Typography variant="body2" sx={{ mb: 0}}>
                                {subtitle}
                            </Typography>
                        )
                    }
                </Grid>
                {
                    actions && (
                        <Grid item xs={12} md={8} textAlign="end" >
                            {actions}
                        </Grid>
                    )
                }
            </Grid>
            {
                children && (
                    <>
                        <Divider sx={{mb: 1}}/>
                        {children}
                    </>
                )
            }

        </Paper>
    )
}

export default PageHeader;