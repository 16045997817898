import React from 'react';
import {Divider, Drawer, Grid, Toolbar} from '@mui/material';
import MainMenuList from './main-menu-list';
import {DRAWER_WIDTH} from '../../constants';
import luminaLogo from '../../lumina-logo-black.png';
import peregrineLogo from '../../peregrine-logo-black.svg';
import styled from 'styled-components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const LuminaLogo = styled.img`
    width: 150px;
`;

const PeregrineLogo = styled.img`
  width: 100px;
`;

const PeregrineLogoContainer = styled.div`
  position: absolute;
  bottom: 48px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 8px 12px;
`;

export interface MainDrawerProps {
    menuExpanded: boolean;
    onCollapseMenu: () => void;
}

const MainDrawer: React.FC<MainDrawerProps> = ({menuExpanded, onCollapseMenu}) => {
    return (
        <Drawer
            sx={{
                width: menuExpanded ? DRAWER_WIDTH : 0,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: menuExpanded ? DRAWER_WIDTH : 0,
                    boxSizing: 'border-box',
                    borderRight: 'none',
                    transition: 'all 0.25s ease'
                },
                paddingBottom: '48px',
                transition: 'all 0.25s ease'
            }}
            variant="permanent"
            anchor="left"
        >
            <Grid container justifyContent="end" alignItems="center" sx={{py: 3, px: 3}} onClick={onCollapseMenu}>
                <Grid item>
                    <ChevronLeftIcon />
                </Grid>
            </Grid>
            <Toolbar sx={{p: 0}}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item sx={{pt: 1}}>
                        <LuminaLogo src={luminaLogo} alt="logo" />
                    </Grid>
                </Grid>
            </Toolbar>
            <Divider/>
            <MainMenuList/>
            <PeregrineLogoContainer>
                <PeregrineLogo src={peregrineLogo} alt="logo" />
            </PeregrineLogoContainer>
        </Drawer>
    );
}

export default MainDrawer;