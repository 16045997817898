import {useAppSelector} from '../app/hooks';
import {selectDialog} from '../store/dialog/dialogSlice';

const useDialog = () => {
    const dialogState = useAppSelector(selectDialog);

    return {
        open: dialogState.open,
        title: dialogState.title,
        message: dialogState.message,
    }
}

export default useDialog;