// TODO: Use Map<string, Promise> to cache responses.
import {API_BASE} from '../../constants';

export async function fetchAllSecurityPrices(securityId?: string) {
    const params = new URLSearchParams();
    if (securityId && securityId !== 'all') {
        params.append('security', securityId);
    }
    return await fetch(`${API_BASE}/api/securities/prices/?${params.toString()}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
}