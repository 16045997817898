import React from 'react';
import {
    Box,
    Button, Grid, Menu, MenuItem,
    Toolbar
} from '@mui/material';
import {DRAWER_WIDTH} from '../../constants';
import {useMsal} from '@azure/msal-react';
import LogoutIcon from '@mui/icons-material/Logout';
import {Person} from '@mui/icons-material';
import useUsers from '../../hooks/use-users';
import MenuIcon from '@mui/icons-material/Menu';

export type MainAppBarProps = {
    pageTitle?: string;
    menuExpanded: boolean;
    onExpandMenu: () => void;
}

const MainAppBar: React.FC<MainAppBarProps> = ({pageTitle, menuExpanded, onExpandMenu}) => {
    const {currentUser} = useUsers();
    const {instance} = useMsal();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Toolbar
            sx={{
                width: `calc(100% - ${menuExpanded ? DRAWER_WIDTH : 0}px)`,
                ml: `${menuExpanded ? DRAWER_WIDTH : 0}px`,
                position: 'fixed',
                bgcolor: '#fff',
                zIndex: 1000,
                transition: 'all 0.25s ease',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Box sx={{flexGrow: 1}}>
                {
                    !menuExpanded && (
                        <Grid container alignItems="center">
                            <MenuIcon onClick={onExpandMenu}/>
                        </Grid>
                    )
                }
            </Box>
            <Button
                onClick={handleClick}
            >
                <Person style={{marginRight: '0.5rem'}}/>{currentUser?.firstName} {currentUser?.lastName}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => instance.logout()}>
                    <LogoutIcon sx={{mr: 1}}/>
                    Logout
                </MenuItem>
            </Menu>
        </Toolbar>
    )
}

export default MainAppBar;