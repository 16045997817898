import {useAppSelector} from '../app/hooks';
import {selectUsers} from '../store/users/usersSlice';
import React from 'react';

const UseRequirePermissions = (requiredPermissions: string[] = [], requireAll: boolean = false) => {
    const usersState = useAppSelector(selectUsers);

    return React.useMemo(() => {
        if (requiredPermissions.length === 0) {
            return true;
        }

        if (!usersState.currentUser) {
            return false;
        }
        let foundPermissions: string[] = [];

        usersState.currentUser.groups.forEach((group) => {
            foundPermissions = [...foundPermissions, ...group.permissions.map((p) => p.codename)];
        });

        if (requireAll) {
            return requiredPermissions.every((permission) => foundPermissions.includes(permission));
        } else {
            return requiredPermissions.some((permission) => foundPermissions.includes(permission));
        }
    }, [requireAll, requiredPermissions, usersState.currentUser]);
}

export default UseRequirePermissions;