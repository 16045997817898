import React from 'react';
import PageContainer from '../../components/page-container';
import {Button, Grid, Paper, Typography} from '@mui/material';
import PageHeader from '../../components/page-header';
import StyledBreadcrumbs from '../../components/styled-breadcrumbs';
import Loader from '../../components/loader';
import useUsers from '../../hooks/use-users';
import {useAppDispatch} from '../../app/hooks';
import {assignUserGroupsAsync, fetchAllGroupsAsync, fetchUserAsync} from '../../store/users/usersSlice';
import {useParams} from 'react-router-dom';
import {SubmitHandler, useForm} from 'react-hook-form';
import {Valuation} from '../../models/valuation';
import FCheckbox from '../../components/forms/checkbox';
import {toast} from 'react-toastify';


export type UserDetailPageParams = {
    userId: string;
}

const AdminManageUser: React.FC = () => {
    const {userId} = useParams<UserDetailPageParams>();
    const {editedUser, isLoadingUsers, isLoadingGroups, groups} = useUsers();
    const dispatch = useAppDispatch();

    const {control, handleSubmit, reset, formState: {errors}, getValues} = useForm();

    React.useEffect(() => {
        if (userId) {
            dispatch(fetchUserAsync(userId));
        }
    }, [dispatch, userId]);

    /**
     * When the detail of a user is loaded, reset the form (i.e. populate it)
     */
    React.useEffect(() => {
        const allFormValues = getValues();
        const activeGroups = editedUser?.groups.map((group) => `group-${group.id}`);
        const result: any = {};

        Object.keys(allFormValues).forEach((formKey: string) => {
            result[formKey as keyof object] = activeGroups && activeGroups.indexOf(formKey) > -1;
        });
        reset(result);
    }, [editedUser, getValues, reset]);

    React.useEffect(() => {
        if (groups.length === 0 && !isLoadingGroups) {
            dispatch(fetchAllGroupsAsync());
        }
    }, [dispatch, groups.length, isLoadingGroups]);

    const onSubmit: SubmitHandler<any> = async (formData: any) => {
        if (!userId) {
            return;
        }

        const groupIds = Object.keys(formData).filter(k => formData[k as keyof object] === true).map((k: string) => {
            return parseInt(k.replace('group-', ''));
        });
        dispatch(assignUserGroupsAsync({userId, groupIds})).then(() => {
            toast.success('User updated.');
        });
    };


    return (
        <PageContainer pageTitle="Manage User" browserTabTitle="Manage User"
                       pagePermissions={{permissions: ['access_admin'], requireAll: true}}>
            <PageHeader title="Manage User"/>
            <Loader message="Please wait..." loading={isLoadingUsers}>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{mb: 5, px: 3, py: 1}}>
                            <h3>User details</h3>
                            <div>
                                <strong>Name: </strong> {editedUser?.firstName} {editedUser?.lastName}
                            </div>
                            <div>
                                <strong>Email: </strong> {editedUser?.email}
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{mb: 5, px: 3, py: 1}}>
                            <h3>User groups</h3>
                            <Loader message="Loading groups..." loading={isLoadingGroups}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container>
                                        {
                                            groups.map((group) => (
                                                <FCheckbox
                                                    key={`group-${group.id}`}
                                                    control={control}
                                                    name={`group-${group.id}`}
                                                    label={group.name}
                                                />
                                            ))
                                        }
                                    </Grid>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item>
                                            <Button type="submit" color="primary" variant="contained">Save
                                                Groups</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Loader>
                        </Paper>
                    </Grid>
                </Grid>
            </Loader>

            <StyledBreadcrumbs aria-label="breadcrumb">
                <Typography color="text.primary">Users</Typography>
            </StyledBreadcrumbs>
        </PageContainer>
    )
}

export default AdminManageUser;