import {TableHead, TableRow} from '@mui/material';
import StyledTableCell from '../../../components/styled-table-cell';
import {translateLabel} from '../../../utils/renderers/field-labels';
import React from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

export interface SecurityTableHeaderProps {
    fieldsToDisplay: string[];
    onSort: (fieldName: string) => void;
    sortField?: string;
    sortDirection?: string;
    disableSorting?: boolean;
}

export interface SortIndicatorProps {
    sortField: string | undefined;
    sortDirection: string | undefined;
    fieldName: string;
}

const SortIndicator: React.FC<SortIndicatorProps> = ({sortField, sortDirection, fieldName}) => {
    // Display the 24px div to prevent layout shift when the icon is being displayed.
    if (fieldName !== sortField) {
        return <div style={{height: '24px', width: '24px'}}></div>;
    }

    if (sortDirection === 'asc') {
        return <ArrowDropUpIcon/>
    }

    if (sortDirection === 'desc') {
        return <ArrowDropDown/>
    }

    return <div style={{height: '24px', width: '24px'}}></div>;
}

const SecurityTableHeader: React.FC<SecurityTableHeaderProps> = ({
                                                                     fieldsToDisplay,
                                                                     onSort,
                                                                     sortField,
                                                                     sortDirection,
                                                                     disableSorting = false
                                                                 }) => {
    return (
        <TableHead>
            <TableRow className="first-col-left-rest-right">
                <StyledTableCell size="small" onClick={() => onSort('ticker')}>
                    <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                        <strong>
                            {translateLabel('ticker')}
                        </strong>
                        {
                            !disableSorting && (
                                <SortIndicator sortField={sortField} sortDirection={sortDirection} fieldName="ticker"/>
                            )
                        }
                    </div>
                </StyledTableCell>
                {
                    fieldsToDisplay.map((fieldName, index) => (
                        <StyledTableCell size="small" key={index} onClick={() => onSort(fieldName)}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                justifyContent: 'flex-end'
                            }}>
                                {
                                    !disableSorting && (
                                        <SortIndicator sortField={sortField} sortDirection={sortDirection} fieldName={fieldName}/>
                                    )
                                }
                                <strong>
                                    {translateLabel(fieldName)}
                                </strong>
                            </div>
                        </StyledTableCell>
                    ))
                }
            </TableRow>
        </TableHead>
    );
}

export default SecurityTableHeader;