import {Grid, Typography} from '@mui/material';
import React from 'react';
import useSecurity from '../../hooks/use-security';
import {useParams} from 'react-router-dom';
import {ValuationsPageRouteParams} from './index';
import useUsers from '../../hooks/use-users';

const EmptyValuationsMessage = () => {
    const {securityId, userId} = useParams<ValuationsPageRouteParams>();
    const {currentUser} = useUsers();
    const {activeSecurity} = useSecurity();
    const {
        userThatOwnsValuations
    } = useSecurity(securityId, userId);

    return (
        <Grid container>
            {
                userId === currentUser?.id ? (
                    <Grid item xs={12}>
                        <Typography variant={"body2"} sx={{textAlign: 'center', mb: 3}}>
                            You have not added any valuations
                            for {activeSecurity?.ticker} yet.
                        </Typography>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Typography variant={"body2"} sx={{textAlign: 'center', mb: 1}}>
                            There are no earnings estimates
                            by {userThatOwnsValuations?.firstName} for {activeSecurity?.ticker} yet.
                        </Typography>
                        <Typography variant={"body2"} sx={{textAlign: 'center'}}>
                            You can't add earnings estimates on behalf of another user.
                        </Typography>
                    </Grid>
                )
            }
        </Grid>
    )
}

export default EmptyValuationsMessage;