import {useAppSelector} from '../app/hooks';
import {selectNotifications} from '../store/notifications/notificationsSlice';

const useNotifications = () => {
    const notificationsState = useAppSelector(selectNotifications);

    return {
        unreadNotificationsCount: notificationsState.unreadNotificationsCount,
        notificationsLoading: notificationsState.loading,
        allNotifications: notificationsState.allNotifications
    }
}

export default useNotifications;