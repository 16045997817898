export const translateLabel = (rawLabel: string) => {
    const labelMap: any = {
        ticker: 'Ticker',
        payoutPercentage: 'Payout %',
        dividendPaymentFrequency: 'Dividend Payment Frequency',
        netCashPerShare: 'Net Cash Per Share',
        exitMultiple24m: 'Exit Multiple 24m',
        exitMultiple48m: 'Exit Multiple 48m',
        terminalGrowth48m: 'Terminal Growth 48m',
        roe: 'ROE',
        rotnav: 'ROTNAV',
        rotnavAdjusted: 'ROTNAV Adjusted',
        currencyPercentageZar: 'Currency Percentage ZAR',
        currencyPercentageUsd: 'Currency Percentage USD',
        currencyPercentageEur: 'Currency Percentage EUR',
        currencyPercentageGbp: 'Currency Percentage GBP',
        currencyPercentageCny: 'Currency Percentage CNY',
        currencyPercentageOther: 'Currency Percentage Other',
        interestCover: 'Interest Cover',
        cumFrac: 'CUM Frac',
        apportionFactor: 'Apportion Factor',
        rolledEarningsT0: 'Rolled Earnings T0',
        rolledEarningsT1: 'Rolled Earnings T1',
        rolledEarningsT2: 'Rolled Earnings T2',
        rolledEarningsT3: 'Rolled Earnings T3',
        rolledEarningsT4: 'Rolled Earnings T4',
        cumDiv: 'CUM Div',
        fpe1: 'FPE 1',
        consGrowthPerc1: 'Cons Growth % 1',
        consGrowthPerc2: 'Cons Growth % 2',
        consGrowthPerc3: 'Cons Growth % 3',
        blendedIntDiff: 'Blended Int Diff',
        exitPrice: 'Exit Price',
        dps1: 'DPS 1',
        dps2: 'DPS 2',
        dps3: 'DPS 3',
        epsGrowthPerc1: 'EPS Growth % 1',
        epsGrowthPerc2: 'EPS Growth % 2',
        epsGrowthPerc3: 'EPS Growth % 3',
        epsGrowthPerc4: 'EPS Growth % 4',
        revenueGrowthPerc1: 'Revenue Growth % 1',
        revenueGrowthPerc2: 'Revenue Growth % 2',
        revenueGrowthPerc3: 'Revenue Growth % 3',
        revenueGrowthPerc4: 'Revenue Growth % 4',
        dividendYield: 'Dividend Yield',
        earningsGrowthPerc1: 'Earnings Growth % 1',
        earningsGrowthPerc2: 'Earnings Growth % 2',
        earningsGrowthPerc3: 'Earnings Growth % 3',
        earningsGrowthPerc4: 'Earnings Growth % 4',
        peg: 'PEG',
        irr24mLc: 'IRR 24m LC',
        irr24mZar: 'IRR 24m ZAR',
        irr48mLc: 'IRR 48m LC',
        irr48mZar: 'IRR 48m ZAR',
        targetBuyingPrice: 'Target Buying Price',
        targetSellingPrice: 'Target Selling Price',
        owner: 'Owner',
        latestValuationDate: 'Latest Valuation Date',
        sectorClassification: 'Sector',
        price: 'Price',
        eps0: 'EPS 0',
        eps1: 'EPS 1',
        eps2: 'EPS 2',
        eps3: 'EPS 3',
        eps4: 'EPS 4',
        twelvedata: 'Twelve Data',
        peresec: 'Bloomberg',
    }

    return labelMap[rawLabel] || rawLabel;
}